import React from "react";
import {
  Page,
  Document,
  //   Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import DataTable from "./dataTable";
import Convert from "../../../helper/curency"


const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 30,
    paddingBottom: 20,

    lineHeight: 1.5,
    flexDirection: "column",
  },
  header: {
    flexDirection: "row",
    paddingLeft: 30,
    paddingRight: 30,
  },
  border: {
    borderWidth: 1,
    borderColor: "#C0C0C0",
    marginLeft: 40,
    marginRight: 40,
  },
  borderTable: {
    borderWidth: 1,
    borderColor: "#C0C0C0",
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: "row",
  },

  termOfPayment:{
    width: "100%", 
    marginLeft: 10,
    marginRight: 10,
    flexDirection: "row",
    borderBottomWidth:1,
    borderColor: "#C0C0C0",
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    borderBottomWidth: 1,
    borderTopWidth:1,
    borderColor: '#000',
    marginLeft: 40,
    marginRight: 40,
    },

  table: {
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    height: 30,
    paddingTop:2,
    paddingBottom:2,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    fontSize:10
    },

    tableFooter:{
        flexDirection: 'row',
        borderBottomColor: '#000',
        backgroundColor: '#fff',
        alignItems: 'left',
        height: 30,
        marginTop:20,
        paddingBottom:2,
        textAlign: 'left',
        fontStyle: 'bold',
        flexGrow: 1,
        fontSize:10
    },
    width10: {
        width: '10%',
        
    },
    width15: {
        width: '15%',
        
    },
    width40: {
        width: '40%',
    },
    width70: {
        width: '70%',
        textAlign:"left"
    },
    width80: {
      width: '80%',
      textAlign:"left",
      marginRight:"10px"

  },
  width90:{
    width: '90%',
    textAlign:"left",
    marginRight:"10px"
  },
    width20: {
        width: '20%',
        textAlign:"left"
       
    },
    width30: {
        width: '30%',
        textAlign:"left"
       
    },
    totalAmount: {
      width: '30%',
      textAlign:"right"
    },
    width100:{
        width: '100%',
    },
    width20WithoutBorder: {
        width: '20%',
    },

    footer:{
        marginTop:"20px",
        bottom: 0,
        width: '100%',
        marginLeft: 50,
    marginRight: 50,
    },

  
});

const POPrintout = ({ data }) => {
  // console.log(data[0]);
  return (
    <Document
      subject={`Purchase Order-${moment().format("DD-MM-YYYY")}`}
      title={`Purchase Order-${moment().format("DD-MM-YYYY")}`}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.border}></View>
        <View style={styles.header}>
          <View
            style={{ width: "100%", padding: "20px", flexDirection: "row" }}
          >
            <View style={{ width: "50%" }}>
              <Text>To:</Text>
              <Text>{data[0].titleType}</Text>
              <Text>{data[0].vendorName}</Text>
              <Text>{data[0].vendorAddress}</Text>

              {/* attention */}
              <Text style={{ marginTop: "30px" }}>
                <span style={{ fontSize: "9px" }}>Attn.</span>
                {data[0].attention}
              </Text>
              <Text style={{ fontSize: "8px", marginTop: "10px" }}>
                Your vendor number with us
              </Text>
              <Text style={{ fontSize: "10px" }}>{data[0].vendorCode}</Text>

              {/*deliver to  */}
              <Text style={{ marginTop: "30px" }}>Please deliver to:</Text>
              <Text>{data[0].plantDescription}</Text>
              <Text>{data[0].plantAddress}</Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text
                style={{
                  fontWeight: "600",
                  paddingLeft: "10px",
                  backgroundColor: "#C0C0C0",
                }}
              >
                Purchase Order
              </Text>
              <View style={styles.borderTable}>
                <View style={{ width: "30%" }}>
                  <Text>Company</Text>
                  <Text>PO Number</Text>
                  <Text>Date</Text>
                  <Text>PO Description</Text>
                </View>
                <View>
                  <Text>{`: ${data[0].companyDescription}`}</Text>
                  <Text>{`: ${data[0].poNo}`}</Text>
                  <Text>{`: ${moment(data[0].createdDate).format("DD-MM-YYYY")}`}</Text>
                  <Text>{`: ${data[0].purchaseDescription !== null ? data[0].purchaseDescription : '-'}`}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Terms Of Payment  */}
        <View style={styles.header}>
          <View
            style={styles.termOfPayment}
          >
            <View style={{ width: "80%",paddingLeft:10 }}>
              <Text>{`Terms Of Payment : ${data[0].paymentTermDesc}`}</Text>
            </View>
            <View style={{ width: "20%" }}>
              <Text>{`Currency : ${data[0].currency}`}</Text>
            </View>
          </View>
        </View>
        
        {/* table */}
        <View style={styles.tableContainer}>
        {/* table header */}
            <View style={styles.table}>
                <Text style={styles.width20}>ITEM</Text>
                <View  style={styles.width90} >
                    <Text >MATERIAL NO.</Text>
                    <Text >MATERIAL DESCRIPTION</Text>
                </View>
                <Text style={styles.width30}>QUANTITY</Text>
                <Text style={styles.width20}>UOM</Text>
                <Text style={styles.width30}>UNIT PRICE</Text>
                <Text style={styles.totalAmount}>AMOUNT</Text>
            </View>
            <DataTable items={data[0].listPOItem}/>
            <View style={styles.tableFooter}>
                <Text style={styles.width20}></Text>
                <Text style={styles.width80}>Total Amount</Text>
                <Text style={styles.width30}></Text>
                <Text style={styles.width20}></Text>
                <Text style={styles.width30}></Text>
                <Text style={styles.totalAmount}>{data[0].currency === "IDR"?Convert(data[0].totalAmount):data[0].totalAmount}</Text>
            </View>
        </View>
        <View style={styles.footer}>
            <Text>*) The amount above is amount before Tax</Text>
            
        </View>
      </Page>
    </Document>
  );
};

export default POPrintout;
