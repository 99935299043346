import "../vendor.css";
import React, { useState,useEffect,useRef } from "react";
import Navbar from "../../../../components/navBarVendor";
import Page from "../../../../components/Page";
import CustomDataGrid from "../../../../components/table";

import { saveAs } from 'file-saver';
import * as excelJS from "exceljs";

import {Autocomplete, Skeleton,TextField, Box, Checkbox, Grid,Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle, Tooltip, Input, } from "@mui/material";

import { useDispatch,useSelector } from "react-redux";
import {
  changeScreenIS,
  getListPO,
  getItemListPO,
  getItemWhtType,
  createInvoice,
  jurnalInvoice,
  uploadItemListExcel,
  getItemType,
  getTaxConfiguration
} from "../../../../configs/redux/action/Vendor/invoiceSubmission";
import Button from "../../../../components/button";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import ConvertFormat from "../../../../helper/curency"

function NumberFormatCustom(props){
  const { inputRef, ...other } = props;

  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
      />
  );
}

function RemoveFormatting(nilai){
  var numberString = nilai.toString();
  var strResult = numberString.replace(/\./g, "");
  var strResult1 = strResult.replace(/,/g, ".");
  return parseFloat(strResult1);
}

function InvoiceSubmission() {
  const today = new Date();
  const dispatch = useDispatch();
  const [checkAllListPO, setCheckAllListPO] = useState(false);
  const [checkedListPO, setCheckedListPO] = useState([]);
  const [checkAllItemList, setCheckAllItemList] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState([]);
  const [checkAllItemType, setCheckAllItemType] = useState(false);
  const [indeterminateListPO, setIndetListPO] = useState(false);
  const [indeterminateItemType, setIndeterminateItemType] = useState(false);
  const [checkedItemType, setCheckedItemType] = useState([]);
  const { listPo,dropdownWht, listSupportDoc, listItemType, listTaxConfig } = useSelector((state) => state.vendorInvoiceSubmission);
  const { listSysparam,listBankAccount,listCompanyCode,dueDate} = useSelector((state) => state.status);
  const { listInfoVendor} = useSelector((state) => state.infoVendor);
  const [loadingListPO, setLoadingListPO] = useState(false);
  const [loadingItemList, setLoadingItemList] = useState(false);
  const [loadingItemType, setLoadingItemType] = useState(false);
  const hiddenFileInput = useRef(null);
  
  const [data, setData] = useState({
    VendorInvoiceNumber:"",
    poType: "",
    PaymentType: "",
    PaymentTerm: "",
    companyCode: "",
    vendorCode: listInfoVendor[0].vendorCode,
    TaxType: "",
    itemTypeTaxConfId: null,
    itemTypeVatAmount: 0,
    itemTypeDppAmount: 0,
    PONo:[],
    ItemList:[],
    ItemListtemp:[],
    selectedItemList:[],

    selectedItemType:[],
    TotalAmountBeforeTax:0,
    TotalAmountServiceMaterial:0,
    TotalAmountItemType:false,
    TotalAmountInstallment:false,
    TotalAmountToBePaid:0,
    itemTypeAmount:0,
    whtType:null,
    whtSubType:null,
    whtRate:null,
    TopType: null,
    TopInstallment: 0,
    TopInstallmentDetail:[],
    TopDueDate:null,
    TopPercentage:null,
    TopPercentageCondition:false,

    TopAmount:0,
    BankName:"",
    BankAccountNo:null,
    BankAccountName:"",

    PaymentRequestNumber: "",
    invoiceDate: "",
    attachment:[]
  });
  const [currency,setCurrency]=useState(null)
  const [selectPO,setSelectedPO]=useState([])
  const [selectListPo,setSelectedListPo]=useState([])
  // const [selectTempResPO,setTempRes]=useState([])
  // const [selectionModel, setSelectionModel] = useState(()=>data.ItemList.filter((r) => r.isCheckBox === false).map((r) => r.id));
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageSizePO, setPageSizePO] = useState(10);
  const [pageSizeItemList, setPageSizeItemList] = useState(10);
  
  const [dropdownWhtType,setWhtType]=useState([{ value: "0", label: "---" }])
  const [dropdownWhtSubType,setWhtSubType]=useState([{ value: "0", label: "---" }])
  const [dropdownTaxConfig,setTaxConfig]=useState([{ value: "0", label: "---", vat: 0, dpp: 0, dppNumber: "", isPercentage: false }])
  const [dataItemType,setItemType] = useState(listItemType);
  const [dataItemList,setItemList] = useState(listTaxConfig);
  const [attachSupportDoc,setAttachSupportDoc] = useState(listSupportDoc);
  const [selectTemp,setSelectTemp]=useState([])
  const [dropdownBank, setBankCode] = useState([]);
  const [dropdownBankNo, setBankNo] = useState([]);
  const [dropdownCompanyCode, setCompanyCode] = useState([]);
  const [loadingSave,setLoadingSave]=useState(false)
  const [loadingSubmit,setLoadingSubmit]=useState(false)
  const [uploadItemList,setUploadItemList]=useState(false)
  const [fileUploadItemList,setFileUploadItemList]=useState([])
  const [errorDialog, setErrorDialog] = useState(false);
  const [listErrorMsg, setListErrorMsg] = useState([]);
  const [pageErr, setPageErr] = useState(5);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const exportToExcel =()=>{
    const newData=data.ItemList
    const newDataTemp =[]
    newData.forEach((d,idx)=>{
      newDataTemp.push({
        poNo:d.poNo,
        item:d.item,
        awbInvoiceNo:d.awbInvoiceNo === null? "NULL":d.awbInvoiceNo,
        material:d.material,
        shortText:d.shortText,
        condition:d.condition,
        conditionDesc:d.conditionDesc,
        quantity:d.quantity,
        currency:d.currency,
        netValue:d.netValue,
        itemStatus:d.itemStatus === null ? "NULL":d.itemStatus,
        grStatus:d.grStatus,
        billedQty:d.billedQty,
      })
    })

    if(newDataTemp.length > 0){
      const date = moment().format('YYYY-MM-DD')
      const workbook = new excelJS.Workbook();
      workbook.creator = "SYSTEM";
      workbook.lastModifiedBy = "SYSTEM";
      workbook.created = new Date();
      workbook.modified = new Date();

      let sheet = workbook.addWorksheet("ItemList");
      sheet.getRow(1).values = [
        "PO Number", 
        "Item",
        "AWB/INV NO",
        "Material",
        "Short Text (Item)",
        "CnTy",
        "Cnty Desc",
        "Qty",
        "Curr",
        "Net Value",
        "itemStatus",
        "GR Status",
        "Billed Qty",
      ];
      sheet.columns = [
        {
          key: "poNo",
          width: 30
        },
        {
          key: "item",
          width: 20
        },
        {
          key: "awbInvoiceNo",
          width: 25
        },
        {
          key: "material",
          width: 25
        },
        {
          key: "shortText",
          width: 40
        },
        {
          key: "condition",
          width: 20
        },
        {
          key: "conditionDesc",
          width: 30
        },
        {
          key: "quantity",
          width: 20
        },
        {
          key: "currency",
          width: 30
        },
        {
          key: "netValue",
          width: 30
        },
        {
          key: "itemStatus",
          width:30
        },
        {
          key: "grStatus",
          width: 30
        },
        {
          key: "billedQty",
          width: 20
        },
      ];
      sheet.addRows(newDataTemp);

      const row = sheet.getRow(1);
      row.eachCell((cell, rowNumber) => {
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'ffff00'},
        
        };
        sheet.getColumn(rowNumber).alignment = {
          vertical: "top",
          horizontal: "left"
        };
        sheet.getColumn(rowNumber).font = { size: 14, family: 2 };
      });

      workbook.xlsx.writeBuffer().then(function(buffer) {
        const blob = new Blob([buffer], { type: "applicationi/xlsx" });
        saveAs(blob, `ItemList-${date}.xlsx`);
      });
    }else{
      Toastify({
        text: "No data available.",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "#FFCC00",
        },
      }).showToast();
    }
    
  }

  const handleUploadItemList =(e)=>{
    setLoadingUpload(true)
    e.preventDefault();
    const files = fileUploadItemList

    const formData = new FormData();
    formData.append("vendorCode",listInfoVendor[0].vendorCode)
    formData.append("file", files);
    dispatch(uploadItemListExcel(formData)).then((res)=>{
      if(res.data.message === "Success"){
        
        const itemTemp =data.ItemList
        const dataTemp = res.data.invoiceUploadItemList
        
        const filterItemTemp = itemTemp.filter((elem) => dataTemp.find(({ poNo,item,conditionCode }) => elem.poNo === poNo && elem.item === Number(item) && elem.condition === conditionCode  ))
  
        let newValueItemList = [];
  
        for(let i = 0; i < filterItemTemp.length; i += 1) {
          let newArray = dataTemp.filter(function (el) {
              return el.poNo === filterItemTemp[i].poNo && Number(el.item)===filterItemTemp[i].item && el.conditionCode === filterItemTemp[i].condition ;
          });
          if(newArray.length ===0)
          {
            newValueItemList.push(filterItemTemp[i]);    
          }
          else
          {
            filterItemTemp[i].isCheckBox=true
            filterItemTemp[i].billedQty = Number(newArray[0].billedQty); 
            filterItemTemp[i].balanceQty = Number(newArray[0].billedQty); 
            filterItemTemp[i].amountInvoice=filterItemTemp[i].currency === "IDR"?Math.ceil(Number(newArray[0].billedQty)*filterItemTemp[i].pricePerUnit) :(Number(newArray[0].billedQty)*filterItemTemp[i].pricePerUnit).toFixed(2) 
            newValueItemList.push(filterItemTemp[i]);
          }
        };
  
        const findIsCheckboxFalse = itemTemp.filter((elem) => !newValueItemList.find(({ poNo,item,condition }) => elem.poNo === poNo && elem.item === item && elem.condition === condition ))
  
        const mergedArray =[...newValueItemList,...findIsCheckboxFalse]
  
        let newDataSelected=[]
        let amountBeforeTax=[]
        let curr=[]
        let idSelectionModel=[]
        newValueItemList.forEach((d)=>{
          idSelectionModel.push(d.id)
          curr.push(d.currency)
          amountBeforeTax.push(Number(d.amountInvoice));
          newDataSelected.push({
            amountInvoice: Number(d.amountInvoice),
            item: d.item,
            billedQty: parseInt(d.balanceQty),
            conditionId: d.conditionId,
            poNo: d.poNo,
          });
        })
        let newAmmountBeforeTax = amountBeforeTax.reduce(
          (a, b) => a + b,
          0
        )
        const uniqueCurr = [...new Set(curr)];
       
        setCheckedItemList(idSelectionModel)
        setSelectionModel(idSelectionModel)
        setCurrency(uniqueCurr)
        setSelectedRows(newValueItemList)
        setData({
          ...data,
          ItemList:mergedArray,
          TotalAmountBeforeTax: newAmmountBeforeTax,
          selectedItemList: newDataSelected,
        })
        setLoadingUpload(false)
        setUploadItemList(false)
        Toastify({
          text: "Upload Item Success",
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          // stopOnFocus: true,
          style: {
            background: "#5EBA7D",
          },
        }).showToast();
      }else{
        setUploadItemList(false)
        setLoadingUpload(false)
        const error = res.data.listErrorUpload;
          error.forEach((data, ind) => {
            data.id = ind + 1;
          });
          setListErrorMsg(error);
          setErrorDialog(true);
      }
      
    })
    .catch((err)=>{
      setUploadItemList(false)
        setLoadingUpload(false)
        const error = err.data.listErrorUpload;
          error.forEach((data, ind) => {
            data.id = ind + 1;
          });
          setListErrorMsg(error);
          setErrorDialog(true);
    })
  }

  const handleChangeHeader = (event) => {
    const dataNew = { ...data };
    dataNew[event.target.name] = event.target.value;
    setData(dataNew);

    let query = "?";
    Object.keys(dataNew).forEach((filternya) => {
      if (
        dataNew[filternya] !== "" &&
        dataNew[filternya] !== "All" &&
        dataNew[filternya] !== null &&
        dataNew[filternya] !== undefined
      ) {
        switch (filternya) {
          case "poType":
            query += "POType=" + dataNew[filternya] + "&";
            break;
          case "companyCode":
            query += "CompanyCode=" + dataNew[filternya] + "&";
            break;
          case "vendorCode":
            query += "VendorCode=" + dataNew[filternya] + "&";
            break;
          case "paymentTerm":
            query += "PaymentTerm=" + dataNew[filternya] + "&";
            break;
          case "PaymentType":
            query += "PaymentType=" + dataNew[filternya] + "&";
            break;
          default:
            query += "";
        }
      }
    });

    query = query.substring(0, query.length - 1);
    if (dataNew.poType !== "" && dataNew.companyCode !== "" && dataNew.PaymentType !== "" && dataNew.TaxType !== "") {
      setLoadingListPO(true);
      dispatch(getListPO(query))
        .then((res) => {
          setLoadingListPO(false);
          setCheckedListPO([]);
          setSelectedRows([])
          dispatch(getItemType())
        })
        .catch(() => {
          setLoadingListPO(false);
          dispatch({
            type: "LIST_PO",
            payload: [],
          });
        })
    }else{
      setCheckedListPO([]);
      setSelectedRows([])
      dispatch({
        type: "LIST_PO",
        payload: [],
      });
    }
  };
 
  const handleFormChange = (event,dataSelect) => {
    const dataNew = { ...data };
    dataNew[event.target.name] = event.target.value;
    setData(dataNew);
    // if(event.target.name === "vat"){
    //   if(event.target.value === "1"){
    //     setWhtType(dropdownWhtType.filter((d)=>d.value === "1"))
    //   }else{
    //     setWhtType(dropdownWhtType.filter((d)=>d.value === "0"))
    //   }
    // }
    if(event.target.name === "TopInstallment") {
      let dataTemp = [];
      let number = parseInt(dataNew.TopInstallment);
      let i;
      for (i = 1; i <= number; i++) {
        dataTemp.push({
          id: i,
          installment: i,
          dueDate: null,
          amount: null,
        });
      }
      setTopInstallmentDetail(dataTemp);
    }else if(event.target.name === "TopType"){
      if(event.target.value === "Installment"){
        setData({
          ...data,
          TopType: data.PaymentType !== "Advanced Payment" ? "Installment" : data.TopType ,
          TopDueDate:dueDate,
          TopInstallment:0,
          TopInstallmentDetail:[],
        })
      }else if(event.target.value === "Credit"){
        setData({
          ...data,
          TopType:"Credit",
          TopDueDate:dueDate,
          TopInstallment:0,
          TopInstallmentDetail:[],
          TopPercentage:null,
          TopAmount:0,
        })
        setTopInstallmentDetail([])
      }else if(event.target.value === "DownPayment" || event.target.value === "COD" || event.target.value === "CBD" ){
        setData({
          ...data,
          TopType:event.target.value,
          TopDueDate:dueDate,
          TopInstallment:0,
          TopInstallmentDetail:[],
        })
        setTopInstallmentDetail([])
      }
    }
    else if(event.target.name === "whtType"){
      const dataItemTemp = dataItemType
      const selected = dataItemTemp.findIndex((row)=>row.id === dataSelect.id)
      dataItemTemp[selected].itemTypeWhtType=event.target.value
      setItemType(dataItemTemp)

      // let newDataWhtSubType = [{ label: "---", value: "---" }];
        let subTypeTemp = dropdownWht.filter((d,index)=>{
          return d.companyCode === data.companyCode && d.taxType === dataItemTemp[selected].itemTypeWhtType
        });
        
        if (subTypeTemp.length >= 1) {
          // remark sementara
          // let newDataDrowdownSubType=[]
          subTypeTemp.forEach((data, i) => {
            data.label=data.serviceType
            // newDataDrowdownSubType.push({
              // name:data.serviceType,
              // value:data.serviceType
            })
          // });
          // setWhtSubType(newDataDrowdownSubType);
          setWhtSubType(subTypeTemp);
        }
        
    }
    else if(event.target.name === "VendorInvoiceNumber"){
      let dataTemp = attachSupportDoc
      dataTemp[0].documentNo=event.target.value
      setAttachSupportDoc(dataTemp)
    }else if(event.target.name === "BankAccountNo"){
      const dataBank = listBankAccount.filter((d)=>{
        return d.bankCode === data.BankName && d.bankAccount === event.target.value
      })
      setData({
        ...data,
        BankAccountNo:event.target.value,
        BankAccountName:dataBank[0].bankAccountName
      })
    }else if(event.target.name === "TopPercentage"){
      let amount = (event.target.value/100) * Number(data.TotalAmountBeforeTax) 
      if (amount >data.TotalAmountBeforeTax ) {
        setData({
          ...data,
          TopPercentage: data.TopType === "DownPayment" ? event.target.value > 99 ? 99 : event.target.value : event.target.value > 100 ? 100 : event.target.value,
          TopAmount:amount.toFixed(2),
          TopPercentageCondition:true
        })
      }else{
        setData({
          ...data,
          TopPercentage: data.TopType === "DownPayment" ? event.target.value > 99 ? 99 : event.target.value : event.target.value > 100 ? 100 : event.target.value,
          TopAmount:amount.toFixed(2),
          TopPercentageCondition:false
        })
      }
    }
  };

  const handleChangeBilledQty =(e,dataSelect)=>{
      const dataNew =data.ItemList;
      const datatemp =data.ItemListtemp;
    
      const selected = datatemp.findIndex((row)=>row.id === dataSelect.id)
      const balanceQtytemp = datatemp[selected].balanceQty
      const value = Number(e.target.value)
      const newArr = dataNew.map((obj) => {
        if (obj.item === dataSelect.item && obj.poNo === dataSelect.poNo) {
          // mengambil dari balance qty
          if (value > balanceQtytemp) {
            if(obj.currency === "IDR"){
              return { ...obj, billedQty: balanceQtytemp,balanceQty: balanceQtytemp,amountInvoice:Math.ceil(balanceQtytemp*obj.pricePerUnit) };
            }else{
              return { ...obj, billedQty: balanceQtytemp,balanceQty: balanceQtytemp,amountInvoice:(balanceQtytemp*obj.pricePerUnit).toFixed(2) };
            }
            
          } else {
            if(obj.currency === "IDR"){
              return { ...obj, billedQty: value,balanceQty: value,amountInvoice:Math.ceil(value*obj.pricePerUnit) };
            }else{
              return { ...obj, billedQty: value,balanceQty: value,amountInvoice:(value*obj.pricePerUnit).toFixed(2) };
            }
            
          }
        }
        return obj;
      });


      let beforeTaxTemp=[]
      let dataSelectedItemList=data.selectedItemList
      let beforeTax;
      if (dataSelectedItemList !== null && dataSelectedItemList.length !== 0) {
        let filterData = newArr.filter((d)=>{
        return dataSelectedItemList.find((itm)=>{
          return itm.conditionId === d.conditionId
        })
        })

        if (filterData.length >= 1) {
          filterData.forEach((d)=>{
            beforeTaxTemp.push(Number(d.amountInvoice))
          })
          if(currency !== null || currency !== undefined){
            if (currency[0] === "IDR") {
              beforeTax = beforeTaxTemp.reduce((a, b) => a + b, 0)
            }else{
              beforeTax = beforeTaxTemp.reduce((a, b) => a + b, 0).toFixed(2)
            }
          }
        }
      }

      // logic untuk checklist itemlist duluan
      let tempSelectedItemList = [];
      if(data.selectedItemList.length > 0){
        const filterNewArrTrue = newArr.filter((d)=>d.isCheckBox === true)
        if (filterNewArrTrue.length > 0) {
          filterNewArrTrue.forEach((d)=>{
            tempSelectedItemList.push({
              amountInvoice: Number(d.amountInvoice),
              item: d.item,
              billedQty: parseInt(d.balanceQty),
              conditionId: d.conditionId,
              poNo: d.poNo,
            });
          })
        }
      }

      setData({
        ...data,
        selectedItemList: data.selectedItemList.length > 0 ? tempSelectedItemList : [],
        TotalAmountBeforeTax:dataSelectedItemList.length >= 1 ? beforeTax:0,
        ItemList: newArr,
      });
   
  }

  const handleChangeItemType=(e,dataSelect, vatRate = 0, dppRate = 0)=>{
      let valueItemType = e.target.name === "itemTypeAmount" ? RemoveFormatting(e.target.value) :e.target.value
      let ammountTemp =[]
      let ammountToBePaid =[]

      const dataNew =dataItemType;
      const selected = dataNew.findIndex((row) => row.id === dataSelect.id)
      dataNew[selected][e.target.name]= Number(valueItemType)
      const {vat,wht}=dataSelect
      // let dt = 0;
      if(e.target.value === "itemTypeDppAmount"){
        // dt = e.target.value;
        dataNew[selected].itemTypeDppAmount = e.target.value;
      }
      if(vat ===1){
        if (dataNew[selected].itemTypeVat !== null && dataNew[selected].itemTypeVat !== 0) {
          if(currency !== null&& currency !== undefined){
            // if(currency[0] === "IDR"){
            //   dataNew[selected].itemTypeVatAmount = Math.ceil((dataNew[selected].itemTypeVatRate / 100) * dataNew[selected].itemTypeAmount)
            // }else{
            //   dataNew[selected].itemTypeVatAmount = ((dataNew[selected].itemTypeVatRate / 100) * dataNew[selected].itemTypeAmount).toFixed(2)
            // }
          }else{
            dataNew[selected].itemTypeVatAmount = ((dataNew[selected].itemTypeVatRate / 100) * dataNew[selected].itemTypeDppAmount).toFixed(2)
          }
        } else {
          dataNew[selected].itemTypeWhtAmountToBePaid = dataNew[selected].itemTypeAmount
        }
      }else{
        dataNew[selected].itemTypeWhtAmountToBePaid = dataNew[selected].itemTypeAmount
      }

      if(wht ===1 && vat ===1){
        let whtRate=dataNew[selected].itemTypeWhtRate/100
        dataNew[selected].itemTypeWhtAmount=(whtRate*Number(dataNew[selected].itemTypeAmount)).toFixed(2)
        if(currency !== null&& currency !== undefined){
          if(currency[0] === "IDR"){
            dataNew[selected].itemTypeWhtAmountToBePaid=Math.ceil((dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount))) 
          }else{
            dataNew[selected].itemTypeWhtAmountToBePaid=(dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount)).toFixed(2)
          }
        }else{
          dataNew[selected].itemTypeWhtAmountToBePaid=(dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount)).toFixed(2)
        }
      }else if(wht ===0 && vat ===1){
        if (dataNew[selected].itemTypeVatAmount !== null && parseInt(dataNew[selected].itemTypeVatAmount) !== 0) {
          dataNew[selected].itemTypeWhtAmountToBePaid=(Number(dataNew[selected].itemTypeVatAmount)+dataNew[selected].itemTypeAmount).toFixed(2)
        }
      }

      if(e.target.name === "itemTypeWhtRate"){
        dataNew[selected][e.target.name]= Number(valueItemType)
        if(currency !== null&& currency !== undefined){
          if (currency[0] === "IDR") {
            dataNew[selected].itemTypeWhtAmount=Math.ceil((Number(valueItemType/100)*dataNew[selected].itemTypeAmount))
          }else{
            dataNew[selected].itemTypeWhtAmount=(Number(valueItemType/100)*dataNew[selected].itemTypeAmount).toFixed(2)
          }
        }else{
          dataNew[selected].itemTypeWhtAmount=(Number(valueItemType/100)*dataNew[selected].itemTypeAmount).toFixed(2)
        }
        dataNew[selected].itemTypeWhtAmountToBePaid=dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount).toFixed(2)
      }
      else if(e.target.name === "itemTypeTaxConfId"){
        if(currency !== null&& currency !== undefined){
          if (currency[0] === "IDR") {
            dataNew[selected].itemTypeVatAmount = Math.round((Number(vatRate/100)*dataNew[selected].itemTypeDppAmount))
            // dataNew[selected].itemTypeDppAmount = Math.ceil((Number(dppRate/100)*dataNew[selected].itemTypeAmount))
          }
        }else{
          dataNew[selected].itemTypeWhtAmount=(Number(valueItemType/100)*dataNew[selected].itemTypeAmount).toFixed(2)
        }
        dataNew[selected].itemTypeWhtAmountToBePaid=dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount).toFixed(2)
      }

      // amount to be paid
      // perbandingan material & services = amount before tax
      let filterSelected = dataNew.filter(function(o1){
        return data.selectedItemType.some(function(o2){
            return o1.description === o2.itemTypeDesc && o1.itemTypeAmount !== 0 ;
        });
      })
      
      const filterMaterialService = filterSelected.filter((d)=>d.description=== "Services" || d.description=== "Material")
      
      let updateCheckBoxtrue = checkedItemType.filter(function(o1){
        return filterSelected.some(function(o2){
            return o1 === o2.id  ;
        });
      })
      setCheckedItemType(updateCheckBoxtrue)

      filterMaterialService.forEach((data)=>ammountTemp.push(data.itemTypeAmount))
      filterSelected.forEach((data)=>ammountToBePaid.push(Number(data.itemTypeWhtAmountToBePaid)))
      
      let amount = ammountTemp.reduce((a, b) => a + b, 0).toFixed(2)
      let ammountToBePaidTemp = Number(ammountToBePaid.slice(0, ammountToBePaid.length).reduce((a, b) => a + b, 0))
      
      let selectedTemp = [];
      filterSelected.forEach((d)=>{
        selectedTemp.push({
          itemTypeNo: d.seq,
          itemTypeDesc: d.description,
          itemTypeAmount: Number(d.itemTypeAmount),
          itemTypeVat: d.itemTypeVat === null ? 0 : d.itemTypeVat,
          itemTypeVatRate: d.itemTypeVatRate,
          itemTypeTaxConfId: d.itemTypeTaxConfId === undefined ? "" : d.itemTypeTaxConfId,
          itemTypeVatAmount: Number(d.itemTypeVatAmount),
          itemTypeDppRate: d.itemTypeDppRate === undefined ? 0 : d.itemTypeDppRate,
          itemTypeDppAmount: Number(d.itemTypeDppAmount),
          itemTypeWht: d.itemTypeWht,
          itemTypeWhtType: d.itemTypeWhtType,
          itemTypeWhtSubtype: d.itemTypeWhtSubtype,
          itemTypeWhtRate: Number(d.itemTypeWhtRate),
          itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
          itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
        });
      })
      
      if(data.PaymentType === "Credit Payment"){
        let amountBeforeTax= data.TotalAmountBeforeTax === null ? 0 : Number(data.TotalAmountBeforeTax).toFixed(2);
        if (Number(amount).toFixed(2) !== amountBeforeTax ) {
          setData({
            ...data,
            TotalAmountServiceMaterial:amount,
            TotalAmountItemType:true,
            TotalAmountToBePaid:ammountToBePaidTemp,
            selectedItemType:selectedTemp
          })
        }else{
          setData({
            ...data,
            TotalAmountServiceMaterial:amount,
            TotalAmountItemType:false,
            TotalAmountToBePaid:ammountToBePaidTemp,
            selectedItemType:selectedTemp
          })
        }
      }else if(data.PaymentType === "Advanced Payment"){
        if (Number(amount).toFixed(2) !== Number(data.TopAmount).toFixed(2) ) {
          setData({
            ...data,
            TotalAmountServiceMaterial:amount,
            TotalAmountItemType:true,
            TotalAmountToBePaid:ammountToBePaidTemp,
            selectedItemType:selectedTemp
          })
        }else{
          setData({
            ...data,
            TotalAmountServiceMaterial:amount,
            TotalAmountItemType:false,
            TotalAmountToBePaid:ammountToBePaidTemp,
            selectedItemType:selectedTemp
          })
        }
      }
      setItemType(dataNew)
  }
 
  const handleCheckBox =(e,dataSelect)=>{
    
    const dataItemTemp = dataItemType
    let ammountToBePaid=[]
    const selected = dataItemTemp.findIndex((row) => row.id === dataSelect.id)
    
    if (e.target.name === "itemTypeVat") {
      // setmaterial(!material)
      dataItemTemp[selected].checkVat = !dataSelect.checkVat
      dataItemTemp[selected].itemTypeVat = 1
      if(dataSelect.vat === 1 && dataSelect.wht === 0 ){
        // if (dataSelect.checkVat) {
        //   dataItemTemp[selected].itemTypeVatRate=parseInt(listSysparam[0].value)
        //   dataItemTemp[selected].itemTypeVat=1
          
        //   if(currency !== null&& currency !== undefined){
        //     if(currency[0] === "IDR"){
        //       dataItemTemp[selected].itemTypeVatAmount=Math.ceil(((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount))
        //       dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount)
        //     }else{
        //       dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
        //       dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount).toFixed(2)
        //     }
        //   }else{
        //     dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
        //       dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount).toFixed(2)
        //   }
          
        // }else{
        //   dataItemTemp[selected].itemTypeVat=0
        //   dataItemTemp[selected].itemTypeVatRate=0
        //   dataItemTemp[selected].itemTypeVatAmount=0
        //   dataItemTemp[selected].itemTypeWhtAmountToBePaid=dataItemTemp[selected].itemTypeAmount
        // }
      }
      else if(dataSelect.vat === 1 && dataSelect.wht === 1){
        // if (dataSelect.checkVat) {
        //   dataItemTemp[selected].itemTypeVatRate=parseInt(listSysparam[0].value)
        //   dataItemTemp[selected].itemTypeVat=1
        //   if(currency !== null&& currency !== undefined){
        //     if(currency[0] === "IDR"){
        //       dataItemTemp[selected].itemTypeVatAmount=Math.ceil(((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount))
        //     }else{
        //       dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
        //     }
        //     dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount)-dataItemTemp[selected].itemTypeWhtAmount
        //   }else{
        //     dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
        //     dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount)-dataItemTemp[selected].itemTypeWhtAmount.toFixed(2)
        //   }
        // }else{
        //   dataItemTemp[selected].itemTypeVat=0
        //   dataItemTemp[selected].itemTypeVatRate=0
        //   dataItemTemp[selected].itemTypeVatAmount=0
        //   dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)).toFixed(2)
        // }
      }
    }
  
    else if(e.target.name === "itemTypeWht"){
    dataItemTemp[selected].checkWht=!dataSelect.checkWht
      // setwhtservice(!whtservice)
      if (dataSelect.checkWht) {
        dataItemTemp[selected].itemTypeWht= 1
        // dataItemTemp[1].itemTypeWhtAmountToBePaid=dataItemTemp[1].itemTypeVatAmount+dataItemTemp[1].itemTypeAmount+dataItemTemp[1].itemTypeWhtAmount
      }else{
        // dataItemTemp[1].itemTypeWhtAmountToBePaid=dataItemTemp[1].itemTypeAmount+dataItemTemp[1].itemTypeWhtAmount
        dataItemTemp[selected].itemTypeWht=0

      }
    }
    dataItemTemp.forEach((data,index)=>{
      ammountToBePaid.push(Number(data.itemTypeWhtAmountToBePaid))
    })
    let ammountToBePaidTemp = Number(ammountToBePaid.slice(0, ammountToBePaid.length).reduce((a, b) => a + b, 0))
    
    // mengambil data selected (isCheckbox true) dan ada perubahan data
    const findisCheckBoxTrue = dataItemTemp.filter((elem) => checkedItemType.find((e)=>elem.id === e));
    let dataTempfindisCheckBoxTrue=[]
    if (findisCheckBoxTrue.length >= 1) {
      findisCheckBoxTrue.forEach((d)=>{
        dataTempfindisCheckBoxTrue.push({
          itemTypeNo: d.seq,
          itemTypeDesc: d.description,
          itemTypeAmount: Number(d.itemTypeAmount),
          itemTypeVat: d.itemTypeVat === null ? 0 : d.itemTypeVat,
          itemTypeTaxConfId: d.itemTypeTaxConfId === undefined ? "" : d.itemTypeTaxConfId,
          itemTypeVatRate: d.itemTypeVatRate,
          itemTypeVatAmount: Number(d.itemTypeVatAmount),
          itemTypeDppRate: d.itemTypeDppRate === undefined ? 0 : d.itemTypeDppRate,
          itemTypeDppAmount: Number(d.itemTypeDppAmount),
          itemTypeWht: d.itemTypeWht,
          itemTypeWhtType: d.itemTypeWhtType,
          itemTypeWhtSubtype: d.itemTypeWhtSubtype,
          itemTypeWhtRate: Number(d.itemTypeWhtRate),
          itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
          itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
          })
      })
    }
    
    
    setData({
      ...data,
      selectedItemType:dataTempfindisCheckBoxTrue,
      TotalAmountToBePaid:ammountToBePaidTemp
    })
      setItemType(dataItemTemp)
  }
  
  const theColumns = [
    {
      field: " ",
      editable: false,
      filter: false,
      width: 100,
      renderHeader: () => {
        return <Checkbox
          sx={{color:"#FFFFFF"}}
          checked={checkAllListPO}
          indeterminate={indeterminateListPO}
          onChange={(e) => {
            setCheckAllListPO(e.target.checked);
            if(e.target.checked){
              let newArray = [];

              listPo.forEach((dataPo) => {
                if(dataPo["isVisible"]){
                  newArray.push(dataPo["id"]);
                  setIndetListPO(true);
                }
              })

              setCheckedListPO(newArray);
            }else{
              setCheckedListPO([]);
              setIndetListPO(false);
            }
          }}
          id="chk-all-list-po"
         />;
      },
      renderCell: (cellValues) => {
        return <Checkbox 
          sx={{marginLeft:"5px"}}
          checked={checkedListPO.includes(cellValues.row.id) ? true : false}
          id={"chk" + cellValues.row.id}
          disabled={cellValues.row.isVisible ? false : true}
          onChange={(e) => {
            if(e.target.checked){
              let tempCheckedListPO = [...checkedListPO];
              tempCheckedListPO.push(cellValues.row.id);
              setCheckedListPO(tempCheckedListPO);
            }else{
              let tempCheckedListPO = [...checkedListPO];
              let foundID = tempCheckedListPO.find(p => p === cellValues.row.id);

              if(foundID !== undefined){
                tempCheckedListPO = tempCheckedListPO.filter(p => p !== foundID);
              }

              setCheckedListPO(tempCheckedListPO);
            }
          }}
        />;
      },
    },
    {
      field: "poNo",
      headerName: "PO Number",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "costCenter",
      headerName: "Cost Center",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "createBy",
      headerName: "SAP Created By",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "paymentTerm",
      headerName: "Payment Term",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "paymentIn",
      headerName: "Payment In",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "companyCode",
      headerName: "Company Code",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "poType",
      headerName: "PO Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        switch (cellValues.row.poType) {
          case 0:return <p>Non Trade</p>
          case 1:return <p>Trade</p>
          default:
            return null
        }
      },
    },
    {
      field: "purchaseOrganization",
      headerName: "Purchasing Org",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "purchaseGroup",
      headerName: "Purch. Group",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "exchangeRate",
      headerName: "Exchange rate",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "incoterms",
      headerName: "Incoterms",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "incotermsDesc",
      headerName: "Incoterms Desc",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 200,
    },
  ];
  const columnItemList = [
    {
      field: " ",
      editable: false,
      filter: false,
      width: 100,
      disableExport:true,
      renderHeader: () => {
        return <Checkbox
          sx={{color:"#FFFFFF"}}
          checked={checkAllItemList}
          onChange={(e) => {
            setCheckAllItemList(e.target.checked);
            if(e.target.checked){
              let newArray = [];

              data.ItemList.forEach((dataPo) => {
                newArray.push(dataPo["id"]);
              })
              setCheckedItemList(newArray);
            }else{
              setCheckedItemList([]);
            }
          }}
          id="chk-all-item-list"
         />;
      },
      renderCell: (cellValues) => {
        return <Checkbox 
          sx={{marginLeft:"5px"}}
          checked={checkedItemList.includes(cellValues.row.id) ? true : false}
          id={"chk" + cellValues.row.id}
          onChange={(e) => {
            if(e.target.checked){
              let tempCheckedItemList = [...checkedItemList];
              tempCheckedItemList.push(cellValues.row.id);
              setCheckedItemList(tempCheckedItemList);
            }else{
              let tempCheckedItemList = [...checkedItemList];
              let foundID = tempCheckedItemList.find(p => p === cellValues.row.id);

              if(foundID !== undefined){
                tempCheckedItemList = tempCheckedItemList.filter(p => p !== foundID);
              }

              setCheckedItemList(tempCheckedItemList);
            }
          }}
        />;
      },
    },
    {
      field: "poNo",
      headerName: "PO Number",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "item",
      headerName: "Item",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 60,
    },
    {
      field: "awbInvoiceNo",
      headerName: "AWB/INV NO",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
      renderCell:(cellValues)=>{
        switch (cellValues.row.awbInvoiceNo) {
          case "NULL" : return ""
          default:
            return cellValues.row.awbInvoiceNo
        }
      }
    },
    {
      field: "material",
      headerName: "Material",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "shortText",
      headerName: "Short Text (Item)",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 160,
    },
    {
      field: "condition",
      headerName: "CnTy",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "conditionDesc",
      headerName: "Cnty Desc",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "quantity",
      headerName: "Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 60,
    },
    {
      field: "currency",
      headerName: "Curr",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 60,
    },
    {
      field: "netValue",
      headerName: "Net Value",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        if(cellValues.row.netValue !== null && cellValues.row.netValue !== undefined){
          return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.netValue} />
        }
      },
    },
    {
      field: "itemStatus",
      headerName: "Item Status",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
      renderCell:(cellValues)=>{
        switch (cellValues.row.itemStatus) {
          case "NULL" : return ""
          default:
            return cellValues.row.itemStatus
        }
      }
    },
    {
      field: "grStatus",
      headerName: "GR Status",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "billedQty",
      headerName: "Billed Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      type: 'number',
      width: 90,
      renderCell: (cellValues) => {
        return (
          <input
            value={cellValues.row.balanceQty}
            onChange={(e)=>handleChangeBilledQty(e,cellValues.row)}
            className="input-BilledQty"
            id="billedQty"
            name="billedQty"
            type="number"
            onPaste={preventPasteNegative}
            onKeyPress={preventMinus}
          />
        );
      },
    },
    {
      field: "amountInvoice",
      headerName: "Amount Inv.",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
      disableExport:true,
    },
  ];

  const columnItemType = [
    {
      field: " ",
      editable: false,
      filter: false,
      width: 100,
      renderHeader: () => {
        let disableChkAllItemType = true;
        dataItemType.forEach((dataPo) => {
          if(dataPo.itemTypeAmount !== 0 && dataPo.itemTypeAmount !== null){
            disableChkAllItemType = false;
          }
        })
        return <Checkbox
          sx={{color:"#FFFFFF"}}
          checked={checkAllItemType}
          disabled={disableChkAllItemType}
          indeterminate={indeterminateItemType}
          onChange={(e) => {
            setCheckAllItemType(e.target.checked);
            if(e.target.checked){
              let newArray = [];

              dataItemType.forEach((dataPo) => {
                //check only all item type with amount not null
                if(dataPo.itemTypeAmount !== "" && dataPo.itemTypeAmount !== null){
                  newArray.push(dataPo["id"]);
                }else{
                  setIndeterminateItemType(true);
                }
              })

              setCheckedItemType(newArray);
            }else{
              setCheckedItemType([]);
            }
          }}
          id="chk-all-item-type"
         />;
      },
      renderCell: (cellValues) => {
        return <Checkbox 
          sx={{marginLeft:"5px"}}
          checked={checkedItemType.includes(cellValues.row.id) ? true : false}
          disabled={cellValues.row.itemTypeAmount === 0 || cellValues.row.itemTypeAmount === "" || cellValues.row.itemTypeAmount === null ? true : false}
          id={"chk" + cellValues.row.id}
          onChange={(e) => {
            // handleCheckBox(e, cellValues.row)
            if(e.target.checked){
              //set row id to checked item type list
              if(cellValues.row.itemTypeAmount !== "" && cellValues.row.itemTypeAmount !== null && cellValues.row.itemTypeAmount !== 0){
                let tempCheckedItemType = [...checkedItemType];
                tempCheckedItemType.push(cellValues.row.id);
                setCheckedItemType(tempCheckedItemType);
              }
            }else{
              //unset row id from checked item type list
              let tempCheckedItemType = [...checkedItemType];
              let foundID = tempCheckedItemType.find(p => p === cellValues.row.id);

              if(foundID !== undefined){
                tempCheckedItemType = tempCheckedItemType.filter(p => p !== foundID);
              }

              setCheckedItemType(tempCheckedItemType);
            }
          }}
        />;
      },
    },
    {
      field: "description",
      headerName: "Item Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
    },
    {
      field: "itemTypeAmount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        return (
          <TextField
            onChange={(e) => handleChangeItemType(e, cellValues.row)}
            margin="none"
            InputProps={{
                inputComponent: NumberFormatCustom,
                className:data.TotalAmountItemType === true ? "input-amountType-warning" :"input-amountType"
            }}
            name="itemTypeAmount"
            size="small"
            value={cellValues.row.itemTypeAmount}
        />
        );
      },
    },
    {
      field: "itemTypeVat",
      headerName: "VAT",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 80,
      renderCell: (cellValues) => {
          return <Checkbox name="itemTypeVat" disabled={(cellValues.row.itemTypeAmount === 0 || cellValues.row.itemTypeAmount === null) && true } checked={cellValues.row.checkVat} onChange={(e)=>handleCheckBox(e,cellValues.row)} />
      },
    },
    {
      field: "itemTypeDppAmount",
      headerName: "DPP Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 200,
      renderCell: (cellValues) => {
        return (
        <TextField 
          type="text"
          name="itemTypeDppAmount"
          disabled={!cellValues.row.itemTypeVat ?? true}
          margin="none"
          // InputProps={{
          //     // inputComponent: NumberFormatCustom,
          //     className:"input-amountType"
          // }}
          size="small"
          value={cellValues.row.itemTypeDppAmount}
          onChange={(e) => handleChangeItemType(e, cellValues.row)}
        />
        )
      },
    },
    {
      field: "itemListVatRate",
      headerName: "VAT Rate",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 200,
      renderCell: (cellValues) => {
        console.log(cellValues.row)
        switch (cellValues.row.vat) {
          case 1:
            return (
              <select disabled={!cellValues.row.checkVat} name="itemTypeTaxConfId" className="form-select w-100" 
              onChange={(e)=>{
                let taxs = handleTaxConfig(e.target.value, cellValues.row.itemTypeAmount)
                console.log(taxs)
                cellValues.row.itemTypeVatRate = taxs.vatRate
                // cellValues.row.itemTypeDppRate = taxs.dppRate
                cellValues.row.itemTypeTaxConfId = e.target.value ?? null
                handleChangeItemType(e,cellValues.row, taxs.vatRate)
              }}
              id={"taxConf-"+cellValues.row.id}
              >
                  {dropdownTaxConfig.map((status, key) => (
                    <option key={"taxConfId-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
            )
          default:
            return null
        }
      },
    },
    {
      field: "itemTypeVatAmount",
      headerName: "VAT Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
      renderCell: (cellValues) => {
        switch (cellValues.row.vat) {
          case 1: 
          <Input type="hidden" name="itemTypeVatAmount" value={Number(cellValues.row.itemTypeVatAmount)} disabled/>
          return (
            <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.itemTypeVatAmount} />
          )   
          default:
            return null;
        }
      },
    },
    {
      field: "itemTypeWht",
      headerName: "Wht",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 80,
      renderCell: (cellValues) => {
        switch (cellValues.row.wht) {
          case 1:return <Checkbox disabled={data.TaxType === "nonTaxable" || data.TaxType === "" || cellValues.row.itemTypeAmount === 0 || cellValues.row.itemTypeAmount === null? true:false} name="itemTypeWht" checked={cellValues.row.checkWht} onChange={(e)=>handleCheckBox(e,cellValues.row)}/>
          default:
            return null
        }
      },
    },
    {
      field: "whtType",
      headerName: "Wht Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        switch (cellValues.row.wht) {
          case 1:
            return (
              <select disabled={!cellValues.row.checkWht} name="whtType" className="select-item-Type" 
              onChange={(e)=>{
                // setSelectedTempItemType(cellValues.row)
                handleFormChange(e,cellValues.row)
                
              }}
              id={"whttype-"+cellValues.row.id}
              >
                  {dropdownWhtType.map((status, key) => (
                    <option key={"whttype-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
            )
          default:
            return null
        }
      },
    },
    {
      field: "whtSubType",
      headerName: "Wht Sub Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 300,
      renderCell: (cellValues) => {
        
        switch (cellValues.row.wht) {
          case 1:
            return (
              <Autocomplete
                disablePortal
                onChange={(e,newValue)=>{
                  
                  if (newValue !== null) {
                    const dataItemTemp = dataItemType
                    const selected = dataItemTemp.findIndex((row)=>row.id === cellValues.row.id)
                    let ammountToBePaid=[]
                    let whtRate = null
                    let whtRateFilter = dropdownWht.filter((d)=>{
                      return d.companyCode === data.companyCode && d.taxType === data.whtType && d.serviceType === newValue.serviceType
                    });
                    
                    if (whtRateFilter.length >= 1 ) {
                      let vatRegistration = listInfoVendor[0].vatRegistration
                      if (vatRegistration !== null && vatRegistration !== "") {
                        whtRate=whtRateFilter[0].npwpRate
                      }else{
                        whtRate=whtRateFilter[0].nonNpwpRate
                      }
                    }
                    dataItemTemp[selected].itemTypeWhtSubtype=newValue.serviceType
                    dataItemTemp[selected].itemTypeWhtRate=Number(whtRate*100)
                    
                    if (currency !== null && currency !== undefined) {
                      if (currency[0] === "IDR") {
                      dataItemTemp[selected].itemTypeWhtAmount=Math.ceil((Number(whtRate)*dataItemTemp[selected].itemTypeAmount))
                      dataItemTemp[selected].itemTypeWhtAmountToBePaid=Math.ceil(dataItemTemp[selected].itemTypeAmount+Number(dataItemTemp[selected].itemTypeVatAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)) 

                      } else {
                      dataItemTemp[selected].itemTypeWhtAmount=(Number(whtRate)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
                      dataItemTemp[selected].itemTypeWhtAmountToBePaid=(dataItemTemp[selected].itemTypeAmount+Number(dataItemTemp[selected].itemTypeVatAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)).toFixed(2)

                      }
                    }else{
                      dataItemTemp[selected].itemTypeWhtAmount=(Number(whtRate)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
                      dataItemTemp[selected].itemTypeWhtAmountToBePaid=(dataItemTemp[selected].itemTypeAmount+Number(dataItemTemp[selected].itemTypeVatAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)).toFixed(2)
                    }

                    let filterSelected = dataItemTemp.filter(function(o1){
                      return data.selectedItemType.some(function(o2){
                          return o1.description === o2.itemTypeDesc;
                      });
                    })
                    
                    filterSelected.forEach((data)=>ammountToBePaid.push(Number(data.itemTypeWhtAmountToBePaid)))
                    let ammountToBePaidTemp = Number(ammountToBePaid.slice(0, 2).reduce((a, b) => a + b, 0))

                    const selectedTemp =[]
                    filterSelected.forEach((d)=>{
                      selectedTemp.push({
                        itemTypeNo: d.seq,
                        itemTypeDesc: d.description,
                        itemTypeAmount: Number(d.itemTypeAmount),
                        itemTypeTaxConfId: d.itemTypeTaxConfId === undefined ? "" : d.itemTypeTaxConfId,
                        itemTypeVat: d.itemTypeVat === null ? 0 : d.itemTypeVat,
                        itemTypeVatRate: d.itemTypeVatRate,
                        itemTypeVatAmount: Number(d.itemTypeVatAmount),
                        itemTypeDppRate: d.itemTypeDppRate === undefined ? 0 : d.itemTypeDppRate,
                        itemTypeDppAmount: Number(d.itemTypeDppAmount),
                        itemTypeWht: d.itemTypeWht,
                        itemTypeWhtType: d.itemTypeWhtType,
                        itemTypeWhtSubtype: d.itemTypeWhtSubtype,
                        itemTypeWhtRate: Number(d.itemTypeWhtRate),
                        itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
                        itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
                      });
                    })
                    
                    setData({
                      ...data,
                      selectedItemType:selectedTemp,
                      TotalAmountToBePaid:ammountToBePaidTemp
                    })
                    setItemType(dataItemTemp)
                  }
                  
                }}
                options={dropdownWhtSubType}
                renderOption={(props) => (
                  <Tooltip title={props.key} placement="bottom">
                    <li {...props}>{props.key}</li>
                  </Tooltip>
                )}
                id="whtSubType"
                name="whtSubType"
                getOptionLabel={(option) => option.label}
                size="small"
                disabled={!cellValues.row.checkWht}
                renderInput={(params) => <TextField {...params} size="small" label="" />}
                sx={{ width: 300 }}
                value={dropdownWht.find((d)=>d.companyCode === data.companyCode && d.taxType === cellValues.row.itemTypeWhtType && d.serviceType === cellValues.row.itemTypeWhtSubtype) !== undefined ?
                    dropdownWht.find((d)=>d.companyCode === data.companyCode && d.taxType === cellValues.row.itemTypeWhtType && d.serviceType === cellValues.row.itemTypeWhtSubtype ):null}
              />
            )
          default:
            return null
        }
      },
    },
    {
      field: "itemTypeWhtRate",
      headerName: "Wht Rate",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell:(cellValues)=>{
        switch (cellValues.row.wht) {
          case 1:
            return(
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  disabled={data.TaxType === "nonTaxable" || data.TaxType === "" || cellValues.row.itemTypeAmount === 0 || cellValues.row.itemTypeAmount === null? true:false}
                  value={cellValues.row.itemTypeWhtRate}
                  onChange={(e)=>handleChangeItemType(e,cellValues.row)}
                    className="input-BilledQty"
                    id="itemTypeWhtRate"
                    name="itemTypeWhtRate"
                    type="number"
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                />
                <p style={{ marginLeft: "20px" }}>%</p>
              </div>
            )
          
        default:
          return <p></p>;
      }
    }},
    {
      field: "itemTypeWhtAmount",
      headerName: "Wht Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
      renderCell:(cellValues)=>{
        switch (cellValues.row.wht) {
          case 1:
            return(
              <input
                value={cellValues.row.itemTypeWhtAmount}
                onChange={(e)=>handleChangeItemType(e,cellValues.row)}
                  className="input-BilledQty"
                  id="itemTypeWhtAmount"
                  name="itemTypeWhtAmount"
                  type="number"
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
              />
            )
          default:
            return<p></p>;
      }
    }},
    {
      field: "itemTypeWhtAmountToBePaid",
      headerName: "Amount To Be Paid",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
      renderCell:(cellValues)=>{
        const dataCurr=currency
        let data=cellValues.row.itemTypeWhtAmountToBePaid
        if (dataCurr !== null && dataCurr !== undefined) {
          if(dataCurr[0] !== null){
            data=Number(cellValues.row.itemTypeWhtAmountToBePaid)
          }else{
            data=Number(cellValues.row.itemTypeWhtAmountToBePaid).toFixed(2)
          }
        }
       
        return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={data} />
      }
    },
  ];

  

  const theColumnsError = [
    {
      field: "error_Description",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 500,
    },
    {
      field: "purchaseNo",
      headerName: "PO Number",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "item",
      headerName: "Item",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 80,
    },
    {
      field: "balanceQty",
      headerName: "Balance Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 100,
    },
    {
      field: "billedQty",
      headerName: "Billed Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 100,
    },
    
    
    
  ];

  const [TopInstallmentDetail, setTopInstallmentDetail] = useState([]);
  const columnInstallment = [
    {
      field: "installment",
      headerName: "Installment",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 200,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 500,
      renderCell: (cellValues) => {
        const currentDate = new Date().toISOString().split('T')[0];
        return (
          <input
            className="input-instalment"
            id="dueDate"
            name="dueDate"
            type="date"
            min={currentDate}
            defaultValue={cellValues.row.dueDate}
            onChange={(e)=>handleInstallment(e,cellValues.row.id)}
            onKeyDown={(e) => e.preventDefault()}
          />
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 490,
      renderCell: (cellValues) => {
        return (
          <TextField
            onChange={(e)=>handleInstallment(e,cellValues.row.id)}
            margin="none"
            InputProps={{
                inputComponent: NumberFormatCustom,
                className:data.TotalAmountInstallment === true ? "input-amountType-warning" :"input-amountType"
            }}
            name="amount"
            size="small"
            defaultValue={cellValues.row.amount}
        />
        );
      },
    },
  ];

  const handleInstallment =(e,dataId)=>{
    let valueInstallment = e.target.name === "amount" ? RemoveFormatting(e.target.value) : e.target.value
    let ammountTemp =[]

    const newData = TopInstallmentDetail
    const selected = newData.findIndex((d)=> d.id === dataId)
    newData[selected][e.target.name]=valueInstallment
    let detailTemp =[]
    newData.forEach((d)=>{
      detailTemp.push({
        installment:d.installment,
        dueDate:d.dueDate,
        amount:Number(d.amount)
      })
      ammountTemp.push(Number(d.amount))
    })
    let amount = ammountTemp.reduce((a, b) => a + b, 0).toFixed(2)

    let ammountToBePaid = data.TotalAmountToBePaid === null ? 0 : data.TotalAmountToBePaid.toFixed(2);
    if (amount < ammountToBePaid || amount > ammountToBePaid ) {
      setData({
        ...data,
        TotalAmountInstallment:true,
        TopInstallmentDetail:detailTemp
      })
    }else{
      setData({
        ...data,
        TotalAmountInstallment:false,
        TopInstallmentDetail:detailTemp
      })
    }

    // setData({
    //   ...data,
    //   TopInstallmentDetail:detailTemp
    // })
    setTopInstallmentDetail(newData)
  }
  
  
  const handleClick = (e) => {
    setSelectTemp(e)
    hiddenFileInput.current.click();
  };
  
  const handleUpload = (event) => {
    const dataTemp = attachSupportDoc
    const selected = dataTemp.findIndex((row)=>row.idDocSetting === selectTemp.idDocSetting)
    if (event.target.files.length >= 1) {
      dataTemp[selected].fileName=event.target.files[0].name
      dataTemp[selected].fileSupporting=event.target.files[0]
      dataTemp[selected].upload=true
      setAttachSupportDoc(dataTemp)
      let payloadAttach=[]
      dataTemp.forEach((d)=>{
        payloadAttach.push({
          DocSettingId:d.idDocSetting,
          DocumentNo:d.documentNo,
          FileSupporting:d.fileSupporting
        })
      })
      setData({
        ...data,
        attachment:payloadAttach
      })
      
    }
};

const handleDeleteFile = (dataSelect) => {
  setSelectTemp(dataSelect)
  const dataTemp = attachSupportDoc
  const selected = dataTemp.findIndex((row)=>row.idDocSetting === dataSelect.idDocSetting)
  dataTemp[selected].fileName=null
  dataTemp[selected].fileSupporting=null
  dataTemp[selected].upload=false
  setAttachSupportDoc(dataTemp)
  
  let payloadAttach=[]
      dataTemp.forEach((d)=>{
        payloadAttach.push({
          DocSettingId:d.idDocSetting,
          DocumentNo:d.documentNo,
          FileSupporting:d.fileSupporting
        })
      })
  setData({
    ...data,
    attachment:payloadAttach
  })
  };

  const handleDocumentNo = (e,dataSelected)=>{
    let dataTemp = attachSupportDoc
    let selected = dataTemp.findIndex((row)=>row.idDocSetting === dataSelected.idDocSetting)
    dataTemp[selected][e.target.name]=e.target.value.length >0?e.target.value:null
    setAttachSupportDoc(dataTemp)
    let payloadAttach=[]
    dataTemp.forEach((d)=>{
      payloadAttach.push({
        DocSettingId:d.idDocSetting,
        DocumentNo:d.documentNo,
        FileSupporting:d.fileSupporting
      })
    })
      setData({
        ...data,
        attachment:payloadAttach
      })
  }
  const columnSupportDoc = [
    {
      field: "documentName",
      headerName: "Document Name",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 490,
      renderCell:(cellValues)=>{
        // validate doc
      // 1.semua vendor validate hanya invoice number
      // 2. jika local validate 1-4 (invoice vendor,faktur pajak,surat jalan,BAST)
      // 3.vendor freight validate 5-12
      // const dataVendor = listInfoVendor[0].countryCode
      // if (dataVendor !== "ID") {
        
      // }
        return <p className={cellValues.row.mandatory === true ?"mandatory-doc":undefined}>{cellValues.row.documentName}</p>

      }
      
    },
    {
      field: "documentNo",
      headerName: "Document No",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 190,
      renderCell:(cellValues)=>{
        return <input className="input-document-no"   name="documentNo" value={cellValues.row.documentNo || ""}  onChange={(e)=>handleDocumentNo(e,cellValues.row)} />
      }
    },
    {
      field: "fileName",
      headerName: "Upload File",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 290,
      renderCell: (cellValues) => {
        return (
            <p className="uploadFile-name" >{cellValues.row.fileName}</p>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 290,
      
      renderCell: (cellValues) => {
        return (
          <div style={{display:"flex"}}>
            <p className="text-upload-file" onClick={()=>handleClick(cellValues.row)}>{cellValues.row.upload === false?"Upload File":"Replace File"}</p>
            <p className="delete-upload-text"onClick={()=>handleDeleteFile(cellValues.row)} >{cellValues.row.upload === true&& "Delete"}</p>
            <input
              id="fileAttach"
              name="fileAttach"
              type="file"
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(event) => handleUpload(event)}
              
              />
            </div>
        );
      },
    },
  ];

  const handleBack = () => {
    dispatch({
      type: "LIST_PO",
      payload: [],
    });
    dispatch({
      type: "ITEM_LIST_PO",
      payload: [],
    });
    dispatch(changeScreenIS("list-is"));

  };

  const handleIncreaseBtn = (number) => {
    number += 1;
    setData({
      ...data,
      TopInstallment: parseInt(number),
    });
    setTopInstallmentDetail([
      ...TopInstallmentDetail,
      {
        id: number,
        installment: number,
        dueDate: null,
        amount: null,
      },
    ]);
  };
  const handleDecreaseBtn = (number) => {
    number -= 1;
    if (number >= 0) {
      setData({
        ...data,
        TopInstallment: parseInt(number),
      });
      const newArr = [...TopInstallmentDetail];
      newArr.splice(-1);
      setTopInstallmentDetail(newArr);
    } else {
      Toastify({
        text: "installments must be more than 0",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        // stopOnFocus: true,
        style: {
          background: "#CB605A",
        },
      }).showToast();
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  
  
const handleSave = (type)=>{
    if (type === "save") {
      setLoadingSave(true)
    }else if(type === "submit"){
      setLoadingSubmit(true)
    }

    let cleanedSelectedItemList = [...data.selectedItemList];
    if(cleanedSelectedItemList.length > 0){
      cleanedSelectedItemList.forEach(selItemList => {
        delete selItemList.id;
      })
    }

    const newData ={
      InvoiceStatus:type === "save" ?0: type === "submit" ?1:null,
      TotalAmountToBePaid:data.TotalAmountToBePaid.toFixed(2),
      VendorInvoiceNumber:data.VendorInvoiceNumber,
      InvoiceDate:moment(today).format("YYYY-MM-DD"),
      POType:data.poType,
      PaymentTerm:data.paymentTerm,
      PaymentType:data.PaymentType,
      TaxType:data.TaxType,
      CompanyCode:data.companyCode,
      VendorCode:listInfoVendor[0].vendorCode,
      ListPOHdr:selectListPo,
      ItemList:data.selectedItemList.length >= 1?data.selectedItemList:null ,
      ItemTypeList:data.selectedItemType.length >= 1 ?data.selectedItemType:null,
      TopType:data.TopType,
      TopInstallment:data.TopInstallment,
      TopInstallmentDetail:data.TopInstallmentDetail.length >= 1 ?data.TopInstallmentDetail:null,
      BankName:data.BankName,
      BankAccountNo:data.BankAccountNo,
      BankAccountName:data.BankAccountName,
      TopDueDate:data.TopDueDate !== null ? moment(data.TopDueDate).format("YYYY-MM-DD") :null,
      TopPercentage:data.TopPercentage,
      TopAmount:data.TopAmount,
      SupportingDocuments:data.attachment.length >= 1 ?data.attachment:null
    }

    let formData = new FormData();
      for (let key in newData) {
        if (Array.isArray(newData[key])) {
          newData[key].forEach((obj, index) => {
            let keyList = Object.keys(obj);
            keyList.forEach((keyItem) => {
              let keyName = [key, "[", index, "]", ".", keyItem].join("");
              formData.append(keyName, obj[keyItem]);
            });
          });
        } else if (typeof newData[key] === "object") {
          for (let innerKey in newData[key]) {
            formData.append(`${key}.${innerKey}`, newData[key][innerKey]);
          }
        }
        formData.append(key, newData[key]);
      }
      if (formData.get("ItemList") !== "null") {
        formData.delete("ItemList")
      }
      if (formData.get("ListPOHdr") !== "null") {
        formData.delete("ListPOHdr")
      }
      if(formData.get("ItemTypeList") !== "null"){
        formData.delete("ItemTypeList")
      }
      if(formData.get("TopInstallmentDetail") !== "null"){
        formData.delete("TopInstallmentDetail")
      }
      if(formData.get("SupportingDocuments") !== "null"){
        formData.delete("SupportingDocuments")
      }

      const filterMandatoryFile = attachSupportDoc.filter((d)=> d.mandatory === true)
      const allTrue  = filterMandatoryFile.every(obj => obj.fileSupporting !== null && obj.documentNo !== null)
      
      if (type === "save") {
        if (
          data.TotalAmountItemType !== true &&
          Number(data.TotalAmountServiceMaterial) !== 0
        ) {
          if (data.TopPercentageCondition !== true) {
            if (data.TotalAmountInstallment !== true) {
              dispatch(createInvoice(formData))
                .then((res) => {
                  setLoadingSave(false)
                    dispatch(changeScreenIS("list-is"));
                    Toastify({
                      text: "Save Success",
                      duration: 3000,
                      newWindow: true,
                      close: true,
                      gravity: "top",
                      position: "right",
                      style: {
                        background: "#5EBA7D",
                      },
                    }).showToast();
                })
                .catch((err) => {
                  setLoadingSave(false)
                  console.log(err);
                  Toastify({
                    text: err.data.message !== undefined ? err.data.message : "Connection Timeout",
                    duration: 3000,
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    style: {
                      background: "#CB605A",
                    },
                  }).showToast();
                });
            } else {
              setLoadingSave(false)
              Toastify({
                text: "The Amount of installment Must Be Equal to amount to be paid ",
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                style: {
                  background: "#CB605A",
                },
              }).showToast();
            }
          } else {
            setLoadingSave(false)
            Toastify({
              text: "The Amount of Term of payment can't be more than to Subtotal Amount Before Tax",
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              style: {
                background: "#CB605A",
              },
            }).showToast();
          }
        } else {
          setLoadingSave(false)
          Toastify({
            text: "The Amount of Material or Service Must Be Equal to Subtotal Amount Before Tax ",
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            style: {
              background: "#CB605A",
            },
          }).showToast();
        }
      }else{
        if (
          data.TotalAmountItemType !== true &&
          Number(data.TotalAmountServiceMaterial) !== 0
        ) {
          if (data.TopPercentageCondition !== true) {
            if (data.TotalAmountInstallment !== true) {
              if (data.TopType !== null) {
                if(allTrue){
                  dispatch(createInvoice(formData))
                  .then((res) => {
                      dispatch(
                        jurnalInvoice({
                          paymentreqNo: res.data.paymentReqNo,
                        })
                      )
                        .then((res) => {
                          setLoadingSubmit(false)
                          Toastify({
                            text: "Success",
                            duration: 3000,
                            newWindow: true,
                            close: true,
                            gravity: "top",
                            position: "right",
                            style: {
                              background: "#5EBA7D",
                            },
                          }).showToast();
                          dispatch(changeScreenIS("list-is"));
                        })
                        .catch((err) => {
                          setLoadingSubmit(false)
                          dispatch(changeScreenIS("list-is"));
                          console.log(err);
                        });
                  })
                  .catch((err) => {
                    setLoadingSubmit(false)
                    console.log(err);
                    Toastify({
                      text: err.data.message !== undefined ? err.data.message : "Connection Timeout",
                      duration: 3000,
                      newWindow: true,
                      close: true,
                      gravity: "top",
                      position: "right",
                      style: {
                        background: "#CB605A",
                      },
                    }).showToast();
                  })
                }else{
                  setLoadingSubmit(false)
                  Toastify({
                    text: "Please upload file mandatory",
                    duration: 3000,
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    style: {
                      background: "#CB605A",
                    },
                  }).showToast();
                }
              }else{
                setLoadingSubmit(false)
                Toastify({
                  text: "Please choose `type` in term of payment",
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: "top",
                  position: "right",
                  style: {
                    background: "#CB605A",
                  },
                }).showToast();
              }
              
              
            } else {
              setLoadingSubmit(false)
              Toastify({
                text: "The Amount of installment Must Be Equal to amount to be paid ",
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                style: {
                  background: "#CB605A",
                },
              }).showToast();
            }
          } else {
            setLoadingSubmit(false)
            Toastify({
              text: "The Amount of Term of payment can't be more than to Subtotal Amount Before Tax",
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              style: {
                background: "#CB605A",
              },
            }).showToast();
          }
        } else {
          setLoadingSubmit(false)
          Toastify({
            text: "The Amount of Material or Service Must Be Equal to Subtotal Amount Before Tax ",
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            style: {
              background: "#CB605A",
            },
          }).showToast();
        }
      } 
      
  }

const handleTaxConfig = (idTaxConf, amount) => {
  try{
    const taxConf = dropdownTaxConfig.find((q) => q.value === idTaxConf);
    // VAT
    let vatRate = taxConf.vat;
    return { vatRate }
  }catch{
    return {vat: 0, dpp: 0}
  }
}

  useEffect(() => {
    if (listCompanyCode.length >= 1 && listCompanyCode !== null) {
      let newDataTemp = [{ value: "", label: "---" }];
      let newDataCompany = listCompanyCode
      newDataCompany.forEach((data, i) => {
        newDataTemp.push({
          value: data.companyCode,
          label: data.companyCode,
        });
      });
      setCompanyCode(newDataTemp)
    }// eslint-disable-next-line
  }, [listCompanyCode])

  useEffect(() => {
    if(checkedListPO.length === listPo.length && checkedListPO.length > 0){
      setCheckAllListPO(true);
    }
    else if(indeterminateListPO){
      setCheckAllListPO(true);
    }else{
      setCheckAllListPO(false);

      if(checkedListPO.length === 0){
        if(data.PaymentType === "Advanced Payment"){
          setCurrency(null)
          setData({
            ...data,
            selectedItemList: [],
            TopInstallment: 0,
            TopInstallmentDetail:[],
          });
        }
        
        setSelectedPO([])
        setData({
          ...data,
          selectedItemList: [],
          TotalAmountItemType:true,
          TotalAmountBeforeTax: 0,
          PONo: [],
          ItemList: [],
        });
        setCurrency(null)
        setSelectedRows([])
        setCheckedItemList([]);
        dispatch({
          type: "ITEM_LIST_PO",
          payload: [],
        });
      }
    }

    setSelectedPO(checkedListPO);
      const selectedIDs = new Set(checkedListPO);
      const selectedRowData = listPo.filter((row) =>
        selectedIDs.has(row.id)
      );
      if (selectedRowData.length >= 1) {
        let newPoNo = [];
        let itemListPo=[];
        let amountTemp=[];
        let curr = [];
                
        selectedRowData.forEach((d, i) => {
          itemListPo.push({
            PoNo:d.poNo
          })
          if(data.PaymentType === "Advanced Payment"){
            curr.push(d.currency)
          }
          
          newPoNo.push(d.poNo);
          amountTemp.push(d.netValue)
        });
        setSelectedListPo(itemListPo)

        // checkitemlist sama dengan list po
        let newArray = [];
        let amountTempCredit=[]
        const selectedRowsTemp =selectedRows
        const checkFilterItemList = selectedRowsTemp.filter((elem) => itemListPo.find(({ PoNo }) => elem.poNo === PoNo));
        setSelectedRows(checkFilterItemList)
        if(checkFilterItemList.length >= 1){
          checkFilterItemList.forEach((dataPo) => {
            amountTempCredit.push(Number(dataPo.amountInvoice))
            newArray.push(dataPo["id"]);
          })
          setCheckedItemList(newArray);
        }else{
          setCheckedItemList([]);
        }

        let newAmmountBeforeTax = amountTemp.reduce((a, b) => a + b, 0).toFixed(2)
      
        let newAmmountBeforeTaxCredit = amountTempCredit.reduce((a, b) => a + b, 0).toFixed(2)
        if(data.PaymentType === "Credit Payment"){
          setData({
            ...data,
            PONo: newPoNo,
            TotalAmountBeforeTax:newAmmountBeforeTaxCredit
          })
        }else if(data.PaymentType === "Advanced Payment"){
          const uniqueCurr = [...new Set(curr)];
          setCurrency(uniqueCurr)
          setData({
            ...data,
            PONo: newPoNo,
            TotalAmountBeforeTax:newAmmountBeforeTax
          })
        }
      }else{
        setData({
          ...data,
          TotalAmountItemType:true,
          TotalAmountBeforeTax: 0,
          PONo: [],
          ItemList: [],
          selectedItemList: [],
        });
        setSelectedRows([])
        setCurrency(null);
        setSelectionModel([]);
        setSelectedRows([])
        setCheckedItemList([]);
        dispatch({
          type: "ITEM_LIST_PO",
          payload: [],
        });
      }
    //eslint-disable-next-line
  }, [checkAllListPO, checkedListPO])

  useEffect(() => {
    if (data.PaymentType.toLowerCase() === "credit payment") {
      if(checkedItemList.length === data.ItemList.length && checkedItemList.length > 0){
        setCheckAllItemList(true);
      }else{
        setCheckAllItemList(false);

        if(checkedItemList.length === 0){
          let dataNew =data.ItemList;
          const newDataCheckBox = dataNew.map((obj, i) => ({ ...obj, isCheckBox:false }));
          setData({
            ...data,
            ItemList:newDataCheckBox,
            TotalAmountBeforeTax: 0,
            selectedItemList: [],
          });
          setSelectedRows([])
          setCurrency(null);
        }
      }

      const selectedIDs = new Set(checkedItemList);
      const selectedRowData = data.ItemList.filter((row) =>
        selectedIDs.has(row.id)
      );
                        
      if (selectedRowData.length >= 1 && selectedRowData !== undefined && selectedRowData !== null ) {
        setSelectedRows(selectedRowData)
        let dataNew =data.ItemList;
        let amountTemp = [];
        let newData = [];
        let curr = [];
        
        selectedRowData.forEach((d, i) => {
          curr.push(d.currency)
          const selected = dataNew.findIndex((row)=>row.conditionId === d.conditionId)
          dataNew[selected].isCheckBox =true
          amountTemp.push(Number(d.amountInvoice));
          newData.push({
            id: d.id,
            amountInvoice: Number(d.amountInvoice),
            item: d.item,
            billedQty: parseInt(d.balanceQty),
            conditionId: d.conditionId,
            poNo: d.poNo,
          });
        });
        
        const unique = [...new Set(curr)];
        setCurrency(unique)
        
        const check = dataNew.filter((checkBox)=>{
          return checkBox.isCheckBox === true
        })
        // let props = ['conditionId'];
        let unchecked = check.filter(function(o1){
          // filter out (!) items in result2
          return !selectedRowData.some(function(o2){
            return o1.id === o2.id;          // assumes unique id
          });
        })
        
        if (unchecked.length >= 1) {
          unchecked.forEach((d)=>{
            const selected = dataNew.findIndex((row)=>row.conditionId === d.conditionId)
            dataNew[selected].isCheckBox =false
          })
        }
        
        let newAmmountBeforeTax = amountTemp.reduce(
          (a, b) => a + b,
          0
        )
        

        let ammountTemp=[]
        if(data.selectedItemType.length >=1){
          data.selectedItemType.forEach((d)=>{
            ammountTemp.push(d.itemTypeAmount)
          })
        }

        let amountMaterial = ammountTemp.slice(0, 2).reduce((a, b) => a + b, 0).toFixed(2)
        if(data.PaymentType === "Credit Payment"){
          let amtBeforeTax = Number(newAmmountBeforeTax).toFixed(2);
          if (Number(amountMaterial).toFixed(2) !== amtBeforeTax ) {
            setData({
              ...data,
              TotalAmountItemType: true,
              ItemList:dataNew,
              TotalAmountBeforeTax: newAmmountBeforeTax,
              selectedItemList: newData,
            })
          }else{
            setData({
              ...data,
              TotalAmountItemType: false,
              ItemList:dataNew,
              TotalAmountBeforeTax: newAmmountBeforeTax,
              selectedItemList: newData,
            })
          }
        }else if(data.PaymentType === "Advanced Payment"){
          if (Number(amountMaterial).toFixed(2) !== Number(data.TopAmount).toFixed(2) ) {
            setData({
              ...data,
              TotalAmountItemType: true,
            })
          }else{
            setData({
              ...data,
              TotalAmountItemType:false,
            })
          }
        }

      }
    }
  // eslint-disable-next-line
  }, [checkedItemList])

  useEffect(() => {
    if(checkedItemType.length === dataItemType.length && checkedItemType.length > 0){
      setCheckAllItemType(true);
      setIndeterminateItemType(false);
    }else{
      setCheckAllItemType(false);

      if(checkedItemType.length === 0){
        setData({
          ...data,
          selectedItemType: [],
          TotalAmountToBePaid: 0,
        });
      }else if(checkedItemType.length !== dataItemType.length && checkedItemType.length !== 0){
        //set indeterminate false
        setIndeterminateItemType(false);
        setData({
          ...data,
          selectedItemType: [],
          TotalAmountToBePaid: 0,
        });
      }
    }

    const selectedIDs = new Set(checkedItemType);
    const selectedRowData = dataItemType.filter((row) =>
      selectedIDs.has(row.id)
    );

    if (selectedRowData.length >= 1) {
      let amountTemp = [];
      let dataTemp = [];
      
      selectedRowData.forEach((d, i) => {
        amountTemp.push(d.itemTypeWhtAmountToBePaid === null ? 0 : Number(d.itemTypeWhtAmountToBePaid));
        dataTemp.push({
          itemTypeNo: d.seq,
          itemTypeDesc: d.description,
          itemTypeAmount: d.itemTypeAmount === null ? 0 : Number(d.itemTypeAmount),
          itemTypeTaxConfId: d.itemTypeTaxConfId === undefined ? "" : d.itemTypeTaxConfId,
          itemTypeVat: d.itemTypeVat === null ? 0 : d.itemTypeVat,
          itemTypeVatRate: d.itemTypeVatRate === null ? 0 : d.itemTypeVatRate,
          itemTypeVatAmount: d.itemTypeVatAmount === null ? 0 : Number(d.itemTypeVatAmount),
          itemTypeDppRate: d.itemTypeDppRate === undefined ? 0 : d.itemTypeDppRate,
          itemTypeDppAmount: Number(d.itemTypeDppAmount),
          itemTypeWht: d.itemTypeWht,
          itemTypeWhtType: d.itemTypeWhtType,
          itemTypeWhtSubtype: d.itemTypeWhtSubtype,
          itemTypeWhtRate: d.itemTypeWhtRate === null ? 0 : Number(d.itemTypeWhtRate),
          itemTypeWhtAmount: d.itemTypeWhtAmount === null ? 0 : Number(d.itemTypeWhtAmount),
          itemTypeWhtAmountToBePaid: d.itemTypeWhtAmountToBePaid === null ? 0 : Number(d.itemTypeWhtAmountToBePaid),
        });
      });

      let newAmmountax = amountTemp.reduce(
        (a, b) => a + b,
        0
      )

      // before tax === total amount service & material
      let ammountTemp = [];
      const filterMaterialService = selectedRowData.filter((d)=>d.description === "Material" || d.description === "Services")
     
      filterMaterialService.forEach((data,index)=>{
        ammountTemp.push(data.itemTypeAmount === null ? 0 : data.itemTypeAmount)
      })
        
      let amountMaterial = ammountTemp.slice(0, 2).reduce((a, b) => a + b, 0).toFixed(2)

      if(data.PaymentType === "Credit Payment"){
        let amtBeforeTax = Number(data.TotalAmountBeforeTax).toFixed(2);
        if (Number(amountMaterial).toFixed(2) !== amtBeforeTax ) {
          setData({
            ...data,
            TotalAmountServiceMaterial:amountMaterial,
            TotalAmountItemType: true,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp
          })
        }else{
          setData({
            ...data,
            TotalAmountServiceMaterial: amountMaterial,
            TotalAmountItemType: false,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp
          })
        }
      }else if(data.PaymentType === "Advanced Payment"){
        if (Number(amountMaterial).toFixed(2) !== Number(data.TopAmount).toFixed(2) ) {
          setData({
            ...data,
            TotalAmountServiceMaterial: amountMaterial,
            TotalAmountItemType: true,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp,
          })
        }else{
          setData({
            ...data,
            TotalAmountServiceMaterial: amountMaterial,
            TotalAmountItemType:false,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp,
          })
        }
      }
    }
    
    // eslint-disable-next-line
  }, [checkedItemType])


  // dropdown wht & sub type wht
  useEffect(() => {
    if (data.TaxType.toLowerCase() === "taxable" && data.companyCode !== null &&data.companyCode !== "") {
      setLoadingItemType(true);
      dispatch(getItemWhtType(`?CompanyCode=${data.companyCode}`)).then((res)=>{
        let getData = res.data;
        let newDataWhtType = [{ label: "---", value: "---" }];
        if (getData.length >= 1) {
          const unique = [...new Set(getData.map(item => item.taxType))];
          unique.forEach((data, i) => {
            newDataWhtType.push({
              value: data,
              label: data,
            });
           
          });
          setWhtType(newDataWhtType);
        }
      })
      .then(() => {
        setLoadingItemType(false);
      })
      .catch(() => {
        setLoadingItemType(false);
      })
      
    }// eslint-disable-next-line
  }, [data.companyCode,data.TaxType,dataItemType])//,selectTemp,data.attachment,attachSupportDoc
  
  useEffect(() => {
    dispatch(getTaxConfiguration()).then((res)=> {
      let getData = res.data;
      let newDataTaxType = [{ value: "", label: "---", vat: 0, dpp: 0, dppNumber: "", isPercentage: false }];
        if (getData.length >= 1) {
          const unique = [...new Set(getData)];
          unique.forEach((data, i) => {
            newDataTaxType.push({
              value: data.id,
              label: "VAT " + data.vatRate + "% (" + data.id + ")",
              vat: data.vatRate,
              dpp: data.dppRate,
              dppNumber: data.dppRateNumber,
              isPercentage: data.isPercentage
            });
          });
          setTaxConfig(newDataTaxType);
        }
    }).then(() => {
      setLoadingItemType(false);
    })
    .catch(() => {
      setLoadingItemType(false);
    })
  }, [])

  useEffect(() => {
    if(data.PaymentType === "Credit Payment"){
      const fetchData = async () => {
        const allPo = data.PONo;
        const dataTempItemList = data.ItemList;
        const existingPOs = dataTempItemList.map(item => item.poNo);
        
        // Determine new POs to fetch
        const newPOs = allPo.filter(po => !existingPOs.includes(po));
        // Determine POs to remove
        const removePOs = existingPOs.filter(po => !allPo.includes(po));
    
        // Remove old POs
        const updatedItemList = dataTempItemList.filter(item => !removePOs.includes(item.poNo));
    
        if (newPOs.length > 0) {
          setLoadingItemList(true);
    
          try {
            const promises = newPOs.map(async (po) => {
              const res = await dispatch(getItemListPO({
                PONo: po,
                VendorCode: listInfoVendor[0].vendorCode,
              }));
              const dataTemp = res.data;
    
              dataTemp.forEach((item) => {
                item.billedQty = item.balanceQty;
                item.amountInvoice = item.currency === "IDR"
                  ? Math.ceil(Number(item.balanceQty * item.pricePerUnit))
                  : Number(item.balanceQty * item.pricePerUnit).toFixed(2);
              });
    
              return dataTemp;
            });
    
            const results = await Promise.all(promises);
            const newData = results.flat();
            // Combine old and new data
            const combinedItemList = [...updatedItemList, ...newData];
            combinedItemList.sort((a, b) => Number(a.poNo) - Number(b.poNo));
            setData((prevData) => ({
              ...prevData,
              ItemList: combinedItemList,
              ItemListtemp: combinedItemList,
              selectedItemList: combinedItemList.filter(item => item.isCheckBox),
            }));
            setSelectedRows(combinedItemList.filter(item => item.isCheckBox));
          } catch (error) {
            console.error("Error processing POs:", error);
          } finally {
            setLoadingItemList(false);
          }
        } else {
          setData((prevData) => ({
            ...prevData,
            ItemList: updatedItemList,
            ItemListtemp: updatedItemList,
            selectedItemList: updatedItemList.filter(item => item.isCheckBox),
          }));
          setSelectedRows(updatedItemList.filter(item => item.isCheckBox));
        }
      };
    
      fetchData();
    }
    // eslint-disable-next-line
  }, [selectPO, data.PaymentType, data.TopAmount]);
  
useEffect(() => {
    setSelectionModel(
      data.ItemList.filter((r, i) => r.isCheckBox === true).map((r) => r.id)
    );
  }, [data.ItemList,selectedRows,selectPO]);
  
  useEffect(() => {
    let newDataBank = [{ value: "", label: "Choose Here" }];
    let bankData = listBankAccount
    bankData.forEach((data, i) => {
      newDataBank.push({
        value: data.bankCode,
        label: data.bankCode,
      });
    });

    window.scrollTo(0, 0)
    setBankCode(newDataBank)
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    let newDataBankNo = [{ value: "", label: "Choose Here" }];
    
    if (data.BankName !== ''|| data.BankName !== null) {
      let bankData = listBankAccount.filter((d)=>{
        return d.bankCode === data.BankName
     });
      bankData.forEach((data, i) => {
        newDataBankNo.push({
          value: data.bankAccount,
          label: data.bankAccount,
        });
      });
    }

    setBankNo(newDataBankNo)
    // eslint-disable-next-line
  }, [data.BankName])

  return (
    <Page title="Invoice Submission">
      <Navbar />
      <div className="container-vendor">
        <div className="header-vendor-invoice">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h6>Create Invoice</h6>
              <p>{`${moment(today).format("dddd, DD MMMM YYYY")}`}</p>
            </div>
            <div>
              <span>Total Amount to be paid</span>
              {currency !== null && currency[0] === "IDR" ? <h2>{`${currency[0]} ${ConvertFormat(data.TotalAmountToBePaid)}`}</h2>:currency !== null ?
              <h2>{`${currency[0]} ${Number(data.TotalAmountToBePaid).toFixed(2)}`}</h2>:<h2>{Number(data.TotalAmountToBePaid).toFixed(2)}</h2>
              }
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginLeft: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              btn="btn-vendor-outlined"
              title="Back"
              onClick={handleBack}
            />
            {loadingSave === true ?
            
            <div className="btn-for-vendor-outlined">
              <Button
                btn="btn-download-loader"
              />
            </div>
            :
            <Button
              btn="btn-vendor-outlined"
              onClick={()=>handleSave("save")}
              title="Save"
              disabled={loadingSubmit === true?true:false}
            />
          }
          {loadingSubmit === true ? 
            <div className="btn-for-vendor-loading-submit">
            <Button
              btn="btn-download-loader"
            />
          </div>:
            <Button btn="btn-vendor-fill" title="Submit" onClick={()=>handleSave("submit")} disabled={loadingSave === true?true:false} />

        }
          </div>
        </div>
        <div className="content-list-po" style={{ marginTop: "220px" }}>
          <div className="square">
            <h3>Payment Request</h3>
            <Grid container spacing={2}>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="PaymentRequestNumber">
                  Payment Request Number
                </label>
                <input
                  disabled={true}
                  id="PaymentRequestNumber"
                  name="PaymentRequestNumber"
                  type="text"
                  value={"Will be generated automatically"}
                />
                <label htmlFor="PaymentType">Payment Type</label>
                <select
                  className="select-create-invoice"
                  id="PaymentType"
                  name="PaymentType"
                  onChange={handleChangeHeader}
                  defaultValue={'DEFAULT'}
                >
                  <option value="DEFAULT" disabled>
                    ---
                  </option>
                  <option value="Credit Payment">Credit Payment</option>
                  <option value="Advanced Payment">Advanced Payment</option>
                </select>
                
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="invoiceDate">Invoice Date</label>
                <input
                  disabled={true}
                  id="invoiceDate"
                  name="invoiceDate"
                  type="text"
                  defaultValue={moment(today).format("DD MMM YYYY")}
                />

                <label htmlFor="TaxType">Tax Type</label>
                <select
                  className="select-create-invoice"
                  id="TaxType"
                  name="TaxType"
                  onChange={handleChangeHeader}
                  defaultValue={'DEFAULT'}
                >
                  <option value="DEFAULT" disabled>
                    ---
                  </option>
                  <option value="Taxable">Taxable</option>
                  <option value="nonTaxable">Non Taxable</option>
                </select>
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="VendorInvoiceNumber">
                  Vendor Invoice Number
                </label>
                <input
                  // disabled={loading === false ? true:false}
                  style={{ background: "#FFFFFF" }}
                  id="VendorInvoiceNumber"
                  name="VendorInvoiceNumber"
                  type="text"
                  onChange={handleFormChange}
                  value={data.VendorInvoiceNumber}
                />
                <label htmlFor="companyCode">Company Code</label>
                <select
                  className="select-create-invoice"
                  id="companyCode"
                  name="companyCode"
                  onChange={handleChangeHeader}
                  value={data.companyCode === null ? "" : data.companyCode}
                >
                  {dropdownCompanyCode.map((status, key) => (
                    <option key={"companyCode-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="poType">PO Type</label>
                <select
                  className="select-create-invoice"
                  id="poType"
                  name="poType"
                  onChange={handleChangeHeader}
                  defaultValue={'DEFAULT'}
                >
                  <option value="DEFAULT" disabled>
                    ---
                  </option>
                  <option value="1">Trade</option>
                  <option value="0">Non Trade</option>
                </select>
                
                <label htmlFor="paymentTerm">Payment Term</label>
                <select
                  className="select-create-invoice"
                  id="paymentTerm"
                  name="paymentTerm"
                  onChange={handleChangeHeader}
                  defaultValue={'DEFAULT'}
                >
                  <option value="DEFAULT" disabled>
                    ---
                  </option>
                  <option value="CASH">CASH - Payable immediately Due net</option>
                  <option value="0004">0004 - Payable immediately Due net</option>
                  <option value="0001">0001 - Payable immediately Due net</option>
                  <option value="I120">I120 - Within 120 days Due net</option>
                  <option value="I160">I160 - Payment in 160 days</option>
                  <option value="I180">I180 - Payment in 180 days</option>
                  <option value="I365">I365 - Payment in 365 days</option>
                  <option value="IN07">IN07 - Payment in 7 days</option>
                  <option value="IN14">IN14 - Payment in 14 days</option>
                  <option value="IN30">IN30 - Payment in 30 days</option>
                  <option value="IN45">IN45 - Payment in 45 days</option>
                  <option value="IN60">IN60 - Payment in 60 days</option>
                  <option value="IN90">IN90 - Payment in 90 days</option>
                  <option value="IN75">IN75 - Payment in 75 days</option>
                  <option value="COD">COD - Cash On Delivery</option>
                  <option value="CBD">CBD - Cash Before Delivery</option>
                  <option value="">---</option>
                </select>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* list PO */}
        <div className="content-list-po">
          <div className="square-table">
            <h3 style={{ marginLeft: "30px" }}>List Purchase Order</h3>
            <Box
              sx={{
                height:700,
                width: 1,
                "& .super-app-theme--header": {
                  backgroundColor: "#001D61",
                  color: "#FFFFFF",
                  fontFamily: "Quicksand",
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
            >
              {
                loadingListPO ?
                  (
                    <Skeleton
                      height={700}
                      variant="rectangular"
                    />
                  ):(
                    <CustomDataGrid
                      // checkboxSelection
                      columns={theColumns}
                      disableSelectionOnClick
                      pageSize={pageSizePO}
                      onPageSizeChange={(newPageSize) =>setPageSizePO(newPageSize)}
                      pagination
                      PreviewMouseDoubleClick
                      rows={listPo}
                      rowsPerPageOptions={[5, 10,25]}
                    />
                  )
              }
            </Box>
          </div>
        </div>
        
          <div>
            {data.PaymentType === "Credit Payment" && data.PONo.length >= 1 && (
              <div className="content-list-po">
                <div className="square-table">
                <div
                    style={{
                      display:"flex",
                      justifyContent: "space-between",
                      alignItems:"center"
                    }}
                  >
                    <h3 style={{ marginLeft: "30px" }}>Item List</h3>

                    <div
                     style={{
                      display:"flex",
                      marginRight:"20px"
                    }}
                    >
                      <Button btn="btn-vendor-export" title="EXPORT" onClick={()=>exportToExcel()}>Export</Button>
                      <Button btn="btn-vendor-upload" title="UPLOAD" onClick={()=>setUploadItemList(true)}/>
                    </div>
                  </div>
                  <Box
                    sx={{
                      height: 700,
                      width: 1,
                      "& .super-app-theme--header": {
                        backgroundColor: "#001D61",
  			color: "#FFFFFF",
                        fontFamily: "Quicksand",
                        fontSize: "12px",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {
                      loadingItemList ? (
                        <Skeleton
                          height={700}
                          variant="rectangular"
                        />
                      ):(
                        <CustomDataGrid
                          // checkboxSelection
                          columns={columnItemList}
                          disableSelectionOnClick
                          pageSize={pageSizeItemList}
                          
                          onPageSizeChange={(newPageSize) => {
                            setPageSizeItemList(newPageSize)
                          }}
                          // components={{ Toolbar: customToolbar }}
                          pagination
                          PreviewMouseDoubleClick
                          rows={data.ItemList}
                          rowsPerPageOptions={[5, 10,25]}
                          selectionModel={selectionModel}
                        />
                      )
                    }
                  </Box>
                </div>
              </div>
            )}
            <div className="content-list-po">
              <div className="square">
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p>Subtotal Amount before Tax</p>
                  {currency !== null && currency[0] === "IDR" ? <h5>{`${currency[0]} ${ConvertFormat(data.TotalAmountBeforeTax)}`}</h5>: currency !== null ?
                  <h5>{`${currency[0]} ${Number(data.TotalAmountBeforeTax).toFixed(2)}`}</h5>
                  :
                  <h5>{Number(data.TotalAmountBeforeTax).toFixed(2)}</h5>
                  }
                </div>
              </div>
            </div>

            {/* term of payment AdvancedPayment*/}
            {data.PaymentType === "Advanced Payment" && (
              <div className="content-list-po">
                <div className="square">
                  <h3>Term of Payment</h3>
                  <Grid container spacing={2}>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopType">Type</label>
                      <select
                        className="select-create-invoice"
                        id="TopType"
                        name="TopType"
                        onChange={handleFormChange}
                        style={{ marginBottom: "30px" }}
                        defaultValue={'DEFAULT'}
                      >
                        <option value="DEFAULT" disabled selected>
                          ---
                        </option>
                        <option value="DownPayment">Down Payment</option>
                        <option value="COD">COD (Cash on Delivery)</option>
                        <option value="CBD">CBD (Cash before Delivery)</option>
                      </select>
                      <label htmlFor="TopPercentage">Percentage</label>
                      <input
                        id="TopPercentage"
                        name="TopPercentage"
                        type="number"
                        onChange={handleFormChange}
                        value={data.TopPercentage}
                        style={{ backgroundColor: "#FFFFFF" }}
                      />
                    </Grid>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopDueDate">Due Date</label>
                      <input
                        id="TopDueDate"
                        name="TopDueDate"
                        type="date"
                        onChange={handleFormChange}
                        value={data.TopDueDate}
                        defaultValue={data.TopDueDate}
                        style={{backgroundColor: "#FFFFFF",marginBottom: "30px"}}
                        min={new Date().toISOString().split('T')[0]} // menonaktifkan tanggal sebelum hari ini
                            //disabled // menonaktifkan input tanggal
                        onKeyDown={(e) => e.preventDefault()} // Mencegah input melalui keyboard
                          
                      />
                      <label htmlFor="TopAmount">Amount</label>
                      <input disabled={true} Value={data.TopAmount} id="TopAmount" name="TopAmount" style={{
              border:
                data.TopPercentageCondition === true ? "1px solid #CB605A" : "",
            }} type="text" />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}

            {/* item type */}
            <div className="content-list-po">
              <div className="square-table">
                <h3 style={{ marginLeft: "30px" }}>Item Type</h3>
                <Box
                  sx={{
                    height: 400,
                    width: 1,
                    "& .super-app-theme--header": {
                      backgroundColor: "#001D61",
                      color: "#FFFFFF",
                      fontFamily: "Quicksand",
                      fontSize: "12px",
                      fontWeight: "bold",
                    },
                  }}
                >
                  {
                    loadingItemType ? (
                      <Skeleton
                        height={400}
                        variant="rectangular"
                      />
                    ):(
                      <CustomDataGrid
                        columns={columnItemType}
                        disableSelectionOnClick
                        isRowSelectable={(params) =>
                          Number(params.row.itemTypeAmount)  !== 0 && params.row.itemTypeAmount !== null
                        }
                        rows={dataItemType}
                        pageSize={5}
                      />
                    )
                  }
                </Box>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "30px",
                  }}
                >
                  <h2>Total Amount to be paid</h2>
                  {currency !== null && currency[0] === "IDR" ? <h5>{`${currency[0]} ${ConvertFormat(data.TotalAmountToBePaid)}`}</h5>:currency !== null ?
                  <h5>{`${currency[0]} ${data.TotalAmountToBePaid.toFixed(2)}`}</h5>: <h5>{data.TotalAmountToBePaid.toFixed(2)}</h5>
                  }
                </div>
              </div>
            </div>

            {/* bank info */}
            <div className="content-list-po">
              <div className="square">
                <h3>Bank Account Information</h3>
                <Grid container spacing={2}>
                  <Grid className="detail-info" item xs={3} md={3}>
                    <label htmlFor="BankName">Bank</label>
                    <select
                      className="select-create-invoice"
                      id="BankName"
                      name="BankName"
                      onChange={handleFormChange}
                      style={{ marginBottom: "30px" }}
                      value={data.BankName === null ? "" : data.BankName}
                    >
                      {dropdownBank.map((status, key) => (
                    <option key={"dropdownBank-" + key} value={status.value} hidden={status.value === "" ?true:false}>
                      {status.label}
                    </option>
                  ))}
                    </select>
                    <label htmlFor="BankAccountNo">Account No</label>
                    <select
                      className="select-create-invoice"
                      id="BankAccountNo"
                      name="BankAccountNo"
                      onChange={handleFormChange}
                      style={{ marginBottom: "30px" }}
                      value={data.BankAccountNo === null ? "" : data.BankAccountNo}
                    >
                      {dropdownBankNo.map((status, key) => (
                    <option key={"dropdownBankNo-" + key} value={status.value} hidden={status.value === "" ?true:false}>
                      {status.label}
                    </option>
                  ))}
                    </select>
                    <label htmlFor="BankAccountName">Account Name</label>
                    <input
                      disabled={true}
                      id="BankAccountName"
                      name="BankAccountName"
                      type="text"
                      defaultValue={data.BankAccountName}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>

            {/* Installment*/}
            {data.PaymentType === "Advanced Payment" && (
              <div className="content-list-po">
                <div className="square-table">
                  <h3 style={{ marginLeft: "30px" }}>Installment</h3>
                  <Grid container spacing={2}>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopType" style={{ marginLeft: "15px" }}>
                      </label>
                      <div
                        className="select-create-invoice"
                        id="TopType"
                        name="TopType"
                        onChange={handleFormChange}
                        style={{ marginBottom: "30px", marginLeft: "30px" }}
                        >
                          <option value="Installment">
                          Installment
                          </option>
                      </div>

                      {data.PaymentType === "Advanced Payment" ? (
                        <>
                          <label
                            htmlFor="TopInstallment"
                            style={{ marginLeft: "30px" }}
                          >
                          </label>
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              marginLeft: "30px",
                              width: "100%",
                            }}
                          >
                            <Button
                              btn="btn-decrease"
                              onClick={() =>
                                handleDecreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                            <input
                              id="TopInstallment"
                              name="TopInstallment"
                              type="number"
                              min="0"
                              step="1"
                              onPaste={preventPasteNegative}
                              onKeyPress={preventMinus}
                              onChange={handleFormChange}
                              value={data.TopInstallment}
                              style={{
                                backgroundColor: "#FFFFFF",
                                textAlign: "center",
                              }}
                            />
                            <Button
                              btn="btn-increase"
                              onClick={() =>
                                handleIncreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                          </div>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                  {data.TopInstallment > 0 && (
                    <Box
                      sx={{
                        height: 300,
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#001D61",
                          color: "#FFFFFF",
                          fontFamily: "Quicksand",
                          fontSize: "12px",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <CustomDataGrid
                        pageSize={10}
                        PreviewMouseDoubleClick
                        columns={columnInstallment}
                        disableSelectionOnClick
                        rows={TopInstallmentDetail}
                      />
                    </Box>
                  )}
                </div>
              </div>
            )}

            {/* term off payment */}
            {data.PaymentType === "Credit Payment" && (
              <div className="content-list-po">
                <div className="square-table">
                  <h3 style={{ marginLeft: "30px" }}>Term of Payment</h3>
                  <Grid container spacing={2}>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopType" style={{ marginLeft: "30px" }}>
                        Type
                      </label>
                      <select
                        className="select-create-invoice"
                        id="TopType"
                        name="TopType"
                        onChange={handleFormChange}
                        style={{ marginBottom: "30px", marginLeft: "30px" }}
                      >
                        <option disabled selected>
                          ---
                        </option>
                        <option value="Installment">Installment</option>
                        <option value="Credit">Credit</option>
                      </select>

                      {data.PaymentType === "Credit Payment" &&
                      data.TopType === "Installment" ? (
                        <>
                          <label
                            htmlFor="TopInstallment"
                            style={{ marginLeft: "30px" }}
                          >
                            Installment
                          </label>
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              marginLeft: "30px",
                              width: "100%",
                            }}
                          >
                            <Button
                              btn="btn-decrease"
                              onClick={() =>
                                handleDecreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                            <input
                              id="TopInstallment"
                              name="TopInstallment"
                              type="number"
                              min="0"
                              step="1"
                              onPaste={preventPasteNegative}
                              onKeyPress={preventMinus}
                              onChange={handleFormChange}
                              value={data.TopInstallment}
                              style={{
                                backgroundColor: "#FFFFFF",
                                textAlign: "center",
                              }}
                            />
                            <Button
                              btn="btn-increase"
                              onClick={() =>
                                handleIncreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                          </div>
                        </>
                      ) : data.PaymentType === "Credit Payment" &&
                        data.TopType === "Credit" ? (
                        <>
                          <label
                            htmlFor="TopDueDate"
                            style={{ marginLeft: "30px" }}
                          >
                            Due Date
                          </label>
                          <input
                            id="TopDueDate"
                            name="TopDueDate"
                            defaultValue={data.TopDueDate}
                            type="date"
                            onChange={handleFormChange}
                            style={{ backgroundColor: "#FFFFFF", marginLeft: "30px" }}
                            min={new Date().toISOString().split('T')[0]} // menonaktifkan tanggal sebelum hari ini
                            //disabled // menonaktifkan input tanggal
                            onKeyDown={(e) => e.preventDefault()} // Mencegah input melalui keyboard
                          />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                  {data.TopInstallment > 0 && (
                    <Box
                      sx={{
                        height: 300,
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#001D61",
                          color: "#FFFFFF",
                          fontFamily: "Quicksand",
                          fontSize: "12px",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <CustomDataGrid
                        pageSize={10}
                        PreviewMouseDoubleClick
                        columns={columnInstallment}
                        disableSelectionOnClick
                        rows={TopInstallmentDetail}
                      />
                    </Box>
                  )}
                </div>
              </div>
            )}

            {/* Supporting Document */}
            <div className="content-list-po">
              <div className="square-table">
                <h3>Supporting Document</h3>
                <Box
                  sx={{
                    height: 900,
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#001D61",
                      color: "#FFFFFF",
                      fontFamily: "Quicksand",
                      fontSize: "12px",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <CustomDataGrid
                    // pageSize={15}
                    columns={columnSupportDoc}
                    disableSelectionOnClick
                    rows={attachSupportDoc}
                    PreviewMouseDoubleClick
                  />
                </Box>
              </div>
            </div>
          </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        // fullScreen={fullScreen}
        open={uploadItemList}
        onClose={()=>setUploadItemList(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Upload"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="dialog-poTrade">
            <Grid container spacing={2}>
              <Grid className="label-dialog-poTrade" item xs={4} md={4}>
                <label htmlFor="uploadFile">Upload File</label>
              </Grid>
              <Grid className="label-dialog-poTrade" item xs={8} md={8}>
                <input className="file-upload" type="file" id="uploadFile" onChange={(e)=>setFileUploadItemList(e.target.files[0])} />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setUploadItemList(false)}
            btn="btn-vendor-outlined"
            title="Cancel"
          />
          {loadingUpload === true?
        <div className="btn-for-vendor-loading-submit">
        <Button
          btn="btn-download-loader"
        />
      </div>:
            <Button onClick={handleUploadItemList} btn="btn-vendor-fill" title="Upload" />

        }
        </DialogActions>
      </Dialog>

      {/* dialog error message */}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        // fullScreen={fullScreen}
        open={errorDialog}
        onClose={()=>setErrorDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Error Detail</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="dialog-poTrade">
            <Grid item xs={12}>
              <Box
                sx={{
                  height:
                    pageErr === 5
                      ? 400
                      : pageErr === 10
                      ? 700
                      : pageErr === 25
                      ? 1800
                      : 2000,
                  width: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "#CB605A",
                    color: "#FFFFFF",
                    fontFamily: "Quicksand",
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                }}
              >
                <CustomDataGrid
                  columns={theColumnsError}
                  disableSelectionOnClick
                  pageSize={pageErr}
                  PreviewMouseDoubleClick
                  onPageSizeChange={(newPageSize) => setPageErr(newPageSize)}
                  rows={listErrorMsg}
                  rowsPerPageOptions={[5, 10]}
                />
              </Box>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setErrorDialog(false)}
            btn="btn-vendor-outlined"
            color="red"
            title="Cancel"
          />
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default InvoiceSubmission;
