import React, { useState,useEffect } from "react";
import Navbar from "../../../../components/navBarVendor";
import Page from "../../../../components/Page";
import POPrintout from "../../../../components/pdf/poPrintout/poPrintOut";
import CustomDataGrid from "../../../../components/table";

import "../vendor.css";
import { PDFViewer} from "@react-pdf/renderer";

import { Backdrop, Dialog,DialogContent, Box,Stack, Menu, MenuItem, ListItemText, ListItemIcon, Fade, } from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import { changeScreenPO,getDetailPO,getListPO,getPrintOut } from "../../../../configs/redux/action/Vendor/purchaseOrder";
import {getStatus} from "../../../../configs/redux/action/apiMaster"
// import FileNotFound from "../../../../assets/img/fileNotFound.png"
import Folder from "../../../../assets/img/folder.png"
import CircularProgress from '@mui/material/CircularProgress';

// Icons
// import InfoIcon from "@mui/icons-material/Info";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useHistory } from "react-router-dom";

import {listPurchaseOrderColumn} from "../../../../contants/purchaseOrder"

function NoRowsOverlay() {
  return (
    <Stack height="100%"  alignItems="center" justifyContent="center">
      <img src={Folder} width="128" heigh="64" alt="Data Not Found"/>
    </Stack>
  );
}

function PurchaseOrder() {
  const history = useHistory()
  const dispatch = useDispatch();
  const { listPO} = useSelector((state) => state.vendorPo);
  const { listStatus } = useSelector((state) => state.status);
  const { listInfoVendor} = useSelector((state) => state.infoVendor);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openOptions, setOpenOptions] = useState(false);

  const [pageSize, setPageSize] = useState(10)
  const [dataPdf,setDataPdf]= useState([])
  const [openPdf, setPDF] = React.useState(false);
  const [loadingChangePage, setLoadingChangePage] = useState(false);
  const [selectedPo, setSelectedPo] = useState(null)

  const handleDetail = (purchaseNo,id) => {
    setLoadingChangePage(true);
    dispatch(getDetailPO(`?VendorCode=${listInfoVendor[0].vendorCode}&PurchaseNo=${purchaseNo}`))
    .then(res=>
      {
        setLoadingChangePage(false);
        dispatch(changeScreenPO("detail-po"))
        history.push(`/vdr/purchase-order?Id=${id}`)
      }
     )
  };
  const handleClosePdf=()=>{
    setPDF(false)
  }

  const renderOption = (
    <Menu
      anchorEl={anchorEl}
      id="option"
      open={openOptions}
      onClose={() => {
        setAnchorEl(null);
        setOpenOptions(false);
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      TransitionComponent={Fade}
    >
      <MenuItem
        onClick={() => {
          setLoadingChangePage(true);
          setAnchorEl(null);
          setOpenOptions(false);
          dispatch(getPrintOut(`?VendorCode=${listInfoVendor[0].vendorCode}&PurchaseNo=${selectedPo}`))
          .then((res)=>{
            setLoadingChangePage(false);
            const dataTemp = res.data
            dataTemp[0].listPOItem.sort((a, b) => Number(a.item) - Number(b.item));
            setDataPdf(dataTemp)
            setPDF(true)
          })
          .catch((err)=>{
            setOpenOptions(false);
            setLoadingChangePage(false);
          })
        }}
      >
        <ListItemIcon>
          <PictureAsPdfIcon />
        </ListItemIcon>
        <ListItemText>Download PO</ListItemText>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    dispatch(getStatus())
    dispatch(getListPO(`?VendorCode=${listInfoVendor[0].vendorCode}`))
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [])
  
  return (
    <Page title="Purchase Order">
      <Navbar />
      {renderOption}
      <div className="container-vendor">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingChangePage}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="header-vendor">
          <h6>Purchase Order</h6>
          <p>List purchase order that have been submitted to Intraco</p>
        </div>
        <div className="content-list-ipms">
          {/* <div style={{ height: 340 }}> */}
          <Box
            sx={{
              height:640,
              "& .super-app-theme--header": {
                backgroundColor: "#001D61",
                color: "#FFFFFF",
                fontFamily: "Quicksand",
                fontSize: "12px",
                fontWeight: "bold",
              },
            }}
          >
            <CustomDataGrid
              components={{ NoRowsOverlay }}
              columns={listPurchaseOrderColumn(listStatus, handleDetail,setAnchorEl,setSelectedPo,setOpenOptions)}
              disableSelectionOnClick
              pageSize={pageSize}
              rows={listPO}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 25, 100]}
            />
          </Box>
          {/* </div> */}
        </div>
        <Dialog maxWidth={"lg"} open={openPdf} onClose={handleClosePdf}>
          <DialogContent>
            <PDFViewer width="1000" height="600">
              <POPrintout data={dataPdf} />
            </PDFViewer>
          </DialogContent>
        </Dialog>
      </div>
    </Page>
  );
}

export default PurchaseOrder;
