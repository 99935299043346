const initialState = {
    screen: 'list-po',
    selectedPOVendor: null,
    listPO:[],
    detailPO:[],
    message:null,
    listPrintOut:[]
  };
  
  const poReducer = (state = initialState, action) => {
    switch(action.type){
      case 'CHANGE_SCREEN_PO':
        return {
          ...state,
          screen: action.payload
        };
      case 'CHANGE_SELECTED_PO':
        return {
          ...state,
          selectedPOVendor: action.payload
        };
        case 'LIST_PO_MENU':
        return {
          ...state,
          listPO: action.payload
        };
        case 'LIST_DETAIL_PO_MENU':
        return {
          ...state,
          detailPO: action.payload
        };
        case 'UPDATE_PO':
        return {
          ...state,
          message: action.payload
        };
        case 'LIST_DETAIL_PRINT_PO':
        return {
          ...state,
          listPrintOut: action.payload
        };
        
      default:
        return state;
    }
  }
  
  export default poReducer;