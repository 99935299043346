import React, { useState,useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
// import picture
// import authLogo from "../../assets/img/logoIntaAuth.svg";
// import form from "../../assets/img/form.png";
import newAuthLogo from "../../assets/img/newLogoAuth.svg"

import Button from "../../components/button/index";
import Page from "../../components/Page";

import { Grid } from "@mui/material";
import { setItem } from "../../configs/utilities";
import { useDispatch} from "react-redux";
import {getInfoVendor,loginAuth,getUser} from "../../configs/redux/action/auth"
import {
  getTitle,
  getCountry,
  getCurrency,
  getPaymentMethods,
  getPaymentterms,
  getSysparam,
  getBankAccount,
  getListBank,
  getPaymenttermsDueDate,
} from "../../configs/redux/action/apiMaster";

function LoginVendor() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading,setLoading]=useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format").required("Required!"),
      password: Yup.string()
        .min(6, "Minimum 6 characters")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      setLoading(true)
      const params = new URLSearchParams();
      params.append('client_id', 'ro.client');
      params.append('client_secret', process.env.REACT_APP_SECRET);
      params.append('username', values.email);
      params.append('password',values.password);
      params.append('grant_type',"password");
      if (localStorage.getItem("session_token_")) {
        params.append("session_token", localStorage.getItem("session_token_"));
      }
      dispatch(loginAuth(params)).then((res)=>{
        localStorage.setItem("session_token_", res.data.session_token);
        setItem("access_token", res.data.access_token);
        setItem("refresh_token", res.data.refresh_token);
        dispatch(getUser())
          .then((response) => {
            setItem("user_info", JSON.stringify(response.data));
            setItem("GR", response.data.role);
            setItem("user_id", response.data.sub);
            setItem("name", response.data.full_name);
            dispatch(getInfoVendor(`?UserId=${response.data.sub}`)).then((res)=>{
              if(res.data.length >0){
                dispatch(getBankAccount(res.data[0].vendorCode))
                dispatch(getListBank());
                dispatch(getSysparam("?ParamCode=COMPANY_TAX_TYPE"));
                dispatch(getPaymentterms());
                dispatch(getPaymentMethods());
                dispatch(getTitle());
                dispatch(getCountry());
                dispatch(getCurrency()); 

                dispatch(getPaymenttermsDueDate(res.data[0].termOfPayment))
                // setTimeout(() => {
                  setLoading(false)
                  history.push("/");
                // }, 3000);
              }else{
                setLoading(false)
                Toastify({
                  text: "User not found",
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: "top",
                  position: "right",
                  stopOnFocus: true,
                  style: {
                    background: "#CB605A",
                  },
                }).showToast();
              }
            })
          })
          .catch((err) => {
            setLoading(false)
            console.log(err.data);
          });
      }).catch((e)=>{
        setLoading(false)
        const error = e.data.error_description;
        console.log(e.data);

        Toastify({
          text: error !== undefined ? error : "Connection Timeout",
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "#CB605A",
          },
        }).showToast();
      })
    },
  });
  // const handleBackAuth = () => {
  //   history.push("/auth");
  // };

  useEffect(() => {
    dispatch(getSysparam("?ParamCode=COMPANY_TAX_TYPE"))
    // dispatch(getListBank())
    dispatch(getPaymentterms())
    dispatch(getPaymentMethods())
    dispatch(getTitle())
    dispatch(getCountry())
    dispatch(getCurrency())// eslint-disable-next-line
  }, [])


  return (
    <Page title="Login">
      <Grid container spacing={2}>
        <Grid item xs={7} md={7} sx={{ backgroundColor: "#FFCD08" }}>
          <div style={{ position: "relative" }}>
            <div className="bg-auth-portal"></div>
            <div className="bg-circle-top-left"></div>
            <div className="bg-circle-bottom-right"></div>
          </div>
        </Grid>
        <Grid item xs={5} md={5}>
          <div className="side-auth">
            {/* <Button btn="btn-back-auth" title="Back" onClick={handleBackAuth} /> */}

            <img src={newAuthLogo} style={{marginTop:"50px"}} alt="logo" />
            {/* <div className="title-auth-right">
              <img src={form} width="20px" height="20px" alt="icon-form" />
              <p>Intraco Payment System</p>
            </div> */}
            <div className="label-as">
              <p>As</p>
              <div className="label-as-vendor">Vendor</div>
            </div>
            <div className="tab-link" style={{width:"100%"}}>
              <Link to="/auth/vdr/login" className="active">
                Login
              </Link>
              <Link to="/auth/vdr/register" className="inactive">
                Register
              </Link>
            </div>
            <form onSubmit={formik.handleSubmit} style={{width:"100%",paddingRight:"60px"}} >
              <div>
                <input
                  id="email"
                  placeholder="Email"
                  type="email"
                  className="input-auth"
                  style={{ marginTop: "30px" }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email && (
                  <p
                    className="error"
                    style={{
                      color: "#CB605A",
                      fontSize: "12px",
                      marginTop: "-15px",
                    }}
                  >
                    {formik.errors.email}
                  </p>
                )}
              </div>
              <div >
                <input
                  id="password"
                  placeholder="Password"
                  type="password"
                  className="input-auth"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.errors.password && formik.touched.password && (
                  <p
                    className="error"
                    style={{
                      color: "#CB605A",
                      fontSize: "12px",
                      marginTop: "-15px",
                    }}
                  >
                    {formik.errors.password}
                  </p>
                )}
              </div>
              
              <Button type="submit" btn="btn-auth" title={loading === true ?"Loading":"Login"} color="navy" />
            </form>
            {/* <Link
              to="/forgot-password"
              style={{ textDecoration: "none", color: "#33A1FD" }}
            >
              Forgot Password?
            </Link> */}
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}

export default LoginVendor;
