import { RequestGet,RequestPost,RequestDelete, RequestPut } from "../../../utilities";

import uuid from "react-uuid";
export const changeScreenIS = (screen) => (dispatch) => {
  return dispatch({
    type: "CHANGE_SCREEN_IS",
    payload: screen,
  });
};

export const changeSelectedIS = (data) => (dispatch) => {
  return dispatch({
    type: "CHANGE_SELECTED_IS",
    payload: data,
  });
};

export const getListPO = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestGet("InvoiceTrade/ListPurchaseOrder" + query)
      .then((res) => {
        const data = res.data;
        data.forEach(function (element) {
          element.id = uuid();
        });
        dispatch({
          type: "LIST_PO",
          payload: data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemListPO = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestGet("InvoiceTrade/ItemList",data)
      .then((res) => {
        const data = res.data;
        // data.forEach(function (element) {
        //   element.id = uuid();
        // });
        dispatch({
          type: "ITEM_LIST_PO",
          payload: data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemListInvoice = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if(query === undefined && query === null && query === "") {
      query = "";
    }
    RequestGet("InvoiceTrade/InvoiceSubmission"+query)
      .then((res) => {
        const data = res.data;
        data.forEach(function (element) {
          element.id = uuid();
        });
        dispatch({
          type: "ITEM_LIST_IS",
          payload: data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getItemWhtType = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestGet("TaxRate/TaxrateSearch"+query)
      .then((res) => {
        dispatch({
          type: "DROPDOWN_WHT",
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createInvoice = (data) => (dispatch) => {
  console.log(data);
  return new Promise((resolve, reject) => {
    RequestPost("InvoiceTrade/CreateInvoice",data)
      .then((res) => {
        dispatch({
          type: "CREATE_INVOICE",
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateInvoice = (query,data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestPut("InvoiceTrade/UpdateInvoice"+query,data)
      .then((res) => {
        dispatch({
          type: "CREATE_INVOICE",
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSupportDoc = (countryCode) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestGet("InvoiceTrade/SettingSupportDoc")
      .then((res) => {
        const data = res.data.filter((d,i)=>{
          return d.vendorData === 1
        })
        data.forEach(function (element) {
          element.id = uuid();
          element.fileName=null
          element.fileSupporting=null
          element.upload=false
          element.documentNo=null
          

          // id invoice vendor, faktur pajak,surat jalan , BAST
          if(countryCode === "ID" && (element.idDocSetting === "0649ec74-b0da-11ec-af65-000c29e307d9" || element.idDocSetting === "178370e4-b0db-11ec-af65-000c29e307d9" || element.idDocSetting === "178736c2-b0db-11ec-af65-000c29e307d9" || element.idDocSetting === "178a10c3-b0db-11ec-af65-000c29e307d9" )){
            element.mandatory=true
            // id invoice vendor
          }else if (element.idDocSetting === "0649ec74-b0da-11ec-af65-000c29e307d9") {
            element.mandatory=true
          }
          else{
            element.mandatory=false
          }

        });

        dispatch({
          type: "LIST_SUPPORT_DOC",
          payload:data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getItemType = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestGet("ItemTypeSetting/Search")
      .then((res) => {
        const data = res.data
        data.forEach(function (element) {
          element.itemTypeAmount= null
          element.itemTypeVat= null
          element.checkVat= false
          element.checkWht= false
        element.itemTypeVatRate= 0
        element.itemTypeVatAmount= null
        element.itemTypeWht= null
        element.itemTypeWhtType= null
        element.itemTypeWhtSubtype= null
        element.itemTypeWhtRate=0
        element.itemTypeWhtAmount= 0
        element.itemTypeWhtAmountToBePaid= 0
        });
        dispatch({
          type: "LIST_ITEM_TYPE",
          payload:data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteInvoice= (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestDelete(`InvoiceTrade/DeleteInvoice?PaymentReqNo=${data}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const detailInvoice= (data,status,countryCode) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestGet(`InvoiceTrade/Detail?PaymentReqNo=${data}`)
      .then((res) => {
        const dataNew = res.data
        dataNew.listPurchaseOrderRes.forEach(function (element,idx) {
          element.id = uuid();
        });
        dataNew.listItemListPOInvoiceRes.forEach(function (element) {
          // element.id = index+1
          element.billedQty=element.balanceQty
          if (status === 0 || status ===4) {
            if(element.currency ==="IDR"){
             element.amountInvoice=Math.ceil(element.balanceQty*element.pricePerUnit)
            }else{
             element.amountInvoice=(element.balanceQty*element.pricePerUnit).toFixed(2)
            }
          }
        });
        
        // get wht type dan wht sub type
        const filterwht = dataNew.listItemTypeRes.filter((d)=>d.itemTypeWht === 1)
        let whtType;
        let whtSubtype;
        if (filterwht.length > 0) {
         whtType=filterwht[0].itemTypeWhtType
         whtSubtype=filterwht[0].itemTypeWhtSubtype
        }else{
          whtType=null
          whtSubtype=null
        }

        let TopType=dataNew.listTermOfPaymentRes[0].topType === "null" ||dataNew.listTermOfPaymentRes[0].topType === null ? null : dataNew.listTermOfPaymentRes[0].topType
        let TopInstallment =dataNew.listTermOfPaymentRes[0].topInstallment

        const filterListDoc = dataNew.listSupportingDocumentRes.filter((d)=>d.isUpload === 1)
        const filterItemList = dataNew.listItemListPOInvoiceRes.filter((d)=>d.isCheckBox === true)
        const filterListPO = dataNew.listPurchaseOrderRes.filter((d)=>d.isCheckBox === true)
        const filterItemType = dataNew.listItemTypeRes.filter((d)=>d.isCheckBox === true)
        const filterItemTypeMaterialService = dataNew.listItemTypeRes.filter((d)=>d.itemTypeDesc === "Material" || d.itemTypeDesc === "Services" )
        
        // ammount before tax
        let amountTemp =[]
        let idSelectionModel=[]
        let curr=[]
        // if (data.PaymentType === "Credit Payment") {
          filterItemList.forEach((data)=>{
            curr.push(data.currency)
            idSelectionModel.push(data.id)
            if (status === 0 ||status === 4) {
              if(data.currency ==="IDR"){
                data.amountInvoice =Math.ceil(data.balanceQty*data.pricePerUnit)
              }
              else{
                data.amountInvoice =(data.balanceQty*data.pricePerUnit).toFixed(2)
              }
            }
            amountTemp.push(Number(data.amountInvoice))
          })
          const uniqueCurr = [...new Set(curr)];

        // }else if(data.PaymentType === "Advanced Payment"){
        //   filter.forEach((data)=>amountTemp.push(data.remainingAmountBalance))
        // }
        // let newAmmountBeforeTax;
        let newAmmountBeforeTax
        if(uniqueCurr[0] === "IDR"){
          newAmmountBeforeTax= Math.ceil(amountTemp.reduce(
            (a, b) => a + b,
            0
          )) 
        }else{
          newAmmountBeforeTax= amountTemp.reduce(
            (a, b) => a + b,
            0
          ).toFixed(2)
        }
        
        let itemListPo=[];
        let dataPoNo=[]
        let idSelectPo=[]
          filterListPO.forEach((d)=>{
            idSelectPo.push(d.id)
            itemListPo.push({
              PoNo:d.poNo
            })
            dataPoNo.push(d.poNo)
          })
        


          dataNew.listItemTypeRes.forEach(function (element,index) {
            element.id = uuid();
            element.itemTypeNo = index+1
            if (element.itemTypeVat) {
              element.checkVat= true
            }
            if(element.itemTypeWht){
              element.checkWht= true
            }
           
          });  
        let selectedItemType=[]
        let totalAmountServiceMaterialTemp=[]
        let idItemType=[]
        filterItemTypeMaterialService.forEach((d)=>totalAmountServiceMaterialTemp.push(Number(d.itemTypeAmount)))
        filterItemType.forEach((d)=>{
          idItemType.push(d.id)
          selectedItemType.push({
            itemTypeNo: d.itemTypeNo,
            itemTypeDesc: d.itemTypeDesc,
            itemTypeAmount: Number(d.itemTypeAmount),
            itemTypeVat: d.itemTypeVat,
            itemTypeVatRate: d.itemTypeVatRate,
            itemTypeVatAmount: Number(d.itemTypeVatAmount),
            itemTypeDppRate: d.itemTypeDppRate,
            itemTypeDppAmount: Number(d.itemTypeDppAmount),
            itemTypeWht: d.itemTypeWht,
            itemTypeWhtType: d.itemTypeWhtType,
            itemTypeWhtSubtype: d.itemTypeWhtSubtype,
            itemTypeWhtRate: Number(d.itemTypeWhtRate),
            itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
            itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
          });
        })

        let totalAmountServiceMaterial = totalAmountServiceMaterialTemp.reduce((a, b) => a + b, 0).toFixed(2)
        let statusItemType;

        if(dataNew.listPaymentRequestRes[0].paymentType === "Credit Payment"){
          if ( totalAmountServiceMaterial < newAmmountBeforeTax ||totalAmountServiceMaterial > newAmmountBeforeTax ) {
            statusItemType=true
          }else{
            statusItemType=false
          }
        }else if(dataNew.listPaymentRequestRes[0].paymentType === "Advanced Payment"){
          if ( totalAmountServiceMaterial < dataNew.listPaymentRequestRes[0].topAmount.toFixed(2) ||totalAmountServiceMaterial > dataNew.listPaymentRequestRes[0].topAmount.toFixed(2) ) {
            statusItemType=true
          }else{
            statusItemType=false
          }
        }

        let supportDoc=[]
        filterListDoc.forEach((d)=>{
          supportDoc.push({
            DocSettingId:d.documentSettingId,
            DocumentNo:d.documentNo,
            FileSupporting:d.url
          })
        })

        

        
        
        
        dataNew.listSupportingDocumentRes.forEach(function (element) {
          element.id = uuid();
          element.fileSupporting=element.url
          if(countryCode === "ID" && (element.documentSettingId === "0649ec74-b0da-11ec-af65-000c29e307d9" || element.documentSettingId === "178370e4-b0db-11ec-af65-000c29e307d9" || element.documentSettingId === "178736c2-b0db-11ec-af65-000c29e307d9" || element.documentSettingId === "178a10c3-b0db-11ec-af65-000c29e307d9" )){
            element.mandatory=true
            // id invoice vendor
          }else if (element.documentSettingId === "0649ec74-b0da-11ec-af65-000c29e307d9") {
            element.mandatory=true
          }
          else{
            element.mandatory=false
          }
        });
        dataNew.listSupportingDocumentRes[0].documentNo=dataNew.listPaymentRequestRes[0].vendorInvoiceNo
        dataNew.listTermOfPaymentRes.forEach(function (element) {
          element.id = uuid();
        });
        
        if (TopType === "Installment") {
          dataNew.listTermOfPaymentRes.sort((a, b) => a.installment - b.installment);
        }

        dataNew.listPaymentRequestRes.forEach(function (element) {
          element.status = status
          element.whtType = whtType
          element.whtSubtype = whtSubtype
          element.TopType=TopType
          element.TopInstallment=TopInstallment
          element.filterListDoc = filterListDoc
          element.filterItemList = filterItemList
          element.amountBeforeTax= newAmmountBeforeTax
          element.filterItemType= selectedItemType
          element.attachment=supportDoc
          element.totalAmountServiceMaterial=totalAmountServiceMaterial
          element.statusItemType=statusItemType
          element.itemListPo=itemListPo
          element.selectionModelItemList=idSelectionModel
          element.selectionModelPO=idSelectPo
          element.selectionModelItemType=idItemType
          element.PoNo=dataPoNo
        });


        dispatch({
          type: "LIST_DETAIL",
          payload:dataNew,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const jurnalInvoice= (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestPost(`InvoiceTrade/JournalInvoice`,data)
      .then((res) => {
        dispatch({
          type: "LIST_JURNAL",
          payload:res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadFile= (url) => () => {
  return new Promise((resolve, reject) => {
    RequestPost("DownloadFile/FileSearch",url,{responseType: 'blob'})
    .then((response) =>{
        resolve(response);
      // response.data.blob()
    })
      .catch((err) => {
        reject(err);
      });
  });
};


export const uploadItemListExcel= (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RequestPost(`InvoiceTrade/UploadItemList`,data)
      .then((res) => {
        // dispatch({
        //   type: "LIST_ITEM_LIST_UPLOAD",
        //   payload:res.data,
        // });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const exportFormPP = (paymentReqNo) => (dispatch) => {
  return new Promise((resolve, reject) => {
      RequestGet('FormPP/ExportApprovalHistory?PaymentReqNo=' + paymentReqNo)
          .then(res => {
              resolve(res);
          })
          .catch(err => {
              reject(err);
          })
  })
}

export const getTaxConfiguration = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
      RequestGet('TaxConf/TaxConfSearch')
          .then(res => {
            dispatch({
              type: "TAX_CONFIG_ITEM",
              payload:res.data,
            });
            resolve(res);
          })
          .catch(err => {
              reject(err);
          })
  })
}