import NumberFormat from "react-number-format";
import {
    TextField, 
    Checkbox, 
    Tooltip,
} from "@mui/material";

function NumberFormatCustom(props){
    const { inputRef, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
        />
    );
}

// note Column+View for status !== 0 || 4 

export const columnPoView = () => ([
    {
        field: "poNo",
        headerName: "PO Number",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex: 1,
        minwidth: 140,
      },
      {
        field: "costCenter",
        headerName: "Cost Center",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "createBy",
        headerName: "Created By",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 140,
      },
      {
        field: "paymentTerm",
        headerName: "Payment Term",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "paymentIn",
        headerName: "Payment In",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "companyCode",
        headerName: "Company Code",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "poType",
        headerName: "PO Type",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
        renderCell: (cellValues) => {
          switch (cellValues.row.poType) {
            case 0:return <p>Non Trade</p>
            case 1:return <p>Trade</p>
            default:
              return null
          }
        },
      },
      {
        field: "purchaseOrganization",
        headerName: "Purchasing Org",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "purchaseGroup",
        headerName: "Purch. Group",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "exchangeRate",
        headerName: "Exchange rate",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "incoterms",
        headerName: "Incoterms",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "incotermsDesc",
        headerName: "Incoterms Desc",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex: 1,
        minwidth: 160,
      },
]);

export const columnItemListView = () => ([
    {
        field: "poNo",
        headerName: "PO Number",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 140,
    },
    {
        field: "item",
        headerName: "Item",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 60,
    },
    {
        field: "awbInvoiceNo",
        headerName: "AWB/INV NO",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex:1,
        minwidth: 160,
    },
    {
        field: "material",
        headerName: "Material",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 140,
    },
    {
        field: "shortText",
        headerName: "Short Text (Item)",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex: 1,
        minwidth: 160,
    },
    {
        field: "condition",
        headerName: "CnTy",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
    },
    {
        field: "conditionDesc",
        headerName: "Cnty Desc",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
    },
    {
        field: "quantity",
        headerName: "Qty",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 60,
    },
    {
        field: "currency",
        headerName: "Curr",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 60,
    },
    {
        field: "netValue",
        headerName: "Net Value",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
        renderCell: (cellValues) => {
            if(cellValues.row.netValue !== null && cellValues.row.netValue !== undefined){
            return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.netValue} />
            }
        },
    },
    {
        field: "itemStatus",
        headerName: "Item Status",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 140,
    },
    {
        field: "grStatus",
        headerName: "GR Status",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
    },
    {
        field: "billedQty",
        headerName: "Billed Qty",
        headerClassName: "super-app-theme--header",
        editable: false,
        type: 'number',
        width: 90,
        renderCell: (cellValues) => {
          return (
            <input
              disabled={true}
              value={cellValues.row.billedQty}
              className="input-BilledQty"
              id="billedQty"
              name="billedQty"
              type="number"
            />
          );
        },
    },
    {
        field: "amountInvoice",
        headerName: "Amount Inv.",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 160,
        disableExport:true
    },
]);

export const columnItemTypeView = (data,dropdownWht,dropdownWhtType,dropdownWhtSubType) => ([
    {
        field: "itemTypeDesc",
        headerName: "Item Type",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 160,
      },
      {
        field: "itemTypeAmount",
        headerName: "Amount",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
        renderCell: (cellValues) => {
          return (
            <TextField
              disabled={true}
              margin="none"
              InputProps={{
                  inputComponent: NumberFormatCustom,
                  className:data.TotalAmountItemType === true ? "input-amountType-warning" :"input-amountType"
              }}
              name="itemTypeAmount"
              size="small"
              value={cellValues.row.itemTypeAmount}
          />
          );
        },
      },
      {
        field: "itemTypeVat",
        headerName: "VAT",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 160,
        renderCell: (cellValues) => {
          switch (cellValues.row.vat) {
            case 1:return <Checkbox name="itemTypeVat" disabled={true} checked={cellValues.row.checkVat} />
            default:
              return ''
          }
        },
      },
      {
        field: "itemTypeVatRate",
        headerName: "VAT Rate",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 140,
        renderCell:(cellValues)=>{
          switch (cellValues.row.vat ) {
            case 1:
                  return(
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                  value={Number(cellValues.row.itemTypeVatRate)}
                    disabled={true}
                    className="input-BilledQty"
                    id="itemTypeVatRate"
                    name="itemTypeVatRate"
                    type="number"
                  />
                  <p style={{ marginLeft: "20px" }}>%</p>
                </div>
  
                  )
            default:
              return <p></p>;
          }
        }
      },
      {
        field: "itemTypeDppAmount",
        headerName: "DPP Amount",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 160,
        renderCell: (cellValues) => {
          switch (cellValues.row.vat) {
            case 1:
            return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.itemTypeDppAmount} />
            default:
              return '';
          }
        },
      },
      {
        field: "itemTypeVatAmount",
        headerName: "VAT Amount",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 160,
        renderCell: (cellValues) => {
          switch (cellValues.row.vat) {
            case 1:
            return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.itemTypeVatAmount} />
            default:
              return '';
          }
        },
      },
      {
        field: "itemTypeWht",
        headerName: "Wht",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 160,
        renderCell: (cellValues) => {
          switch (cellValues.row.wht) {
            case 1:return <Checkbox disabled={true} name="itemTypeWht" checked={cellValues.row.checkWht} />
            default:
              return ''
          }
        },
      },
      {
        field: "whtType",
        headerName: "Wht Type",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
        renderCell: (cellValues) => {
            return(
                <Tooltip title={cellValues.row.itemTypeWhtType} >
                <span className="table-cell-trucate">{cellValues.row.itemTypeWhtType}</span>
                </Tooltip>
            )
        },
      },
      {
        field: "whtSubType",
        headerName: "Wht Sub Type",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex: 1,
        minwidth: 300,
        renderCell: (cellValues) => {
            const findData = dropdownWht.find((d)=>d.companyCode === data.companyCode && d.taxType === cellValues.row.itemTypeWhtType && d.serviceType === cellValues.row.itemTypeWhtSubtype)
            return(
                <Tooltip title={findData?.serviceType} >
                <span className="table-cell-trucate">{findData?.serviceType}</span>
                </Tooltip>
            )
        },
    },
    {
        field: "itemTypeWhtRate",
        headerName: "Wht Rate",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
        renderCell:(cellValues)=>{
            return(
                <div style={{ display: "flex", alignItems: "center" }}>
                <input
                    disabled={true}
                    value={cellValues.row.itemTypeWhtRate}
                    className="input-BilledQty"
                    id="itemTypeWhtRate"
                    name="itemTypeWhtRate"
                    type="number"
                />
                <p style={{ marginLeft: "20px" }}>%</p>
            </div>
            )
            
        }
    },
    {
        field: "itemTypeWhtAmount",
        headerName: "Wht Amount",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
        renderCell:(cellValues)=>{
            switch (cellValues.row.wht) {
            case 1:
                return(
                <input
                    disabled={true}
                    value={cellValues.row.itemTypeWhtAmount}
                    className="input-BilledQty"
                    id="itemTypeWhtAmount"
                    name="itemTypeWhtAmount"
                    type="number"
                />
                )
            default:
            return<p></p>;
        }
      }
      },
    {
        field: "itemTypeWhtAmountToBePaid",
        headerName: "Amount To Be Paid",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 140,
        renderCell:(cellValues)=>{
            const data = cellValues.row.itemTypeWhtAmountToBePaid.toFixed(2)
            const result = Number(data);
            return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={result} />
        }
    },
])


export const columnSupportDocumentView = (handleDownloadFile) => ([
    {
        field: "documentName",
        headerName: "Document Name",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 490,
        renderCell:(cellValues)=>{
          return <p className={cellValues.row.mandatory === true ?"mandatory-doc":undefined}>{cellValues.row.documentName}</p>
  
        }
      },
      {
        field: "documentNo",
        headerName: "Document No",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 310,
        renderCell:(cellValues)=>{
          return <input className="input-document-no" disabled={true}  name="documentNo" value={cellValues.row.documentNo === "null" ?null:cellValues.row.documentNo}   />
        }
      },
      {
        field: "fileName",
        headerName: "Upload File",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex: 1,
        minwidth: 400,
        renderCell: (cellValues) => {
          return (
              <p className="uploadFile-name" onClick={()=>handleDownloadFile(cellValues.row)} >{cellValues.row.fileName}</p>
          );
        },
      },
])