import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import uuid from "react-uuid";
import moment from "moment";
import { saveAs } from 'file-saver';
import Swal from "sweetalert2"

// redux
import { changeScreenPO, updatePOStatus } from "../../../../configs/redux/action/Vendor/purchaseOrder";
import {downloadFile} from "../../../../configs/redux/action/Vendor/invoiceSubmission"

// utils
import {detailItemList} from '../.././../../contants/purchaseOrder'

// component
import Navbar from "../../../../components/navBarVendor";
import Page from "../../../../components/Page";
import Button from "../../../../components/button";
import CustomDataGrid from "../../../../components/table";

import "../vendor.css";

function DetailPO() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { detailPO } = useSelector((state) => state.vendorPo);
  const { listStatus } = useSelector((state) => state.status);
  const [dataListPo,setDataListPo] = useState([]);
  
  const [pageSize, setPageSize] = useState(10)
  const [status,setStatus]=useState(null)
  const [rejectDialog,setRejectDialog]=useState(false)
  const [notes,setNotes]=useState("")

  const handleBack = () => {
    dispatch(changeScreenPO("list-po"));
    history.push(`/vdr/purchase-order`)

  };
  
  const updateStatus =(e,type)=>{
    setRejectDialog(false)
    // let userInfo = JSON.parse(getItem("user_info"))
    const newData ={
          "IdPOHeader": detailPO[0].idPOHeader,
          "approvalNotes":notes,
          "approvalStatus": type === "reject" ?6:5
    }

    if(type !== "reject") {
      delete newData["approvalNotes"]
    }

    const sentenceApprove = "Please Check wheater this PO already correct ? Need to ensure before make an approval, Will you approve this PO?"
    Swal.fire({
      title:type === "reject" && 'Are you sure?',
      text: type === "reject" ?  `You will ${type} this PO!` :sentenceApprove,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${type} it!`
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(newData,"newData");
        dispatch(updatePOStatus(newData)).then(()=>{
          Swal.fire(
            `${type}`,
            `Po number ${detailPO[0].purchaseNo} has been ${type}.`,
            'success'
          )
          dispatch(changeScreenPO("list-po"));
        }).catch((err)=>{
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        })
      }
    })
  }

  const handleDownloadFile=(event)=>{
    const urlData = {
      url:event
    }
    
    dispatch(downloadFile(urlData)).then((res)=>{
      var blob = new Blob([res.data], {type: "application/octet-stream"});
      let splitUrl=event.split("/")
      saveAs(blob, splitUrl[7])
    })
  }

  useEffect(() => {
    if (detailPO[0].itemList.length >= 1) {
      const newData = detailPO[0].itemList
      newData.forEach(element => {
        element.id=uuid()
      });
      setDataListPo(newData)
    }
    let dataStatus = listStatus
    let findData = dataStatus.find(d => parseInt(d.statusCode)  === detailPO[0].approvalStatus && d.category === "Approval_PO_Non_Trade" );
    if (findData !== undefined) {
      setStatus(findData.statusDescription)
    }
    // eslint-disable-next-line
  }, [])
  
  return (
    <Page title="purchase Order">
      <Navbar />
      <div className="container-vendor">
        <div className="detail-po">
          <div style={{ display: "flex" }}>
            <Button btn="btn-back-vendor" title="Back" onClick={handleBack} />
            <h5>{`PO Number ${detailPO[0].purchaseNo}`}</h5>
          </div>
          {detailPO[0].approvalStatus === 3 && 
          <div style={{ display: "flex" }}>
            <Button btn="btn-vendor" color="red" title="Reject" onClick={()=>setRejectDialog(true)} /> 
            <Button btn="btn-vendor-fill" title="Approve" onClick={(e)=>updateStatus(e,"approve")} /> 
          </div>
          }
        </div>
        <div className="content-list-po">
          <div className="square">
            <h3>Purchase Order Header</h3>
            <Grid container spacing={2}>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="PO Number">PO Number</label>
                <input
                  disabled={true}
                  id="PO Number"
                  name="PO Number"
                  type="text"
                  defaultValue={detailPO[0].purchaseNo}
                />
                <label htmlFor="POType">PO Type</label>
                <input
                  disabled={true}
                  id="POType"
                  name="POType"
                  type="text"
                  defaultValue={detailPO[0].poType === 1 ? "Trade":"Non Trade"}
                />
                {detailPO[0].poType === 0 && 
                  <>
                    <label htmlFor="AttachmentQuotation">Attachment Quotation</label>
                    <div className="download-Quotation">
                      <p onClick={(e) => handleDownloadFile(detailPO[0].attachmentQuotation)} style={{ pointerEvents: 'none', color: 'gray' }}>Download File</p>
                    </div>
                  </>
                
                }
                <label htmlFor="Currency">Currency</label>
                <input
                  disabled={true}
                  id="Currency"
                  name="Currency"
                  type="text"
                  defaultValue={detailPO[0].currency}
                />
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="PODescription">PO Description</label>
                <input
                  disabled={true}
                  id="PODescription"
                  name="PODescription"
                  type="text"
                  defaultValue={detailPO[0].purchaseDescription !== null ?detailPO[0].purchaseDescription:"-"}
                />
                <label htmlFor="Incoterms">Incoterms</label>
                <input
                  disabled={true}
                  id="Incoterms"
                  name="Incoterms"
                  type="text"
                  defaultValue={detailPO[0].incotermCode}
                />
                <label htmlFor="Status">Status</label>
                <input
                  disabled={true}
                  id="Status"
                  name="Status"
                  type="text"
                  defaultValue={status}
                />
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="PoDate">PO Date</label>
                <input
                  disabled={true}
                  id="PoDate"
                  name="PoDate"
                  type="text"
                  defaultValue={moment(detailPO[0].poDate).format("DD-MMM-YYYY")}
                />
                <label htmlFor="incotermsLoc">Incoterms Loc</label>
                <input
                  disabled={true}
                  id="incotermsLoc"
                  name="incotermsLoc"
                  type="text"
                  defaultValue={detailPO[0].incotermLocation !== null && detailPO[0].incotermLocation !== "" ?detailPO[0].incotermLocation:"-"}
                />
                <label htmlFor="PaymentTerm">Payment Term</label>
                <input
                  disabled={true}
                  id="PaymentTerm"
                  name="PaymentTerm"
                  type="text"
                  defaultValue={detailPO[0].paymentTerm !== null && detailPO[0].paymentTerm !== "" ?detailPO[0].paymentTerm:"-"}
                />
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="CreatedBy">Created By</label>
                <input
                  disabled={true}
                  id="CreatedBy"
                  name="CreatedBy"
                  type="text"
                  defaultValue={detailPO[0].createdBy}
                />
                <label htmlFor="companyCode">Company Code</label>
                <input
                  disabled={true}
                  id="companyCode"
                  name="companyCode"
                  type="text"
                  defaultValue={detailPO[0].companyCode}
                />
                <label htmlFor="PaymentIn">Payment In</label>
                <input
                  disabled={true}
                  id="PaymentIn"
                  name="PaymentIn"
                  type="text"
                  defaultValue={detailPO[0].paymentIn}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="content-list-po">
          <div className="square-table">
            <h3 style={{ paddingLeft: "30px", marginBottom: "30px" }}>
              Item List
            </h3>
            <Box
              sx={{
                height:700,
                width: 1,
                "& .super-app-theme--header": {
                  backgroundColor: "#001D61",
                  color: "#FFFFFF",
                },
              }}
            >
              <CustomDataGrid
                columns={detailItemList()}
                disableSelectionOnClick
                pageSize={pageSize}
                rows={dataListPo}
                rowsPerPageOptions={[5, 10, 25]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}

              />
            </Box>
          </div>
        </div>
      </div>
      {/* dialog reject */}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={rejectDialog}
        onClose={()=>setRejectDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Notes Reject</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="dialog-poTrade">
            <textarea className="text-area-reject-po" name="notes" onChange={(e)=>setNotes(e.target.value)}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setRejectDialog(false)}
            btn="btn-vendor"
            color="red"
            title="Cancel"
          />
          <Button  btn="btn-vendor-fill" color="bg-red" title="Reject" onClick={(e)=>updateStatus(e,"reject")} />

        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default DetailPO;
