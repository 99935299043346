import React, { useState,useEffect,useRef } from "react";
import Navbar from "../../../../components/navBarVendor";
import Page from "../../../../components/Page";
import { saveAs } from 'file-saver';
import "../vendor.css";
import * as excelJS from "exceljs";
import {Autocomplete, Skeleton, TextField, Box, Checkbox, Grid, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle, CircularProgress, Backdrop, Tooltip,} from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import {
  changeScreenIS,
  getItemListPO,
  getItemWhtType,
  jurnalInvoice,
  updateInvoice,
  downloadFile,
  uploadItemListExcel,
  getTaxConfiguration
} from "../../../../configs/redux/action/Vendor/invoiceSubmission";
import Button from "../../../../components/button";
import CustomDataGrid from "../../../../components/table";

import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import ConvertFormat from "../../../../helper/curency"
import { useHistory } from "react-router-dom";
import {columnPoView,columnItemListView,columnItemTypeView,columnSupportDocumentView} from '../../../../contants/invoiceTable'


function NumberFormatCustom(props){
  const { inputRef, ...other } = props;

  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
      />
  );
}

function RemoveFormatting(nilai){
  var numberString = nilai.toString();
  var strResult = numberString.replace(/\./g, "");
  var strResult1 = strResult.replace(/,/g, ".");
  return parseFloat(strResult1);
}

function DetailInvoice() {
  const history = useHistory()
  const today = new Date();
  const dispatch = useDispatch();
  const { dropdownWht,detailInvoice } = useSelector((state) => state.vendorInvoiceSubmission);
  const { listSysparam,listBankAccount,dueDate} = useSelector((state) => state.status);
  const { listInfoVendor} = useSelector((state) => state.infoVendor);


  const [checkAllListPO, setCheckAllListPO] = useState(false);
  const [indetListPO, setIndetListPO] = useState(false);
  const [checkedListPO, setCheckedListPO] = useState(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ?detailInvoice.listPaymentRequestRes[0].selectionModelPO:[]);
  const [checkAllItemList, setCheckAllItemList] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ?detailInvoice.listPaymentRequestRes[0].selectionModelItemList:[]);
  const [checkAllItemType, setCheckAllItemType] = useState(false);
  const [checkedItemType, setCheckedItemType] = useState(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ?detailInvoice.listPaymentRequestRes[0].selectionModelItemType:[]);
  const [loadingItemList, setLoadingItemList] = useState(false);
  const [loadingItemType, setLoadingItemType] = useState(false);
  const hiddenFileInput = useRef(null);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [data, setData] = useState({
    VendorInvoiceNumber:detailInvoice.listPaymentRequestRes[0].vendorInvoiceNo,
    poType: detailInvoice.listPaymentRequestRes[0].poType,
    PaymentType:detailInvoice.listPaymentRequestRes[0].paymentType,
    companyCode:detailInvoice.listPaymentRequestRes[0].companyCode,
    vendorCode: listInfoVendor[0].vendorCode,
    TaxType: detailInvoice.listPaymentRequestRes[0].taxType,

    PONo:detailInvoice.listPaymentRequestRes[0].PoNo,
    ListPO:detailInvoice.listPurchaseOrderRes,
    ItemList:detailInvoice.listItemListPOInvoiceRes,
    ItemListtemp:detailInvoice.listItemListPOInvoiceRes,

    TotalAmountToBePaid: detailInvoice.listPaymentRequestRes[0].totalAmountToBePaid,
    whtType: detailInvoice.listPaymentRequestRes[0].whtType,
    whtSubType: detailInvoice.listPaymentRequestRes[0].whtSubtype,
    TopType: detailInvoice.listPaymentRequestRes[0].TopType !== null ?detailInvoice.listPaymentRequestRes[0].TopType:null,
    TopInstallment: detailInvoice.listPaymentRequestRes[0].TopInstallment !== null ?detailInvoice.listPaymentRequestRes[0].TopInstallment:0 ,
    
    selectedItemList:detailInvoice.listPaymentRequestRes[0].filterItemList,
    selectedItemType:detailInvoice.listPaymentRequestRes[0].filterItemType,
    TotalAmountInstallment:false,
    TopPercentageCondition:false,
    TotalAmountBeforeTax:detailInvoice.listPaymentRequestRes[0].amountBeforeTax,
    TotalAmountServiceMaterial:detailInvoice.listPaymentRequestRes[0].totalAmountServiceMaterial,
    TotalAmountItemType:detailInvoice.listPaymentRequestRes[0].statusItemType,
    itemTypeAmount:0,
    
    whtRate:null,
    TopInstallmentDetail:detailInvoice.listPaymentRequestRes[0].TopInstallment !== null ?detailInvoice.listTermOfPaymentRes:[],
    TopDueDate:detailInvoice.listPaymentRequestRes[0].topDueDate !== null?moment(detailInvoice.listPaymentRequestRes[0].topDueDate ).format("yyyy-MM-DD"):detailInvoice.listPaymentRequestRes[0].topDueDate  ,
    TopPercentage:detailInvoice.listPaymentRequestRes[0].topPercentage,
    TopAmount:detailInvoice.listPaymentRequestRes[0].topAmount,
    BankName:detailInvoice.listBankAccountRes[0].bankName,
    BankAccountNo:detailInvoice.listBankAccountRes[0].bankAccountNo,
    BankAccountName:detailInvoice.listBankAccountRes[0].bankAccountName,

    PaymentRequestNumber: "",
    invoiceDate: "",
    attachment:detailInvoice.listPaymentRequestRes[0].attachment
  });

  const [selectPO,setSelectedPO]=useState([])
  const [selectionModelPO, setSelectionModelPO] = useState([]);
  const [selectionModelItemType, setSelectionItemType] = useState([]);
  const [selectListPo,setSelectedListPo]=useState(detailInvoice.listPaymentRequestRes[0].itemListPo)
  const [selectionModel, setSelectionModel] = useState(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ?detailInvoice.listPaymentRequestRes[0].selectionModelItemList:[]);
  const [selectedRows, setSelectedRows] = useState(detailInvoice.listPaymentRequestRes[0].filterItemList);
  // const [selectedRowsItemType, setSelectedRowsItemType] = useState([]);

  const [pageSizePO, setPageSizePO] = useState(10);
  const [pageSizeItemList, setPageSizeItemList] = useState(10);

  const [dropdownWhtType,setWhtType]=useState([])
  const [dropdownWhtSubType,setWhtSubType]=useState([{ value: "0", label: "---" }])
  const [dropdownTaxConfig,setTaxConfig]=useState([{ value: "0", label: "---", vat: 0, dpp: 0, dppNumber: "", isPercentage: false }])
  const [dataItemType,setItemType] = useState(detailInvoice.listItemTypeRes);
  const [attachSupportDoc,setAttachSupportDoc] = useState(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? detailInvoice.listSupportingDocumentRes:detailInvoice.listPaymentRequestRes[0].filterListDoc);
  const [selectTemp,setSelectTemp]=useState([])
  const [dropdownBank, setBankCode] = useState([]);
  const [dropdownBankNo, setBankNo] = useState([]);
  const [currency,setCurrency]=useState(null)
  const [loadingSave,setLoadingSave]=useState(false)
  const [loadingSubmit,setLoadingSubmit]=useState(false)
  const [uploadItemList,setUploadItemList]=useState(false)
  const [fileUploadItemList,setFileUploadItemList]=useState([])
  const [errorDialog, setErrorDialog] = useState(false);
  const [listErrorMsg, setListErrorMsg] = useState([]);
  const [pageErr, setPageErr] = useState(5);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const exportToExcel =()=>{
    const newData=data.ItemList
    const newDataTemp =[]
    newData.forEach((d,idx)=>{
      newDataTemp.push({
        poNo:d.poNo,
        item:d.item,
        awbInvoiceNo:d.awbInvoiceNo === null? "NULL":d.awbInvoiceNo,
        material:d.material,
        shortText:d.shortText,
        condition:d.condition,
        conditionDesc:d.conditionDesc,
        quantity:d.quantity,
        currency:d.currency,
        netValue:d.netValue,
        itemStatus:d.itemStatus === null ? "NULL":d.itemStatus,
        grStatus:d.grStatus,
        billedQty:d.billedQty,
      })
    })

    if(newDataTemp.length > 0){
      const date = moment().format('YYYY-MM-DD')
      const workbook = new excelJS.Workbook();
      workbook.creator = "SYSTEM";
      workbook.lastModifiedBy = "SYSTEM";
      workbook.created = new Date();
      workbook.modified = new Date();

      let sheet = workbook.addWorksheet("ItemList");
      sheet.getRow(1).values = [
        "PO Number", 
        "Item",
        "AWB/INV NO",
        "Material",
        "Short Text (Item)",
        "CnTy",
        "Cnty Desc",
        "Qty",
        "Curr",
        "Net Value",
        "itemStatus",
        "GR Status",
        "Billed Qty",
      ];
      sheet.columns = [
        {
          key: "poNo",
          width: 30
        },
        {
          key: "item",
          width: 30
        },
        {
          key: "awbInvoiceNo",
          width: 25
        },
        {
          key: "material",
          width: 25
        },
        {
          key: "shortText",
          width: 40
        },
        {
          key: "condition",
          width: 20
        },
        {
          key: "conditionDesc",
          width: 30
        },
        {
          key: "quantity",
          width: 30
        },
        {
          key: "currency",
          width: 30
        },
        {
          key: "netValue",
          width: 30
        },
        {
          key: "itemStatus",
          width:30
        },
        {
          key: "grStatus",
          width: 30
        },
        {
          key: "billedQty",
          width: 30
        },
      ];
      sheet.addRows(newDataTemp);
    // // if(res.data.length > 1){
    // //   sheet.mergeCells(`C2:C${1+res.data.length}`);
    // // }

      const row = sheet.getRow(1);
      row.eachCell((cell, rowNumber) => {
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'ffff00'},
          // bgColor:{
          //   argb:'FFFDFA62'
          // }
        };
        sheet.getColumn(rowNumber).alignment = {
          vertical: "top",
          horizontal: "left"
        };
        sheet.getColumn(rowNumber).font = { size: 14, family: 2 };
      });

      workbook.xlsx.writeBuffer().then(function(buffer) {
        // done
    
        const blob = new Blob([buffer], { type: "applicationi/xlsx" });
        saveAs(blob, `ItemList-${date}.xlsx`);
      });
    }else{
      Toastify({
        text: "No data available.",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "#FFCC00",
        },
      }).showToast();
    }
    
  }

  const handleUploadItemList =(e)=>{
    setLoadingUpload(true)
    e.preventDefault();
    const files = fileUploadItemList

    const formData = new FormData();
    formData.append("vendorCode",listInfoVendor[0].vendorCode)
    formData.append("file", files);
    dispatch(uploadItemListExcel(formData)).then((res)=>{
      if(res.data.message === "Success"){
        const itemTemp =data.ItemList
        const dataTemp = res.data.InvoiceUploadItemList
        
        const filterItemTemp = itemTemp.filter((elem) => dataTemp.find(({ poNo,item,conditionCode }) => elem.poNo === poNo && elem.item === Number(item) && elem.condition === conditionCode  ))
  
        let newValueItemList = [];
  
        for(let i = 0; i < filterItemTemp.length; i += 1) {
          let newArray = dataTemp.filter(function (el) {
              return el.poNo === filterItemTemp[i].poNo && Number(el.item)===filterItemTemp[i].item && el.conditionCode === filterItemTemp[i].condition ;
          });
          if(newArray.length ===0)
          {
            newValueItemList.push(filterItemTemp[i]);    
          }
          else
          {
            filterItemTemp[i].isCheckBox=true
            filterItemTemp[i].billedQty = Number(newArray[0].billedQty); 
            filterItemTemp[i].balanceQty = Number(newArray[0].billedQty); 
            filterItemTemp[i].amountInvoice=filterItemTemp[i].currency === "IDR"?Math.ceil(Number(newArray[0].billedQty)*filterItemTemp[i].pricePerUnit) :(Number(newArray[0].billedQty)*filterItemTemp[i].pricePerUnit).toFixed(2) 
            newValueItemList.push(filterItemTemp[i]);
          }
        };
  
        const findIsCheckboxFalse = itemTemp.filter((elem) => !newValueItemList.find(({ poNo,item,condition }) => elem.poNo === poNo && elem.item === item && elem.condition === condition ))
  
        const mergedArray =[...newValueItemList,...findIsCheckboxFalse]
  
        let newDataSelected=[]
        let amountBeforeTax=[]
        let curr=[]
        let idSelectionModel=[]
        newValueItemList.forEach((d)=>{
          idSelectionModel.push(d.id)
          curr.push(d.currency)
          amountBeforeTax.push(Number(d.amountInvoice));
          newDataSelected.push({
            amountInvoice: Number(d.amountInvoice),
            item: d.item,
            billedQty: parseInt(d.balanceQty),
            conditionId: d.conditionId,
            poNo: d.poNo,
          });
        })
  
        let newAmmountBeforeTax = amountBeforeTax.reduce(
          (a, b) => a + b,
          0
        )
        const uniqueCurr = [...new Set(curr)];
       
        setCheckedItemList(idSelectionModel)
        setSelectionModel(idSelectionModel)
        setCurrency(uniqueCurr)
        setSelectedRows(newValueItemList)
        setData({
          ...data,
          ItemList:mergedArray,
          TotalAmountBeforeTax: newAmmountBeforeTax,
          selectedItemList: newDataSelected,
        })
        setLoadingUpload(false)
        setUploadItemList(false)
        Toastify({
          text: "Upload Item Success",
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "#5EBA7D",
          },
        }).showToast();
      }else{
        setUploadItemList(false)
        setLoadingUpload(false)
        const error = res.data.listErrorUpload;
          error.forEach((data, ind) => {
            data.id = ind + 1;
          });
          setListErrorMsg(error);
          setErrorDialog(true);
      }
      
    })
    .catch((err)=>{
      setUploadItemList(false)
        setLoadingUpload(false)
        const error = err.data.listErrorUpload;
          error.forEach((data, ind) => {
            data.id = ind + 1;
          });
          setListErrorMsg(error);
          setErrorDialog(true);
    })
  }

  const handleFormChange = (event,dataSelect) => {
    const dataNew = { ...data };
    dataNew[event.target.name] = event.target.value;
    setData(dataNew);
    if (event.target.name === "TopInstallment") {
      let dataTemp = [];
      let number = parseInt(dataNew.TopInstallment);
      let i;
      for (i = 1; i <= number; i++) {
        dataTemp.push({
          id: i,
          installment: i,
          dueDate: null,
          amount: null,
        });
      }
      setTopInstallmentDetail(dataTemp);
    }else if(event.target.name === "TopType"){
      if(event.target.value === "Installment"){
        setData({
          ...data,
          TopType: data.PaymentType !== "Advanced Payment" ? "Installment" : data.TopType ,
          TopDueDate:dueDate,
          TopInstallment:0,
          TopInstallmentDetail:[],
        })
      }else if(event.target.value === "Credit"){
        setData({
          ...data,
          TopType:"Credit",
          TopDueDate:dueDate,
          TopInstallment:0,
          TopInstallmentDetail:[],
          TopPercentage:null,
          TopAmount:0,
        })
        setTopInstallmentDetail([])
      }else if(event.target.value === "DownPayment" || event.target.value === "COD" || event.target.value === "CBD" ){
        setData({
          ...data,
          TopType:event.target.value,
          TopDueDate:dueDate,
          TopInstallment:0,
          TopInstallmentDetail:[],
        })
        setTopInstallmentDetail([])
      }
    }
    else if(event.target.name === "whtType"){
      const dataItemTemp = dataItemType
      const selected = dataItemTemp.findIndex((row)=>row.id === dataSelect.id)
      dataItemTemp[selected].itemTypeWhtType=event.target.value

      // mengambil data existing
      let findisCheckBoxTrue =dataItemTemp.filter((d)=>d.isCheckBox === true)
      let dataTempfindisCheckBoxTrue=[]
      if (findisCheckBoxTrue.length >= 1) {
        findisCheckBoxTrue.forEach((d)=>{
          dataTempfindisCheckBoxTrue.push({
            itemTypeNo: d.itemTypeNo,
            itemTypeDesc: d.itemTypeDesc,
            itemTypeAmount: Number(d.itemTypeAmount),
            itemTypeVat: d.itemTypeVat,
            itemTypeVatRate: d.itemTypeVatRate,
            itemTypeVatAmount: Number(d.itemTypeVatAmount),
            itemTypeDppRate: d.itemTypeDppRate,
            itemTypeDppAmount: Number(d.itemTypeDppAmount),
            itemTypeWht: d.itemTypeWht,
            itemTypeWhtType: d.itemTypeWhtType,
            itemTypeWhtSubtype: d.itemTypeWhtSubtype,
            itemTypeWhtRate: Number(d.itemTypeWhtRate),
            itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
            itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
            })
        })
      }

      setData({
        ...data,
        selectedItemType:dataTempfindisCheckBoxTrue
      })

      setItemType(dataItemTemp)

      let subTypeTemp = dropdownWht.filter((d,index)=>{
        return d.companyCode === data.companyCode && d.taxType === dataItemTemp[selected].itemTypeWhtType
      });
      if (subTypeTemp.length >= 1) {
        setWhtSubType(subTypeTemp);
      }
    }
    else if(event.target.name === "VendorInvoiceNumber"){
      let dataTemp = attachSupportDoc
      dataTemp[0].documentNo=event.target.value
      setAttachSupportDoc(dataTemp)
    }else if(event.target.name === "BankAccountNo"){
      const dataBank = listBankAccount.filter((d)=>{
        return d.bankCode === data.BankName && d.bankAccount === event.target.value
      })
      setData({
        ...data,
        BankAccountName:dataBank[0].bankAccountName
      })
    }else if(event.target.name === "TopPercentage"){
      let amount = (event.target.value/100) * Number(data.TotalAmountBeforeTax) 
      if (amount >data.TotalAmountBeforeTax ) {
        setData({
          ...data,
          TopPercentage: data.TopType === "DownPayment" ? event.target.value > 99 ? 99 : event.target.value : event.target.value > 100 ? 100 : event.target.value,
          TopAmount:amount.toFixed(2),
          TopPercentageCondition:true
        })
      }else{
        setData({
          ...data,
          TopPercentage: data.TopType === "DownPayment" ? event.target.value > 99 ? 99 : event.target.value : event.target.value > 100 ? 100 : event.target.value,
          TopAmount:amount.toFixed(2),
          TopPercentageCondition:false
        })
      }
    }
  };

  const handleChangeBilledQty =(e,dataSelect)=>{
      const dataNew =data.ItemList;
      const datatemp =data.ItemListtemp;

      const selected = datatemp.findIndex((row)=>row.id === dataSelect.id)
      const balanceQtytemp = datatemp[selected].balanceQty
      const value = Number(e.target.value)
      const newArr = dataNew.map((obj) => {
        if (obj.item === dataSelect.item && obj.poNo === dataSelect.poNo) {
          // mengambil dari balance qty
          if (value > balanceQtytemp) {
            if(obj.currency === "IDR"){
              return { ...obj, billedQty: balanceQtytemp,balanceQty: balanceQtytemp,amountInvoice:Math.ceil(balanceQtytemp*obj.pricePerUnit) };
            }else{
              return { ...obj, billedQty: balanceQtytemp,balanceQty: balanceQtytemp,amountInvoice:(balanceQtytemp*obj.pricePerUnit).toFixed(2) };
            }
            
          } else {
            if(obj.currency === "IDR"){
              return { ...obj, billedQty: value,balanceQty: value,amountInvoice:Math.ceil(value*obj.pricePerUnit) };
            }else{
              return { ...obj, billedQty: value,balanceQty: value,amountInvoice:(value*obj.pricePerUnit).toFixed(2) };
            }
          }
        }
        return obj;
      });



      let beforeTaxTemp=[]
      let dataSelectedItemList=data.selectedItemList
      let beforeTax;
      
      if (dataSelectedItemList !== null && dataSelectedItemList !== 0) {
        let filterData = newArr.filter((d)=>{
         return dataSelectedItemList.find((itm)=>{
           return itm.conditionId === d.conditionId
         })
        })
        if (filterData.length >= 1) {
          filterData.forEach((d)=>{
            beforeTaxTemp.push(Number(d.amountInvoice))
          })
          if(currency !== null || currency !== undefined){
            if (currency[0] === "IDR") {
              beforeTax = beforeTaxTemp.reduce((a, b) => a + b, 0)
            }else{
              beforeTax = beforeTaxTemp.reduce((a, b) => a + b, 0).toFixed(2)
            }
          }
        }
      }
     
      
      // logic untuk checklist itemlist duluan
      let tempSelectedItemList = [];
      if(data.selectedItemList.length > 0){
        const filterNewArrTrue = newArr.filter((d)=>d.isCheckBox === true)
        if (filterNewArrTrue.length > 0) {
          filterNewArrTrue.forEach((d)=>{
            tempSelectedItemList.push({
              amountInvoice: Number(d.amountInvoice),
              item: d.item,
              billedQty: parseInt(d.balanceQty),
              conditionId: d.conditionId,
              poNo: d.poNo,
            });
          })
        }
      }

      setData({
        ...data,
        selectedItemList: data.selectedItemList.length > 0 ? tempSelectedItemList : [],
        TotalAmountBeforeTax:dataSelectedItemList.length >= 1 ? beforeTax:0,
        ItemList: newArr,
      });
      
   
  }


  const handleChangeItemType=(e,dataSelect)=>{
      let valueItemType = e.target.name === "itemTypeAmount" ? RemoveFormatting(e.target.value) :e.target.value
      let ammountTemp =[]
      let ammountToBePaid =[]
      const dataNew =dataItemType;
      const selected = dataNew.findIndex((row) => row.id === dataSelect.id)
      dataNew[selected][e.target.name]= Number(valueItemType)
      const {vat,wht}=dataSelect
      let dataAmountNew = dataNew[selected].itemTypeAmount;
      if(e.target.name === "itemTypeDppAmount")
      {
        dataNew[selected].itemTypeDppAmount = e.target.value;
        if(dataNew[selected].itemTypeVatRate !== 0 || dataNew[selected].itemTypeVatRate !== null){
          dataNew[selected].itemTypeVatAmount = dataNew[selected].itemTypeVatRate /100 * dataNew[selected].itemTypeDppAmount
        }
      }
      if(e.target.name === "itemTypeAmount"){
        if(vat === 1){
          // dataNew[selected].itemTypeDppAmount = dataNew[selected].itemTypeAmount * dataNew[selected].itemTypeDppRate / 100;
          dataNew[selected].itemTypeVatAmount = dataNew[selected].itemTypeDppAmount * dataNew[selected].itemTypeVatRate / 100;
        }else if(vat === 1 && dataNew[selected].itemTypeWht === 1){
          dataNew[selected].itemTypeWhtAmountToBePaid = dataNew[selected].itemTypeWhtAmountToBePaid - dataNew[selected].itemTypeWhtAmount;
        }
      }

      if(vat ===1){
        if (dataNew[selected].itemTypeVat !== null && dataNew[selected].itemTypeVat !== 0) {
          if(currency !== null&& currency !== undefined){

            // if(currency[0] === "IDR"){
            //   dataNew[selected].itemTypeVatAmount = Math.ceil((dataNew[selected].itemTypeVatRate / 100) * dataNew[selected].itemTypeAmount)
            // }else{
            //   dataNew[selected].itemTypeVatAmount = ((dataNew[selected].itemTypeVatRate / 100) * dataNew[selected].itemTypeAmount).toFixed(2)
            // }
          }else{
            // dataNew[selected].itemTypeVatAmount = ((dataNew[selected].itemTypeVatRate / 100) * dataNew[selected].itemTypeAmount).toFixed(2)
          }
        } else {
          dataNew[selected].itemTypeWhtAmountToBePaid = dataNew[selected].itemTypeAmount
        }
      }else{
        dataNew[selected].itemTypeWhtAmountToBePaid = dataNew[selected].itemTypeAmount
      }

      if(wht ===1 && vat ===1){
        let whtRate=dataNew[selected].itemTypeWhtRate/100
        dataNew[selected].itemTypeWhtAmount=(whtRate*Number(dataNew[selected].itemTypeAmount)).toFixed(2)
        if(currency !== null&& currency !== undefined){
          if(currency[0] === "IDR"){
            dataNew[selected].itemTypeWhtAmountToBePaid=Math.ceil((dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount))) 
          }else{
            dataNew[selected].itemTypeWhtAmountToBePaid=(dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount)).toFixed(2)
          }
        }else{
          dataNew[selected].itemTypeWhtAmountToBePaid=(dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount)).toFixed(2)
        }
      }else if(wht ===0 && vat ===1){
        if (dataNew[selected].itemTypeVatAmount !== null && parseInt(dataNew[selected].itemTypeVatAmount) !== 0) {
          dataNew[selected].itemTypeWhtAmountToBePaid = (Number(dataNew[selected].itemTypeVatAmount)+dataNew[selected].itemTypeAmount).toFixed(2)
        }
      }


      if(e.target.name === "itemTypeWhtRate"){
        dataNew[selected][e.target.name]= Number(valueItemType)
        if(currency !== null&& currency !== undefined){
          if (currency[0] === "IDR") {
            dataNew[selected].itemTypeWhtAmount=Math.ceil((Number(valueItemType/100)*dataNew[selected].itemTypeAmount))
          }else{
            dataNew[selected].itemTypeWhtAmount=(Number(valueItemType/100)*dataNew[selected].itemTypeAmount).toFixed(2)
          }
        }else{
          dataNew[selected].itemTypeWhtAmount=(Number(valueItemType/100)*dataNew[selected].itemTypeAmount).toFixed(2)
        }
        dataNew[selected].itemTypeWhtAmountToBePaid=(dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount)).toFixed(2)
      }
      if(e.target.name === "itemTypeTaxConfId"){
        if(currency !== null&& currency !== undefined){
          if (currency[0] === "IDR") {
            let taxs = handleTaxConfig(e.target.value, dataAmountNew)
            dataNew[selected].itemTypeVatRate = Math.round(taxs.vatRate)
            dataNew[selected].itemTypeVatAmount = Math.round(dataNew[selected].itemTypeDppAmount * taxs.vatRate / 100)
          }
        }else{
          dataNew[selected].itemTypeWhtAmount=(Number(valueItemType/100)*dataNew[selected].itemTypeAmount).toFixed(2)
        }
        dataNew[selected].itemTypeWhtAmountToBePaid=dataNew[selected].itemTypeAmount+Number(dataNew[selected].itemTypeVatAmount)-Number(dataNew[selected].itemTypeWhtAmount).toFixed(2)
      }

      // amount to be paid
      // perbandingan material & services = amount before tax
      let filterSelected = dataNew.filter(function(o1){
        return data.selectedItemType.some(function(o2){
            return o1.itemTypeDesc === o2.itemTypeDesc && o1.itemTypeAmount !== 0;
        });
      })
      const filterMaterialService = filterSelected.filter((d)=>d.itemTypeDesc=== "Services" || d.itemTypeDesc=== "Material")
      let updateCheckBoxtrue = checkedItemType.filter(function(o1){
        return filterSelected.some(function(o2){
            return o1 === o2.id  ;
        });
      })
      setCheckedItemType(updateCheckBoxtrue)

      filterMaterialService.forEach((data)=>ammountTemp.push(data.itemTypeAmount))
      filterSelected.forEach((data)=>ammountToBePaid.push(Number(data.itemTypeWhtAmountToBePaid)))

      let amount = ammountTemp.slice(0, 2).reduce((a, b) => a + b, 0)
      let ammountToBePaidTemp = Number(ammountToBePaid.slice(0, ammountToBePaid.length).reduce((a, b) => a + b, 0))
      
      let selectedTemp =[]
      filterSelected.forEach((d)=>{
        selectedTemp.push({
          itemTypeNo: d.itemTypeNo,
          itemTypeDesc: d.itemTypeDesc,
          itemTypeAmount: Number(d.itemTypeAmount),
          itemTypeVat: d.itemTypeVat,
          itemTypeVatRate: d.itemTypeVatRate,
          itemTypeVatAmount: Number(d.itemTypeVatAmount),
          itemTypeDppRate: d.itemTypeDppRate,
          itemTypeDppAmount: Number(d.itemTypeDppAmount),
          itemTypeWht: d.itemTypeWht,
          itemTypeWhtType: d.itemTypeWhtType,
          itemTypeWhtSubtype: d.itemTypeWhtSubtype,
          itemTypeWhtRate: Number(d.itemTypeWhtRate),
          itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
          itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
        });
      })

      if(data.PaymentType === "Credit Payment"){
        let amountBeforeTax=Number(data.TotalAmountBeforeTax).toFixed(2)
        if (Number(amount).toFixed(2) !== amountBeforeTax ) {
          setData({
            ...data,
            TotalAmountItemType:true,
            TotalAmountServiceMaterial:amount,
            TotalAmountToBePaid:ammountToBePaidTemp,
            selectedItemType:selectedTemp
          })
        }else{
          setData({
            ...data,
            TotalAmountItemType:false,
            TotalAmountServiceMaterial:amount,
            TotalAmountToBePaid:ammountToBePaidTemp,
            selectedItemType:selectedTemp
          })
        }
      }else if(data.PaymentType === "Advanced Payment"){
        let amountBeforeTax=Number(data.TopAmount*100/data.TopPercentage).toFixed(2)
        if (Number(amount).toFixed(2) !== Number(data.TopAmount).toFixed(2) ) {
          setData({
            ...data,
            TotalAmountItemType:true,
            TotalAmountServiceMaterial:amount,
            TopAmount:ammountToBePaidTemp,
            TotalAmountBeforeTax:amountBeforeTax,
            selectedItemType:selectedTemp
          })
        }else{
          setData({
            ...data,
            TotalAmountItemType:false,
            TotalAmountServiceMaterial:amount,
            TopAmount:ammountToBePaidTemp,
            TotalAmountBeforeTax:amountBeforeTax,
            selectedItemType:selectedTemp
          })
        }
      }
      
      setItemType(dataNew)
  }
  const handleCheckBox =(e,dataSelect)=>{
    
    const dataItemTemp = dataItemType
    let ammountToBePaid=[]
    const selected = dataItemTemp.findIndex((row) => row.id === dataSelect.id)
    if (e.target.name === "itemTypeVat") {
      dataItemTemp[selected].checkVat=!dataSelect.checkVat
      if(dataSelect.vat === 1 && dataSelect.wht === 0 ){
        if (dataSelect.checkVat) {
          // dataItemTemp[selected].itemTypeVatRate=parseInt(listSysparam[0].value)
          dataItemTemp[selected].itemTypeVatRate=dataItemType[selected].itemTypeVatRate;
          dataItemTemp[selected].itemTypeVat=1
          dataItemTemp[selected].itemTypeWhtAmountToBePaid = dataItemType[selected].itemTypeAmount;
          if(currency !== null&& currency !== undefined){
            if(currency[0] === "IDR"){
              dataItemTemp[selected].itemTypeVatAmount=Math.round(((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount))
              dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount)
            }else{
              dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
              dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount).toFixed(2)
            }
          }else{
            dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
              dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount).toFixed(2)
          }
        }else{
          dataItemTemp[selected].itemTypeVat=0
          dataItemTemp[selected].itemTypeVatRate=0
          dataItemTemp[selected].itemTypeVatAmount=0
          dataItemTemp[selected].itemTypeWhtAmountToBePaid=dataItemTemp[selected].itemTypeAmount
        }
      }else if(dataSelect.vat === 1 && dataSelect.wht === 1){
        if (dataSelect.checkVat) {
          dataItemTemp[selected].itemTypeVatRate=parseInt(listSysparam[0].value)
          dataItemTemp[selected].itemTypeVat=1
          if(currency !== null&& currency !== undefined){
            if(currency[0] === "IDR"){
              dataItemTemp[selected].itemTypeVatAmount=Math.ceil(((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount))
            }else{
              dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
            }
            dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount)-dataItemTemp[selected].itemTypeWhtAmount
          }else{
            dataItemTemp[selected].itemTypeVatAmount=((dataItemTemp[selected].itemTypeVatRate/100)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
            dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeVatAmount)+dataItemTemp[selected].itemTypeAmount)-dataItemTemp[selected].itemTypeWhtAmount.toFixed(2)
          }
        }else{
          dataItemTemp[selected].itemTypeVat=0
          dataItemTemp[selected].itemTypeVatRate=0
          dataItemTemp[selected].itemTypeVatAmount=0
          dataItemTemp[selected].itemTypeDppRate=0
          dataItemTemp[selected].itemTypeDppAmount=0
          dataItemTemp[selected].itemTypeWhtAmountToBePaid=(Number(dataItemTemp[selected].itemTypeAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)).toFixed(2)
          // dataItemTemp[selected].itemTypeWhtAmountToBePaid=(dataItemTemp[selected].itemTypeAmount+dataItemTemp[selected].itemTypeWhtAmount)
        }
      }
    }
  
    else if(e.target.name === "itemTypeWht"){
      dataItemTemp[selected].checkWht=!dataSelect.checkWht
      // setwhtservice(!whtservice)
      if (dataSelect.checkWht) {
        dataItemTemp[selected].itemTypeWht = 1
        dataItemTemp[selected].itemTypeWhtAmountToBePaid = dataItemTemp[selected].itemTypeVatAmount + dataItemTemp[selected].itemTypeAmount - dataItemTemp[selected].itemTypeWhtAmount
      }else{
        dataItemTemp[selected].itemTypeWhtAmountToBePaid = dataItemTemp[selected].itemTypeAmount + dataItemTemp[selected].itemTypeVatAmount
        dataItemTemp[selected].itemTypeWht = 0
        dataItemTemp[selected].itemTypeWhtType = "---"
        dataItemTemp[selected].itemTypeWhtSubtype = "---"
        dataItemTemp[selected].itemTypeWhtRate = 0
        dataItemTemp[selected].itemTypeWhtAmount = 0
      }
    }
    dataItemTemp.forEach((data,index)=>{
      ammountToBePaid.push(Number(data.itemTypeWhtAmountToBePaid))
    })
    console.log(ammountToBePaid)
    let ammountToBePaidTemp = Number(ammountToBePaid.slice(0, ammountToBePaid.length).reduce((a, b) => a + b, 0))
    

    // mengambil data selected (isCheckbox true) dan ada perubahan data
    let findisCheckBoxTrue =dataItemTemp.filter((d)=>d.isCheckBox === true)
    let dataTempfindisCheckBoxTrue=[]
    if (findisCheckBoxTrue.length >= 1) {
      findisCheckBoxTrue.forEach((d)=>{
        dataTempfindisCheckBoxTrue.push({
          itemTypeNo: d.itemTypeNo,
          itemTypeDesc: d.itemTypeDesc,
          itemTypeAmount: Number(d.itemTypeAmount),
          itemTypeVat: d.itemTypeVat,
          itemTypeVatRate: d.itemTypeVatRate,
          itemTypeVatAmount: Number(d.itemTypeVatAmount),
          itemTypeDppRate: d.itemTypeDppRate,
          itemTypeDppAmount: Number(d.itemTypeDppAmount),
          itemTypeWht: d.itemTypeWht,
          itemTypeWhtType: d.itemTypeWhtType,
          itemTypeWhtSubtype: d.itemTypeWhtSubtype,
          itemTypeWhtRate: Number(d.itemTypeWhtRate),
          itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
          itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
          })
      })
    }
    setData({
      ...data,
      selectedItemType:dataTempfindisCheckBoxTrue,
      TotalAmountToBePaid:ammountToBePaidTemp
    })
      setItemType(dataItemTemp)
  }

  const theColumns = [
    {
      field: " ",
      editable: false,
      filter: false,
      width: 100,
      renderHeader: () => {
        let showChkAllListPO = detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? true:false;
        if(showChkAllListPO){
          return <Checkbox
            sx={{color:"#FFFFFF"}}
            checked={checkAllListPO}
            indeterminate={indetListPO}
            onChange={(e) => {
              setCheckAllListPO(e.target.checked);
              if(e.target.checked){
                let newArray = [];

                data.ListPO.forEach((dataPo) => {
                  if(dataPo.isVisible){
                    newArray.push(dataPo["id"]);
                    setIndetListPO(true);
                  }
                })

                setCheckedListPO(newArray);
              }else{
                setCheckedListPO([]);
                setIndetListPO(false);
              }
            }}
            id="chk-all-list-po"
          />;
        }
      },
      renderCell: (cellValues) => {
        let showChkAllListPO = detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? true:false;
        if(showChkAllListPO){
          return <Checkbox 
            sx={{marginLeft:'5px'}}            
            checked={checkedListPO.includes(cellValues.row.id) ? true : false}
            disabled={cellValues.row.isVisible ? false : true}
            id={"chk" + cellValues.row.id}
            onChange={(e) => {
              if(e.target.checked){
                let tempCheckedListPO = [...checkedListPO];
                tempCheckedListPO.push(cellValues.row.id);
                setCheckedListPO(tempCheckedListPO);
              }else{
                let tempCheckedListPO = [...checkedListPO];
                let foundID = tempCheckedListPO.find(p => p === cellValues.row.id);

                if(foundID !== undefined){
                  tempCheckedListPO = tempCheckedListPO.filter(p => p !== foundID);
                }

                setCheckedListPO(tempCheckedListPO);
              }
            }}
          />;
        }
      },
    },
    {
      field: "poNo",
      headerName: "PO Number",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "costCenter",
      headerName: "Cost Center",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "createBy",
      headerName: "SAP Created By",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "paymentTerm",
      headerName: "Payment Term",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "paymentIn",
      headerName: "Payment In",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "companyCode",
      headerName: "Company Code",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "poType",
      headerName: "PO Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        switch (cellValues.row.poType) {
          case 0:return <p>Non Trade</p>
          case 1:return <p>Trade</p>
          default:
            return null
        }
      },
    },
    {
      field: "purchaseOrganization",
      headerName: "Purchasing Org",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "purchaseGroup",
      headerName: "Purch. Group",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "exchangeRate",
      headerName: "Exchange rate",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "incoterms",
      headerName: "Incoterms",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "incotermsDesc",
      headerName: "Incoterms Desc",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 160,
    },
  ];

  const columnItemList = [
    {
      field: " ",
      editable: false,
      filter: false,
      width: 80,
      renderHeader: () => {
        let showChkAllItemList = detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? true:false;
        if(showChkAllItemList){
          return <Checkbox
            sx={{
              color: "#FFFFFF"
            }}
            checked={checkAllItemList}
            onChange={(e) => {
              setCheckAllItemList(e.target.checked);
              if(e.target.checked){
                let newArray = [];

                data.ItemList.forEach((dataPo) => {
                  newArray.push(dataPo["id"]);
                })

                setCheckedItemList(newArray);
              }else{
                setCheckedItemList([]);
              }
            }}
            id="chk-all-item-list"
          />;
        }
      },
      renderCell: (cellValues) => {
        let showChkAllItemList = detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? true:false;
        if(showChkAllItemList){
          return <Checkbox 
            sx={{marginLeft:"5px"}}
            checked={checkedItemList.includes(cellValues.row.id) ? true : false}
            id={"chk" + cellValues.row.id}
            onChange={(e) => {
              if(e.target.checked){
                let tempCheckedItemList = [...checkedItemList];
                tempCheckedItemList.push(cellValues.row.id);
                setCheckedItemList(tempCheckedItemList);
              }else{
                let tempCheckedItemList = [...checkedItemList];
                let foundID = tempCheckedItemList.find(p => p === cellValues.row.id);

                if(foundID !== undefined){
                  tempCheckedItemList = tempCheckedItemList.filter(p => p !== foundID);
                }

                setCheckedItemList(tempCheckedItemList);
              }
            }}
          />;
        }
      },
    },
    {
      field: "poNo",
      headerName: "PO Number",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "item",
      headerName: "Item",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 60,
    },
    {
      field: "awbInvoiceNo",
      headerName: "AWB/INV NO",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
      renderCell:(cellValues)=>{
        switch (cellValues.row.awbInvoiceNo) {
          case "NULL" : return ""
          default:
            return cellValues.row.awbInvoiceNo
        }
      }
    },
    {
      field: "material",
      headerName: "Material",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
    },
    {
      field: "shortText",
      headerName: "Short Text (Item)",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 160,
    },
    {
      field: "condition",
      headerName: "CnTy",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "conditionDesc",
      headerName: "Cnty Desc",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "quantity",
      headerName: "Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 60,
    },
    {
      field: "currency",
      headerName: "Curr",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 60,
    },
    {
      field: "netValue",
      headerName: "Net Value",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        if(cellValues.row.netValue !== null && cellValues.row.netValue !== undefined){
          return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.netValue} />
        }
      },
    },
    {
      field: "itemStatus",
      headerName: "Item Status",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
      renderCell:(cellValues)=>{
        switch (cellValues.row.itemStatus) {
          case "NULL" : return ""
          default:
            return cellValues.row.itemStatus
        }
      }
    },
    {
      field: "grStatus",
      headerName: "GR Status",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "billedQty",
      headerName: "Billed Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      type: 'number',
      width: 90,
      renderCell: (cellValues) => {
        return (
          <input
          disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
            value={cellValues.row.billedQty}
            onChange={(e)=>handleChangeBilledQty(e,cellValues.row)}
            className="input-BilledQty"
            id="billedQty"
            name="billedQty"
            type="number"
            onPaste={preventPasteNegative}
            onKeyPress={preventMinus}
          />
        );
      },
    },
    {
      field: "amountInvoice",
      headerName: "Amount Inv.",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
      disableExport:true
    },
  ];
  
  const columnItemType = [
    {
      field: " ",
      editable: false,
      filter: false,
      width: 100,
      renderHeader: () => {
        let showChkAllItemType = detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? true:false;
        if(showChkAllItemType){
          let disableChkAllItemType = true;
          dataItemType.forEach((dataPo) => {
            if(dataPo.itemTypeAmount !== 0){
              disableChkAllItemType = false;
            }
          })
          return <Checkbox
            sx={{color:"#FFFFFF"}}
            checked={checkAllItemType}
            disabled={disableChkAllItemType}
            onChange={(e) => {
              setCheckAllItemType(e.target.checked);
              if(e.target.checked){
                let newArray = [];

                dataItemType.forEach((dataPo) => {
                  newArray.push(dataPo["id"]);
                })

                setCheckedItemType(newArray);
              }else{
                setCheckedItemType([]);
              }
            }}
            id="chk-all-item-type"
          />;
        }
      },
      renderCell: (cellValues) => {
        let showChkAllItemType = detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? true:false;
        if(showChkAllItemType){
          return <Checkbox 
            sx={{marginLeft:"5px"}}
            checked={checkedItemType.includes(cellValues.row.id) ? true : false}
            disabled={cellValues.row.itemTypeAmount === 0 || cellValues.row.itemTypeAmount === "" || cellValues.row.itemTypeAmount === null ? true : false}
            id={"chk" + cellValues.row.id}
            onChange={(e) => {
              if(e.target.checked){
                let tempCheckedItemType = [...checkedItemType];
                tempCheckedItemType.push(cellValues.row.id);
                setCheckedItemType(tempCheckedItemType);
              }else{
                cellValues.row.itemTypeVatRate = 0;
                cellValues.row.itemTypeVatAmount = 0;
                cellValues.row.itemTypeDppRate = 0;
                cellValues.row.itemTypeDppAmount = 0;
                let tempCheckedItemType = [...checkedItemType];
                let foundID = tempCheckedItemType.find(p => p === cellValues.row.id);

                if(foundID !== undefined){
                  tempCheckedItemType = tempCheckedItemType.filter(p => p !== foundID);
                }

                setCheckedItemType(tempCheckedItemType);
              }
            }}
          />;
        }
      },
    },
    {
      field: "itemTypeDesc",
      headerName: "Item Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
    },
    {
      field: "itemTypeAmount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        return (
          <TextField
            disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
            onChange={(e) => handleChangeItemType(e, cellValues.row)}
            margin="none"
            InputProps={{
                inputComponent: NumberFormatCustom,
                className:data.TotalAmountItemType === true ? "input-amountType-warning" :"input-amountType"
            }}
            name="itemTypeAmount"
            size="small"
            value={cellValues.row.itemTypeAmount}
        />
        );
      },
    },
    {
      field: "itemTypeVat",
      headerName: "VAT",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 80,
      renderCell: (cellValues) => {
        switch (cellValues.row.vat) {
          case 1:return <Checkbox name="itemTypeVat" disabled={(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4) && (cellValues.row.itemTypeAmount !== 0 || cellValues.row.itemTypeAmount !== null) ? false:true} checked={cellValues.row.checkVat} onChange={(e)=>handleCheckBox(e,cellValues.row)} />
          default:
            return ''
        }
      },
    },
    {
      field: "itemTypeDppAmount",
      headerName: "Dpp Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        return (
        //   <TextField
        //     disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
        //     onChange={(e) => handleChangeItemType(e, cellValues.row)}
        //     margin="none"
        //     InputProps={{
        //         inputComponent: NumberFormatCustom,
        //         className:data.TotalAmountItemType === true ? "input-amountType-warning" :"input-amountType"
        //     }}
        //     name="itemTypeDppAmount"
        //     size="small"
        //     value={cellValues.row.itemTypeDppAmount}
        // />
        <input
              // disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:data.TaxType === "nonTaxable" || data.TaxType === ""? true:true}
              // disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:data.TaxType === "nonTaxable" || data.TaxType === ""? true:false}
              value={cellValues.row.itemTypeDppAmount}
              onChange={(e)=>handleChangeItemType(e,cellValues.row)}
                className="input-BilledQty"
                id="itemTypeDppAmount"
                name="itemTypeDppAmount"
                type="number"
                onPaste={preventPasteNegative}
                onKeyPress={preventMinus}
              />
        );
      },
    },
    {
      field: "itemListVatRate",
      headerName: "VAT Rate",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 200,
      renderCell: (cellValues) => {
        switch (cellValues.row.vat) {
          case 1:
            return (
              <select disabled={!cellValues.row.checkVat} name="itemTypeTaxConfId" className="form-select w-100" 
              onChange={(e)=>{
                // let taxs = handleTaxConfig(e.target.value, cellValues.row.itemTypeAmount)
                // cellValues.row.itemTypeVatAmount = taxs.resVat
                // cellValues.row.itemTypeDppAmount = taxs.resDpp
                // cellValues.row.itemTypeVatRate = taxs.vatRate
                // cellValues.row.itemTypeDppRate = taxs.dppRate
                // cellValues.row.itemTypeTaxConfId = e.target.value
                handleChangeItemType(e,cellValues.row)
              }}
              id={"taxConf-"+cellValues.row.id}
              >
                  {dropdownTaxConfig.map((status, key) => (
                    <option key={"taxConfId-" + key} value={status.value} selected={status.value === cellValues.row.itemTypeTaxConfId ? true : false}>
                      {status.label}
                    </option>
                  ))}
                </select>
            )
          default:
            return null
        }
      },
    },
    // {
    //   field: "itemTypeDppAmount",
    //   headerName: "DPP Amount",
    //   headerClassName: "super-app-theme--header",
    //   editable: false,
    //   width: 160,
    //   renderCell: (cellValues) => {
    //     switch (cellValues.row.vat ) {
    //       case 1:
    //       // return <p>{cellValues.row.itemTypeAmount !== null && cellValues.row.itemTypeDppRate !== null ?NumberFormatCustom(cellValues.row.itemTypeDppAmount):null}</p>
    //       return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.itemTypeDppAmount ?? null} />
    //       default:
    //         return '';
    //     }
    //   },
    // },
    {
      field: "itemTypeVatAmount",
      headerName: "VAT Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 160,
      renderCell: (cellValues) => {
        switch (cellValues.row.vat ) {
          case 1:
          return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.itemTypeAmount !== null && cellValues.row.itemTypeVatRate !== null ? cellValues.row.itemTypeVatAmount:null}/>
          default:
            return '';
        }
      },
    },
    {
      field: "itemTypeWht",
      headerName: "Wht",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 80,
      renderCell: (cellValues) => {
        switch (cellValues.row.wht) {
          case 1:return <Checkbox disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:data.TaxType === "nonTaxable" || data.TaxType === ""? true:true} name="itemTypeWht" checked={cellValues.row.checkWht} onChange={(e)=>handleCheckBox(e,cellValues.row)}/>
          default:
            return ''
        }
      },
      
    },
    {
      field: "whtType",
      headerName: "Wht Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell: (cellValues) => {
        switch (cellValues.row.wht) {
          case 1:
            return (
              <select defaultValue={cellValues.row.itemTypeWhtType} value={cellValues.row.itemTypeWhtType} disabled={(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4) && cellValues.row.checkWht === true ? false:true} name="whtType" className="select-item-Type" onChange={(e)=>handleFormChange(e,cellValues.row)}>
                
                  {dropdownWhtType.map((status, key) => (
                    <option key={"whttype-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
            )
          default:
            return ''
        }
      },
    },
    {
      field: "whtSubType",
      headerName: "Wht Sub Type",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 300,
      renderCell: (cellValues) => {
        let dropDownWhtTemp = dropdownWht.filter((d)=>{
          return d.companyCode === data.companyCode && d.taxType === cellValues.row.itemTypeWhtType
        });
        if(dropDownWhtTemp.length >= 1){
          dropDownWhtTemp.forEach((d)=>{
            d.label=d.serviceType
          })
        }
        
        switch (cellValues.row.wht) {
          case 1:
            return (
              <Autocomplete
                        disablePortal
                        onChange={(e,newValue)=>{
                          if (newValue !== null) {
                            const dataItemTemp = dataItemType
                            const selected = dataItemTemp.findIndex((row)=>row.id === cellValues.row.id)
                            let ammountToBePaid=[]
                            let whtRate = null
                            let whtRateFilter = dropdownWht.filter((d)=>{
                              return d.companyCode === data.companyCode && d.taxType === cellValues.row.itemTypeWhtType && d.serviceType === newValue.serviceType
                            });
                           
                            if (whtRateFilter.length >= 1 ) {
                              console.log(whtRateFilter);
                              let vatRegistration = listInfoVendor[0].vatRegistration
                              console.log(vatRegistration);
                              if (vatRegistration !== null && vatRegistration !== "" ) {
                                whtRate=whtRateFilter[0].npwpRate
                              }else{
                                whtRate=whtRateFilter[0].nonNpwpRate
                              }
                            }
                            dataItemTemp[selected].itemTypeWhtSubtype=newValue.serviceType
                            dataItemTemp[selected].itemTypeWhtRate=Number(whtRate*100)
                            
                            if (currency !== null && currency !== undefined) {
                              if (currency[0] === "IDR") {
                              dataItemTemp[selected].itemTypeWhtAmount=Math.ceil((Number(whtRate)*dataItemTemp[selected].itemTypeAmount))
                              dataItemTemp[selected].itemTypeWhtAmountToBePaid=Math.ceil(dataItemTemp[selected].itemTypeAmount+Number(dataItemTemp[selected].itemTypeVatAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)) 

                              } else {
                              dataItemTemp[selected].itemTypeWhtAmount=(Number(whtRate)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
                              dataItemTemp[selected].itemTypeWhtAmountToBePaid=(dataItemTemp[selected].itemTypeAmount+Number(dataItemTemp[selected].itemTypeVatAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)).toFixed(2)

                              }
                            }else{
                              dataItemTemp[selected].itemTypeWhtAmount=(Number(whtRate)*dataItemTemp[selected].itemTypeAmount).toFixed(2)
                              dataItemTemp[selected].itemTypeWhtAmountToBePaid=(dataItemTemp[selected].itemTypeAmount+Number(dataItemTemp[selected].itemTypeVatAmount)-Number(dataItemTemp[selected].itemTypeWhtAmount)).toFixed(2)
                            }


                            let filterSelected = dataItemTemp.filter(function(o1){
                              return data.selectedItemType.some(function(o2){
                                  return o1.description === o2.itemTypeDesc;
                              });
                            })
                            filterSelected.forEach((data)=>ammountToBePaid.push(Number(data.itemTypeWhtAmountToBePaid)))
                            let ammountToBePaidTemp = Number(ammountToBePaid.slice(0, 2).reduce((a, b) => a + b, 0))
                            const selectedTemp =[]
                            filterSelected.forEach((d)=>{
                              selectedTemp.push({
                                itemTypeNo: d.seq,
                                itemTypeDesc: d.description,
                                itemTypeAmount: Number(d.itemTypeAmount),
                                itemTypeVat: d.itemTypeVat,
                                itemTypeVatRate: d.itemTypeVatRate,
                                itemTypeVatAmount: Number(d.itemTypeVatAmount),
                                itemTypeDppRate: d.itemTypeDppRate,
                                itemTypeDppAmount: Number(d.itemTypeDppAmount),
                                itemTypeTaxConfId: d.itemTypeTaxConfId === null ? "" : d.itemTypeTaxConfId,
                                itemTypeWht: d.itemTypeWht,
                                itemTypeWhtType: d.itemTypeWhtType,
                                itemTypeWhtSubtype: d.itemTypeWhtSubtype,
                                itemTypeWhtRate: Number(d.itemTypeWhtRate),
                                itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
                                itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
                              });
                            })
                            


                            // mengambil data existing
                            let findisCheckBoxTrue =dataItemTemp.filter((d)=>d.isCheckBox === true)
                            console.log(findisCheckBoxTrue);
                            let dataTempfindisCheckBoxTrue=[];
                            let total = 0;
                            if (findisCheckBoxTrue.length >= 1) {
                              findisCheckBoxTrue.forEach((d)=>{
                                dataTempfindisCheckBoxTrue.push({
                                  itemTypeNo: d.itemTypeNo,
                                  itemTypeDesc: d.itemTypeDesc,
                                  itemTypeAmount: Number(d.itemTypeAmount),
                                  itemTypeVat: d.itemTypeVat,
                                  itemTypeVatRate: d.itemTypeVatRate,
                                  itemTypeVatAmount: Number(d.itemTypeVatAmount),
                                  itemTypeDppRate: d.itemTypeDppRate,
                                  itemTypeDppAmount: Number(d.itemTypeDppAmount),
                                  itemTypeTaxConfId: d.itemTypeTaxConfId === null ? "" : d.itemTypeTaxConfId,
                                  itemTypeWht: d.itemTypeWht,
                                  itemTypeWhtType: d.itemTypeWhtType,
                                  itemTypeWhtSubtype: d.itemTypeWhtSubtype,
                                  itemTypeWhtRate: Number(d.itemTypeWhtRate),
                                  itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
                                  itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
                                })
                                total += Number(d.itemTypeWhtAmountToBePaid)
                              })
                            }
                            // console.log(total)
                            ammountToBePaidTemp = total;
                            setData({
                              ...data,
                              selectedItemType:findisCheckBoxTrue.length >= 1 ? dataTempfindisCheckBoxTrue:selectedTemp,
                              TotalAmountToBePaid:ammountToBePaidTemp
                            })
                            setItemType(dataItemTemp)
                          }
                        }}
                        options={dropDownWhtTemp}
                        renderOption={(props) => (
                          <Tooltip title={props.key} placement="bottom">
                            <li {...props}>{props.key}</li>
                          </Tooltip>
                        )}
                        name="whtSubType"
                        getOptionLabel={(option) => option.serviceType}
                        renderInput={(params) => <TextField   size="small" {...params} />}
                        size="small"
                        disabled={(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4) && cellValues.row.checkWht === true ? false:true}
                        sx={{ width: 300 }}
                        value={dropdownWht.find((d)=>d.companyCode === data.companyCode && d.taxType === cellValues.row.itemTypeWhtType && d.serviceType === cellValues.row.itemTypeWhtSubtype) !== undefined ?
                            dropdownWht.find((d)=>d.companyCode === data.companyCode && d.taxType === cellValues.row.itemTypeWhtType && d.serviceType === cellValues.row.itemTypeWhtSubtype ):null}
                        // value={dataListBanks["realData"].find(db => db.bankAccount === cellValues.row.bankAccount) !== undefined ? dataListBanks["realData"].find(db => db.bankAccount === cellValues.row.bankAccount) : null}
                    />
            )
          default:
            return ''
        }
      },
    },
    {
      field: "itemTypeWhtRate",
      headerName: "Wht Rate",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell:(cellValues)=>{
        switch (cellValues.row.wht) {
          case 1:
          // switch (checked.material) {
          //   case true:
              return(
              <div style={{ display: "flex", alignItems: "center" }}>
              <input
              disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:data.TaxType === "nonTaxable" || data.TaxType === ""? true:true}
              // disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:data.TaxType === "nonTaxable" || data.TaxType === ""? true:false}
              value={cellValues.row.itemTypeWhtRate}
              onChange={(e)=>handleChangeItemType(e,cellValues.row)}
                className="input-BilledQty"
                id="itemTypeWhtRate"
                name="itemTypeWhtRate"
                type="number"
                onPaste={preventPasteNegative}
                onKeyPress={preventMinus}
              />
              <p style={{ marginLeft: "20px" }}>%</p>
            </div>

              )
          
            // default:
            //   break;
          // }
          
        default:
          return <p></p>;
      }
    }
    },
    {
      field: "itemTypeWhtAmount",
      headerName: "Wht Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
      renderCell:(cellValues)=>{
        switch (cellValues.row.wht) {
          case 1:
          // switch (checked.material) {
          //   case true:
              return(
              <input
              disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:data.TaxType === "nonTaxable" || data.TaxType === ""? true:true}
              // disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
              value={cellValues.row.itemTypeWhtAmount}
              onChange={(e)=>handleChangeItemType(e,cellValues.row)}
                className="input-BilledQty"
                id="itemTypeWhtAmount"
                name="itemTypeWhtAmount"
                type="number"
                onPaste={preventPasteNegative}
                onKeyPress={preventMinus}
              />

              )
          
            // default:
            //   break;
          // }
          
        default:
          return<p></p>;
      }
    }
    },
    {
      field: "itemTypeWhtAmountToBePaid",
      headerName: "Amount To Be Paid",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 140,
      renderCell:(cellValues)=>{
        const data=Number(cellValues.row.itemTypeWhtAmountToBePaid)
        return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.itemTypeWhtAmountToBePaid ?? null} />
      }
    },
  ];

  const theColumnsError = [
    {
      field: "error_Description",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 500,
    },
    {
      field: "purchaseNo",
      headerName: "PO Number",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 120,
    },
    {
      field: "item",
      headerName: "Item",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 80,
    },
    {
      field: "balanceQty",
      headerName: "Balance Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 100,
    },
    {
      field: "billedQty",
      headerName: "Billed Qty",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 100,
    },
  ];
  const [TopInstallmentDetail, setTopInstallmentDetail] = useState(data.TopInstallmentDetail);
  const columnInstallment = [
    {
      field: "installment",
      headerName: "Installment",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 200,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 500,
      renderCell: (cellValues) => {
        let date ;
        if (cellValues.row.dueDate !== null || cellValues.row.dueDate !== undefined) {
          date=moment(cellValues.row.dueDate).format("yyyy-MM-DD")
        }else{
          date=null
        }
        
        return (
          <input
          disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
            className="input-instalment"
            id="dueDate"
            name="dueDate"
            type="date"
            value={date}
            defaultValue={date}
            onChange={(e)=>handleInstallment(e,cellValues.row.id)}
          />
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 490,
      renderCell: (cellValues) => {
        return (
          <TextField
            disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
            onChange={(e)=>handleInstallment(e,cellValues.row.id)}
            margin="none"
            InputProps={{
                inputComponent: NumberFormatCustom,
                className:data.TotalAmountInstallment === true ? "input-amountType-warning" :"input-amountType"
            }}
            name="amount"
            size="small"
            defaultValue={cellValues.row.amount}
          />
        );
      },
    },
  ];

  const handleInstallment =(e,dataId)=>{
    let valueInstallment = e.target.name === "amount" ? RemoveFormatting(e.target.value) : e.target.value
    let ammountTemp =[]

    const newData = TopInstallmentDetail
    const selected = newData.findIndex((d)=> d.id === dataId)
    newData[selected][e.target.name]=valueInstallment
    let detailTemp =[]
    newData.forEach((d)=>{
      detailTemp.push({
        installment:d.installment,
        dueDate:d.dueDate,
        amount:Number(d.amount)
      })
      ammountTemp.push(Number(d.amount))
    })
    
    let amount = ammountTemp.reduce((a, b) => a + b, 0).toFixed(2)

    let ammountToBePaid=data.TotalAmountToBePaid.toFixed(2)
    if (amount < ammountToBePaid ||amount > ammountToBePaid ) {
      setData({
        ...data,
        TotalAmountInstallment:true,
        TopInstallmentDetail:detailTemp
      })
    }else{
      setData({
        ...data,
        TotalAmountInstallment:false,
        TopInstallmentDetail:detailTemp
      })
    }

    // setData({
    //   ...data,
    //   TopInstallmentDetail:detailTemp
    // })
    setTopInstallmentDetail(newData)
  }
  
  
  const handleClick = (e) => {
    setSelectTemp(e)
    hiddenFileInput.current.click();
  };
  const handleUpload = (event) => {
    const dataTemp = attachSupportDoc
    const selected = dataTemp.findIndex((row)=>row.documentSettingId === selectTemp.documentSettingId)
    if (event.target.files.length >= 1) {
      dataTemp[selected].fileName=event.target.files[0].name
      dataTemp[selected].fileSupporting=event.target.files[0]
      dataTemp[selected].isUpload=1
      setAttachSupportDoc(dataTemp)
      let payloadAttach=[]
      dataTemp.forEach((d)=>{
        payloadAttach.push({
          DocSettingId:d.documentSettingId,
          DocumentNo:d.documentNo,
          FileSupporting:d.fileSupporting
        })
      })
      setData({
        ...data,
        attachment:payloadAttach
      })
      
    }
};
const handleDownloadFile=(event)=>{
  setLoadingDownload(true);
  const urlData = {
    url:event.url
  }
  
  dispatch(downloadFile(urlData)).then((res)=>{
    var blob = new Blob([res.data], {type: "application/octet-stream"});
    saveAs(blob, event.fileName)
    setLoadingDownload(false);

  })
}

const handleDeleteFile = (dataSelect) => {
  setSelectTemp(dataSelect)
  const dataTemp = attachSupportDoc
  const selected = dataTemp.findIndex((row)=>row.documentSettingId === dataSelect.documentSettingId)
  dataTemp[selected].fileName=null
  dataTemp[selected].fileSupporting=null
  dataTemp[selected].isUpload=0
  setAttachSupportDoc(dataTemp)
  
  let payloadAttach=[]
      dataTemp.forEach((d)=>{
        payloadAttach.push({
          DocSettingId:d.documentSettingId,
          DocumentNo:d.documentNo,
          FileSupporting:d.fileSupporting
        })
      })
  setData({
    ...data,
    attachment:payloadAttach
  })
  };

  const handleDocumentNo = (e,dataSelected)=>{
    let dataTemp = attachSupportDoc
    let selected = dataTemp.findIndex((row)=>row.documentSettingId === dataSelected.documentSettingId)
    dataTemp[selected][e.target.name]=e.target.value
    setAttachSupportDoc(dataTemp)
    let payloadAttach=[]
    dataTemp.forEach((d)=>{
      payloadAttach.push({
        DocSettingId:d.documentSettingId,
        DocumentNo:d.documentNo,
        FileSupporting:d.fileSupporting
      })
    })
      setData({
        ...data,
        attachment:payloadAttach
      })
  }
  const columnSupportDoc = [
    {
      field: "documentName",
      headerName: "Document Name",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 490,
      renderCell:(cellValues)=>{
        return <p className={cellValues.row.mandatory === true ?"mandatory-doc":undefined}>{cellValues.row.documentName}</p>
      }
    },
    {
      field: "documentNo",
      headerName: "Document No",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 190,
      renderCell:(cellValues)=>{
        return <input className="input-document-no" disabled={cellValues.row.documentName === "Invoice Vendor" && true}  name="documentNo" value={cellValues.row.documentNo || ""}  onChange={(e)=>handleDocumentNo(e,cellValues.row)} />
      }
    },
    {
      field: "fileName",
      headerName: "Upload File",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      minwidth: 290,
      renderCell: (cellValues) => {
        return (
            <p className="uploadFile-name" onClick={()=>handleDownloadFile(cellValues.row)} >{cellValues.row.fileName}</p>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex:1,
      minwidth: 290,
      
      renderCell: (cellValues) => {
        return (
          <div style={{display:"flex"}}>
            <p className="text-upload-file" onClick={()=>handleClick(cellValues.row)}>{cellValues.row.isUpload === 0?"Upload File":"Replace File"}</p>
            <p className="delete-upload-text"onClick={()=>handleDeleteFile(cellValues.row)} >{cellValues.row.isUpload === 1 && "Delete"}</p>
            <input
              disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}

              id="fileAttach"
              name="fileAttach"
              type="file"
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(event) => handleUpload(event)}
              
              />
            </div>
        );
      },
    },
  ];

  const handleBack = () => {
    history.push('/vdr/invoice-submission')
    dispatch(changeScreenIS("list-is"));
  };

  const handleIncreaseBtn = (number) => {
    number += 1;
    setData({
      ...data,
      TopInstallment: parseInt(number),
    });
    setTopInstallmentDetail([
      ...TopInstallmentDetail,
      {
        id: number,
        installment: number,
        dueDate: null,
        amount: null,
      },
    ]);
  };
  const handleDecreaseBtn = (number) => {
    number -= 1;
    if (number >= 0) {
      setData({
        ...data,
        TopInstallment: parseInt(number),
      });
      const newArr = [...TopInstallmentDetail];
      newArr.splice(-1);
      setTopInstallmentDetail(newArr);
    } else {
      Toastify({
        text: "installments must be more than 0",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "#CB605A",
        },
      }).showToast();
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };



  const handleSave = (type)=>{
    if (type === "save") {
      setLoadingSave(true)
    }else if(type === "submit"){
      setLoadingSubmit(true)
    }

    const newData ={
      InvoiceStatus:type === "save" ?0: type === "submit" ?1:null,
      TotalAmountToBePaid:data.TotalAmountToBePaid,
      VendorInvoiceNumber:data.VendorInvoiceNumber,
      InvoiceDate:moment(today).format("YYYY-MM-DD"),
      POType:data.poType,
      PaymentType:data.PaymentType,
      TaxType:data.TaxType,
      CompanyCode:data.companyCode,
      VendorCode:listInfoVendor[0].vendorCode,
      ListPOHdr:selectListPo,
      ItemList:data.selectedItemList.length >= 1?data.selectedItemList:null ,
      ItemTypeList:data.selectedItemType.length >= 1 ?data.selectedItemType:null,
      TopType:data.TopType,
      TopInstallment:data.TopInstallment,
      TopInstallmentDetail:data.TopInstallmentDetail.length >= 1 ?data.TopInstallmentDetail:null,
      BankName:data.BankName,
      BankAccountNo:data.BankAccountNo,
      BankAccountName:data.BankAccountName,
      TopDueDate:data.TopDueDate !== null ? moment(data.TopDueDate).format("YYYY-MM-DD") :null,
      TopPercentage:data.TopPercentage,
      TopAmount:data.TopAmount,
      SupportingDocuments:data.attachment.length >= 1 ?data.attachment:null
    }

    let formData = new FormData();
      for (let key in newData) {
        if (Array.isArray(newData[key])) {
          newData[key].forEach((obj, index) => {
            let keyList = Object.keys(obj);
            keyList.forEach((keyItem) => {
              let keyName = [key, "[", index, "]", ".", keyItem].join("");
              formData.append(keyName, obj[keyItem]);
            });
          });
        } else if (typeof newData[key] === "object") {
          for (let innerKey in newData[key]) {
            formData.append(`${key}.${innerKey}`, newData[key][innerKey]);
          }
        }
        formData.append(key, newData[key]);
      }
      if (formData.get("ItemList") !== "null") {
        formData.delete("ItemList")
      }
      if (formData.get("ListPOHdr") !== "null") {
        formData.delete("ListPOHdr")
      }
      if(formData.get("ItemTypeList") !== "null"){
        formData.delete("ItemTypeList")
      }
      if(formData.get("TopInstallmentDetail") !== "null"){
        formData.delete("TopInstallmentDetail")
      }
      if(formData.get("SupportingDocuments") !== "null"){
        formData.delete("SupportingDocuments")
      }
      const query = `?PaymentReqNo=${detailInvoice.listPaymentRequestRes[0].paymentReqNo}`

      const filterMandatoryFile = attachSupportDoc.filter((d)=> d.mandatory === true)
      const allTrue  = filterMandatoryFile.every(obj => obj.isUpload !== 0 && obj.documentNo !== null)

      if (type === "save") {
        if (
          data.TotalAmountItemType !== true &&
          Number(data.TotalAmountServiceMaterial) !== 0
        ) {
          if (data.TopPercentageCondition !== true) {
            if (data.TotalAmountInstallment !== true) {
                dispatch(updateInvoice(query, formData))
                  .then((res) => {
                    setLoadingSave(false)
                      dispatch(changeScreenIS("list-is"));
                      Toastify({
                        text: "Save Success",
                        duration: 3000,
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        style: {
                          background: "#5EBA7D",
                        },
                      }).showToast();
                  })
                  .catch((err) => {
                    console.log(err);
                    setLoadingSave(false)
                    Toastify({
                      text: err.data.message,
                      duration: 3000,
                      newWindow: true,
                      close: true,
                      gravity: "top",
                      position: "right",
                      stopOnFocus: true,
                      style: {
                        background: "#CB605A",
                      },
                    }).showToast();
                  });
            } else {
              setLoadingSave(false)
              Toastify({
                text: "The Amount of Installment Must Be Equal to Total Amount to be paid ",
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                  background: "#CB605A",
                },
              }).showToast();
            }
          } else {
            setLoadingSave(false)
            Toastify({
              text: "The Amount of Term of payment can't be more than to Subtotal Amount Before Tax",
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true,
              style: {
                background: "#CB605A",
              },
            }).showToast();
          }
        } else {
          setLoadingSave(false)
          Toastify({
            text: "The Amount of Material or Service Must Be Equal to Subtotal Amount Before Tax ",
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            style: {
              background: "#CB605A",
            },
          }).showToast();
        }
      } else {
        if (
          data.TotalAmountItemType !== true &&
          Number(data.TotalAmountServiceMaterial) !== 0
        ) {
          if (data.TopPercentageCondition !== true) {
            if (data.TotalAmountInstallment !== true) {
              if (data.TopType !== null) {
                if (allTrue) {
                  dispatch(updateInvoice(query, formData))
                    .then((res) => {
                        dispatch(
                          jurnalInvoice({
                            paymentreqNo: res.data.paymentReqNo,
                          })
                        )
                          .then((res) => {
                            setLoadingSubmit(false)
                            Toastify({
                              text: "Create Invoice Success",
                              duration: 3000,
                              newWindow: true,
                              close: true,
                              gravity: "top",
                              position: "right",
                              stopOnFocus: true,
                              style: {
                                background: "#5EBA7D",
                              },
                            }).showToast();
                            dispatch(changeScreenIS("list-is"));
                          })
                          .catch((err) => {
                            setLoadingSubmit(false)
                            dispatch(changeScreenIS("list-is"));
                            console.log(err);
                          });
                    })
                    .catch((err) => {
                      setLoadingSubmit(false)
                      console.log(err);
                      Toastify({
                        text: err.data.message,
                        duration: 3000,
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        style: {
                          background: "#CB605A",
                        },
                      }).showToast();
                    });
                } else {
                  setLoadingSubmit(false)
                  Toastify({
                    text: "Please upload file mandatory",
                    duration: 3000,
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    style: {
                      background: "#CB605A",
                    },
                  }).showToast();
                }
              }else{
                setLoadingSubmit(false)
                Toastify({
                  text: "Please choose `type` in term of payment",
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: "top",
                  position: "right",
                  style: {
                    background: "#CB605A",
                  },
                }).showToast();
              }
              
            } else {
              setLoadingSubmit(false)
              Toastify({
                text: "The Amount of Installment Must Be Equal to Total Amount to be paid ",
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                  background: "#CB605A",
                },
              }).showToast();
            }
          } else {
            setLoadingSubmit(false)
            Toastify({
              text: "The Amount of Term of payment can't be more than to Subtotal Amount Before Tax",
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true,
              style: {
                background: "#CB605A",
              },
            }).showToast();
          }
        } else {
          setLoadingSubmit(false)
          Toastify({
            text: "The Amount of Material or Service Must Be Equal to Subtotal Amount Before Tax ",
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            style: {
              background: "#CB605A",
            },
          }).showToast();
        }
      }
      
  }

  useEffect(() => {
    // untuk checkall list po
    if(checkedListPO.length === data.ListPO.length && checkedListPO.length > 0){
      setCheckAllListPO(true);
    }else{
      setCheckAllListPO(false);

      if(checkedListPO.length === 0){
        // if(data.PaymentType === "Advanced Payment"){
        //   setCurrency(null)
        //   // setData({
        //   //   ...data,
        //   //   selectedItemList: [],
        //   //   TopInstallment: 0,
        //   //   TopInstallmentDetail:[],
        //   //   TopPercentage:null,
        //   //   TopPercentageCondition:false,
        //   //   TopAmount:0,
        //   // });
        // }
        setSelectedPO([]);
        setSelectionModelPO([]);
        let dataNew =data.ListPO;
        const newDataCheckBox = dataNew.map((obj, i) => ({ ...obj, isCheckBox:false }));
        
        setData({
          ...data,
          ListPO:newDataCheckBox,
          PONo: [],
          ItemList: [],
          selectedItemList: [],
          TotalAmountItemType:true,
          TotalAmountBeforeTax: 0,
          TopPercentage:null,
          TopAmount:0,
        });
        setCurrency(null)
        setSelectedRows([])
        setCheckedItemList([]);
        dispatch({
          type: "ITEM_LIST_PO",
          payload: [],
        });
      }
    }
    
    setSelectedPO(checkedListPO)
    
    const selectedIDs = new Set(checkedListPO);
    const selectedRowData = data.ListPO.filter((row) =>
    selectedIDs.has(row.id)
    );
    if (selectedRowData.length >= 1) {
      let dataNew =data.ListPO;
      let newPoNo = [];
      let amountTemp=[];
      let curr = []
      let itemListPo=[];

      selectedRowData.forEach((d, i) => {
        const selected = dataNew.findIndex((row)=>row.poNo === d.poNo)
        if (selected > -1) {
          dataNew[selected].isCheckBox =true
        }

        itemListPo.push({
          PoNo:d.poNo
        })
        if(data.PaymentType === "Advanced Payment"){
          curr.push(d.currency)
        }
        newPoNo.push(d.poNo);
        amountTemp.push(d.netValue)
      });
      setSelectedListPo(itemListPo)

      // // checkitemlist sama dengan list po
      let newArray = [];
      let amountTempCredit=[]
      const selectedRowsTemp =selectedRows
      const checkFilterItemList = selectedRowsTemp.filter((elem) => itemListPo.find(({ PoNo }) => elem.poNo === PoNo));
      setSelectedRows(checkFilterItemList)
      if(checkFilterItemList.length >= 1){
        checkFilterItemList.forEach((dataPo) => {
          amountTempCredit.push(Number(dataPo.amountInvoice))
          newArray.push(dataPo["id"]);
        })
        setCheckedItemList(newArray);
      }else{
        setCheckedItemList([]);
      }
      

      if(data.PaymentType === "Advanced Payment"){
        const uniqueCurr = [...new Set(curr)];
        setCurrency(uniqueCurr)
      }

      let newAmmountBeforeTax = amountTemp.reduce((a, b) => a + b, 0).toFixed(2)
      
      let newAmmountBeforeTaxCredit = amountTempCredit.reduce((a, b) => a + b, 0).toFixed(2)
      
      const check = dataNew.filter((checkBox)=>{
        return checkBox.isCheckBox === true
      })

      let unchecked = check.filter(function(o1){
        return !selectedRowData.some(function(o2){
            return o1.poNo === o2.poNo;
        });
      })
      if (unchecked.length >= 1) {
        unchecked.forEach((d)=>{
          const selected = dataNew.findIndex((row)=>row.poNo === d.poNo)
          dataNew[selected].isCheckBox =false
        })
      }

      if(data.PaymentType === "Credit Payment"){
        setData({
          ...data,
          PONo: newPoNo,
          ListPO:dataNew,
          TotalAmountBeforeTax:newAmmountBeforeTaxCredit
        })
      }else if(data.PaymentType === "Advanced Payment"){
        let amountItemTypeTemp=[]
        let amount = (data.TopPercentage/100) * Number(newAmmountBeforeTax) 
        const dataItemTemp = dataItemType.filter((d)=>d.isCheckBox === true && (d.itemTypeDesc === "Material" ||d.itemTypeDesc === "Services" ))
        if (dataItemTemp.length >= 1) {
          dataItemTemp.forEach((d)=>{
            amountItemTypeTemp.push(d.itemTypeAmount)
          })
        }
        let amountItemType = amountItemTypeTemp.reduce((a, b) => a + b, 0).toFixed(2)
        let amountBefrTax = Number(data.TopAmount*100/data.TopPercentage).toFixed(2)
        
        if (Number(amountItemType).toFixed(2) !== Number(amount).toFixed(2) ) {
          setData({
            ...data,
            TotalAmountItemType: true,
            PONo: newPoNo,
            ListPO:dataNew,
            TotalAmountBeforeTax:amountBefrTax,//newAmmountBeforeTax,
            TopAmount:amount.toFixed(2)
          })
        }else{
          setData({
            ...data,
            TotalAmountItemType:false,
            PONo: newPoNo,
            ListPO:dataNew,
            TotalAmountBeforeTax:amountBefrTax,//newAmmountBeforeTax,
            TopAmount:amount.toFixed(2)
          })
        }
      }
    }else{
        let dataNew =data.ListPO;
        const newDataCheckBox = dataNew.map((obj, i) => ({ ...obj, isCheckBox:false }));
        
        setData({
          ...data,
          ListPO:newDataCheckBox,
          TotalAmountItemType:true,
          TotalAmountBeforeTax: 0,
          PONo: [],
          ItemList: [],
          selectedItemList: [],
        });
        setSelectedRows([])
        setCurrency(null);
        setSelectionModel([]);
        setSelectedRows([])
        setCheckedItemList([]);
        dispatch({
          type: "ITEM_LIST_PO",
          payload: [],
        });
    }
    //eslint-disable-next-line
  }, [checkedListPO])

  useEffect(() => {
    if (data.PaymentType === "Credit Payment") {
      if(checkedItemList.length === data.ItemList.length && checkedItemList.length > 0){
        setCheckAllItemList(true);
      }else{
        setCheckAllItemList(false);
        if(checkedItemList.length === 0){
          let dataNew =data.ItemList;
          const newDataCheckBox = dataNew.map((obj, i) => ({ ...obj, isCheckBox:false }));
          setData({
            ...data,
            ItemList:newDataCheckBox,
            TotalAmountBeforeTax: 0,
            selectedItemList: [],
          });
          setSelectedRows([])
          setCurrency(null);
          setSelectionModel([]);
        }
      }
      const selectedIDs = new Set(checkedItemList);
      const selectedRowData = data.ItemList.filter((row) =>
        selectedIDs.has(row.id)
      );
      if (selectedRowData.length >= 1 && selectedRowData !== undefined && selectedRowData !== null ) {
        setSelectedRows(selectedRowData);
        let dataNew = data.ItemList;
        let amountTemp = [];
        let newData = [];
        let curr = [];
        selectedRowData.forEach((d, i) => {
          curr.push(d.currency)
          const selected = dataNew.findIndex((row)=>row.conditionId === d.conditionId)
          dataNew[selected].isCheckBox =true
          amountTemp.push(Number(d.amountInvoice));
          newData.push({
            amountInvoice: d.amountInvoice,
            item: d.item,
            billedQty: parseInt(d.balanceQty),
            conditionId: d.conditionId,
            poNo: d.poNo,
            currency: d.currency
          });
        });

        const unique = [...new Set(curr)];
        setCurrency(unique)

        const check = dataNew.filter((checkBox)=>{
          return checkBox.isCheckBox === true
        })
        let unchecked = check.filter(function(o1){
          return !selectedRowData.some(function(o2){
              return o1.id === o2.id;          // assumes unique id
          });
        })
        
        if (unchecked.length >= 1) {
          unchecked.forEach((d)=>{
            const selected = dataNew.findIndex((row)=>row.conditionId === d.conditionId)
            dataNew[selected].isCheckBox =false
          })
        }
        
        let newAmmountBeforeTax = amountTemp.reduce(
          (a, b) => a + b,
          0
        );
        // remark 28072022
        // setData({
        //   ...data,
        //   ItemList:dataNew,
        //   TotalAmountBeforeTax: newAmmountBeforeTax,
        //   selectedItemList: newData,
        // });

        let ammountTemp=[]
        const dataItemTypeTemp =dataItemType.filter((d)=>d.isCheckBox===true && (d.itemTypeDesc.toLowerCase() === "material" || d.itemTypeDesc.toLowerCase() === "services"))
        if(dataItemTypeTemp.length >=1){
          dataItemTypeTemp.forEach((d)=>{
            ammountTemp.push(d.itemTypeAmount)

          })
        }
        let amountMaterial = ammountTemp.slice(0, 2).reduce((a, b) => a + b, 0).toFixed(2)
        if(data.PaymentType === "Credit Payment"){
          let amtBeforeTax = Number(newAmmountBeforeTax).toFixed(2);
          if (Number(amountMaterial).toFixed(2) !== amtBeforeTax ) {
            setData({
              ...data,
              TotalAmountItemType: true,
              ItemList:dataNew,
              TotalAmountBeforeTax: newAmmountBeforeTax,
              selectedItemList: newData,
            })
          }else{
            setData({
              ...data,
              TotalAmountItemType: false,
              ItemList:dataNew,
              TotalAmountBeforeTax: newAmmountBeforeTax,
              selectedItemList: newData,
            })
          }
        }else if(data.PaymentType === "Advanced Payment"){
          if (Number(amountMaterial).toFixed(2) !== Number(data.TopAmount).toFixed(2) ) {
            setData({
              ...data,
              TotalAmountItemType: true,
            })
          }else{
            setData({
              ...data,
              TotalAmountItemType:false,
            })
          }
        }

        
      }else{
        let dataNew =data.ItemList;
          const newDataCheckBox = dataNew.map((obj, i) => ({ ...obj, isCheckBox:false }));
          setData({
            ...data,
            ItemList:newDataCheckBox,
            TotalAmountItemType:true,
            TotalAmountBeforeTax: 0,
            selectedItemList: [],
          });
          setSelectedRows([])
          setCurrency(null);
          setSelectionModel([]);
      }
    }
    // eslint-disable-next-line
  }, [checkedItemList])

  useEffect(() => {
    if(checkedItemType.length === dataItemType.length && checkedItemType.length > 0){
      setCheckAllItemType(true);
    }else{
      setCheckAllItemType(false);

      if(checkedItemType.length === 0){
        let dataNew = dataItemType;
        const newDataCheckBox = dataNew.map((obj, i) => ({ ...obj, isCheckBox:false }));
        setItemType(newDataCheckBox);
        setSelectionItemType([]);
        // setSelectedRowsItemType([])
        setData({
          ...data,
          selectedItemType: [],
          TotalAmountToBePaid: 0,
        });
      }
    }

    const selectedIDs = new Set(checkedItemType);
    const selectedRowData = dataItemType.filter((row) =>
      selectedIDs.has(row.id)
    );

    if (selectedRowData.length >= 1) {
      let dataNew = dataItemType
      let amountTemp = [];
      let dataTemp = [];

      selectedRowData.forEach((d, i) => {
        // setSelectedRowsItemType(selectedRowData)
        const selected = dataNew.findIndex((row)=>row.id === d.id)
        dataNew[selected].isCheckBox =true
        amountTemp.push(Number(d.itemTypeWhtAmountToBePaid));
        dataTemp.push({
          itemTypeNo: d.itemTypeNo,
          itemTypeDesc: d.itemTypeDesc,
          itemTypeAmount: Number(d.itemTypeAmount),
          itemTypeVat: d.itemTypeVat,
          itemTypeVatRate: d.itemTypeVatRate,
          itemTypeVatAmount: Number(d.itemTypeVatAmount),
          itemTypeDppRate: d.itemTypeDppRate,
          itemTypeDppAmount: Number(d.itemTypeDppAmount),
          itemTypeTaxConfId: d.itemTypeTaxConfId === null ? "" : d.itemTypeTaxConfId,
          itemTypeWht: d.itemTypeWht,
          itemTypeWhtType: d.itemTypeWhtType,
          itemTypeWhtSubtype: d.itemTypeWhtSubtype,
          itemTypeWhtRate: Number(d.itemTypeWhtRate),
          itemTypeWhtAmount: Number(d.itemTypeWhtAmount),
          itemTypeWhtAmountToBePaid:Number(d.itemTypeWhtAmountToBePaid),
        });
      });

      let newAmmountax = amountTemp.reduce(
        (a, b) => a + b,
        0
      )

      let ammountTemp = [];

      const filterMaterialService = selectedRowData.filter((d)=>d.itemTypeDesc === "Material" || d.itemTypeDesc === "Services")
      
      filterMaterialService.forEach((data)=>ammountTemp.push(data.itemTypeAmount))
        
      let amountMaterial = ammountTemp.slice(0, 2).reduce((a, b) => a + b, 0).toFixed(2)

      const check = dataNew.filter((checkBox)=>{
        return checkBox.isCheckBox === true
      })
      let unchecked = check.filter(function(o1){
        return !selectedRowData.some(function(o2){
            return o1.id === o2.id;          // assumes unique id
        });
      })
      
      if (unchecked.length >= 1) {
        unchecked.forEach((d)=>{
          const selected = dataNew.findIndex((row)=>row.id === d.id)
          dataNew[selected].isCheckBox =false
        })
      }

      setItemType(dataNew)

      if(data.PaymentType === "Credit Payment"){
        let amtBeforeTax = Number(data.TotalAmountBeforeTax).toFixed(2);
        if (Number(amountMaterial).toFixed(2) !== amtBeforeTax ) {
          setData({
            ...data,
            TotalAmountServiceMaterial:amountMaterial,
            TotalAmountItemType: true,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp
          })
        }else{
          setData({
            ...data,
            TotalAmountServiceMaterial: amountMaterial,
            TotalAmountItemType: false,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp
          })
        }
      }else if(data.PaymentType === "Advanced Payment"){
        if (Number(amountMaterial).toFixed(2) !== Number(data.TopAmount).toFixed(2) ) {
          setData({
            ...data,
            TotalAmountServiceMaterial: amountMaterial,
            TotalAmountItemType: true,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp,
          })
        }else{
          setData({
            ...data,
            TotalAmountServiceMaterial: amountMaterial,
            TotalAmountItemType:false,
            TotalAmountToBePaid: newAmmountax,
            selectedItemType: dataTemp,
          })
        }
      }
    }
    
    // eslint-disable-next-line
  }, [checkedItemType])

  const handleTaxConfig = (idTaxConf, amount) => {
    try{
      const taxConf = dropdownTaxConfig.find((q) => q.value === idTaxConf);
      console.log(taxConf)
      // DPP
      let dppRate = taxConf.dpp;
      let mathDpp = 0;
      if(taxConf.isPercentage === false){
        let evalDpp = eval(taxConf.dppNumber);
        mathDpp = evalDpp * amount;
      }else{
        mathDpp = (dppRate / 100) * amount;
      }
      let dppAmount = Math.round(mathDpp);
      let resDpp = dppAmount.toFixed();
      // VAT
      let vatRate = taxConf.vat;
      let vatAmount = Math.round((vatRate / 100) * resDpp);
      let resVat = vatAmount.toFixed();
      return {resVat, vatRate, resDpp, dppRate}
    }catch{
      return {vat: 0, dpp: 0}
    }
  }

  // dropdown tax config
  useEffect(() => {
      dispatch(getTaxConfiguration()).then((res)=> {
        let getData = res.data;
        let newDataTaxType = [{ value: "", label: "---", vat: 0, dpp: 0, dppNumber: "", isPercentage: false }];
          if (getData.length >= 1) {
            const unique = [...new Set(getData)];
            unique.forEach((data, i) => {
              newDataTaxType.push({
                value: data.id,
                label: "VAT " + data.vatRate + "% (" + data.id + ")",
                vat: data.vatRate,
                dpp: data.dppRate,
                dppNumber: data.dppRateNumber,
                isPercentage: data.isPercentage
              });
            });
            setTaxConfig(newDataTaxType);
          }
      }).then(() => {
        setLoadingItemType(false);
      })
      .catch(() => {
        setLoadingItemType(false);
      })
    }, [])

  // dropdown wht & sub type wht
  useEffect(() => {
    if (data.companyCode !== null ||data.companyCode !== "") {
      setLoadingItemType(true);
      dispatch(getItemWhtType(`?CompanyCode=${data.companyCode}`)).then((res)=>{
        let getData = res.data;
        let newDataWhtType = [{ label: "---", value: "---" }];
        if (getData.length >= 1) {
          const unique = [...new Set(getData.map(item => item.taxType))];
          unique.forEach((data, i) => {
            newDataWhtType.push({
              value: data,
              label: data,
            });
           
          });
          setWhtType(newDataWhtType);
        }
      })
      .then(() => {
        setLoadingItemType(false);
      })
      .catch(() => {
        setLoadingItemType(false);
      })
    }// eslint-disable-next-line
  }, [data.companyCode])

  
  
  // use effect for checked po list
  useEffect(()=>{
    if (detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4) {
      const allPo = data.PONo;
      // credit payment logic -> get item list
      if (data.PaymentType === "Credit Payment") {
        if (allPo !== null && allPo !== undefined && allPo.length >= 1) {
          setLoadingItemList(true);
          let newTempData = []; // eslint-disable-next-line
          allPo.map((d) => {
            dispatch(
              getItemListPO({
                PONo: d,
                VendorCode: listInfoVendor[0].vendorCode,
              })
            )
              .then((res) => {
                let dataTemp = res.data;
                // filter dataTemp selain data existing di selectedRows
                dataTemp = dataTemp.filter((d1) => {
                  return !selectedRows.some((d2) =>{
                    return d2["id"] === d1["id"]
                  })
                })
                // setDatatemp billedqty = balanceqty
                dataTemp.forEach(((dt) => {
                  return dt["billedQty"] = dt["balanceQty"];
                }))
                let varSelectedRows = [...selectedRows];
                varSelectedRows.forEach((r) => {
                  let findData=res.data.find(p => p["id"] === r["id"])
                  if (findData !== undefined) {
                    r["balanceQty"] = findData["balanceQty"]
                  }
                })
               
                let dataTemp2 =[...dataTemp,...varSelectedRows]

                dataTemp2.sort((a, b) => Number(a["item"]) - Number(b["item"]));
                dataTemp2.forEach((data) => {
                  if(data.currency === "IDR"){
                    return data.amountInvoice= Math.ceil((data.billedQty*data.pricePerUnit))
                  }else{
                    return data.amountInvoice= (data.billedQty*data.pricePerUnit).toFixed(2)
                  }
                });
                return newTempData.push(dataTemp2);
              })
              .then(() => {
                let merged = [].concat.apply([], newTempData);
                const uniqueData = [...new Map(merged.map(item =>[item["id"], item])).values()]
                  
                if (uniqueData !== undefined && uniqueData !== null) {
                  let newSelectedRow = selectedRows;
                  let newData = uniqueData;

                  if (
                    newSelectedRow.length >= 1 &&
                    newSelectedRow !== undefined &&
                    newSelectedRow !== null
                    ) {
                    newSelectedRow.forEach((data) => {
                      let selected = newData.findIndex(
                        (d) => d.conditionId === data.conditionId
                      );
                      if (selected > -1) {
                        newData[selected].isCheckBox = true;
                      }
                    });
                    newData.sort((a, b) => Number(a.poNo) - Number(b.poNo));
                    setData({
                      ...data,
                      ItemList: newData,
                      ItemListtemp: newData,
                    });
                  } else {
                    const newDataCheckBox = newData.map((obj, i) => ({
                      ...obj,
                      isCheckBox: false,
                    }));
                    newDataCheckBox.sort((a, b) => Number(a.poNo) - Number(b.poNo));

                    setData({
                      ...data,
                      ItemList: newDataCheckBox,
                      ItemListtemp: newDataCheckBox,
                    });
                  }
                } else {
                  setSelectedRows([]);
                  setData({
                    ...data,
                    ItemList: [],
                    ItemListtemp: [],
                  });
                }
              })
              .then(() => {
                setLoadingItemList(false);
              })
              .catch(() => {
                setLoadingItemList(false);
              })
          });
        }
      }
    }
   // eslint-disable-next-line
  },[selectPO,data.PaymentType,data.TopAmount])


  useEffect(() => {
    
    if(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4){
      // sementara seperti ini remark use effect yang diatas 29072022
    }else{
      let newDataListPo = detailInvoice.listPurchaseOrderRes
      let newDataItemType = detailInvoice.listItemTypeRes
      
      let newDataItemList = detailInvoice.listItemListPOInvoiceRes

      let filterListPO= newDataListPo.filter((d)=>d.isCheckBox === true)
      let filterItemList= newDataItemList.filter((d)=>d.isCheckBox === true)
      let filterItemType= newDataItemType.filter((d)=>d.isCheckBox === true)
       
      setItemType(filterItemType)
      setData({
        ...data,
        ListPO:filterListPO,
        ItemList:filterItemList,
        ItemListtemp:filterItemList
      })
    }
     // eslint-disable-next-line
  }, [selectPO,data.ItemList,selectedRows])
  

  useEffect(() => {
    let newDataBank = [{ value: "", label: "Choose Here" }];
    let bankData = listBankAccount
    bankData.forEach((data, i) => {
      newDataBank.push({
        value: data.bankCode,
        label: data.bankCode,
      });
    });
  
    setBankCode(newDataBank)
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    let newDataBankNo = [{ value: "", label: "Choose Here" }];
    if (data.BankName !== ''|| data.BankName !== null) {
      let bankData = listBankAccount.filter((d)=>{
        return d.bankCode === data.BankName
     });
      bankData.forEach((data, i) => {
        newDataBankNo.push({
          value: data.bankAccount,
          label: data.bankAccount,
        });
      });
    }
  
    setBankNo(newDataBankNo)

    // eslint-disable-next-line
  }, [data.BankName])
  
  useEffect(() => {
    if(data.PaymentType === "Credit Payment"){
      if ((data.ItemList !== null || data.ItemList !== 0) && (detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4)) {
        const filterCheckTrue = data.ItemList.filter((d)=>d.isCheckBox===true)
        
        let curr =[]
        if(filterCheckTrue.length >= 1){
          filterCheckTrue.forEach((d)=>{
            curr.push(d.currency);
          })
          const unique = [...new Set(curr)];
          setCurrency(unique)
        }else{
          setData({
            ...data,
            TotalAmountBeforeTax:0,
          });
          setCurrency(null)
        }
      }else{
        let curr =[]
        let tempAmountbeforeTax=[]
        let newDataItemList=data.ItemList
        newDataItemList.forEach((d)=>{
          tempAmountbeforeTax.push(Number(d.amountInvoice))
          curr.push(d.currency);
          if(d.currency === "IDR"){
            return d.amountInvoice= Math.ceil(d.amountInvoice)
          }else{
            return d.amountInvoice= Number(d.amountInvoice).toFixed(2)
          }
        })
        const unique = [...new Set(curr)];
        let totalBeforeTax= tempAmountbeforeTax.reduce((a, b) => a + b, 0).toFixed(2)


        let ammountTemp=[]
        const dataItemTypeTemp =dataItemType.filter((d)=>d.paymentReqNo !== null )
        if(dataItemTypeTemp.length >=1){
          dataItemTypeTemp.forEach((d)=>{
            ammountTemp.push(d.itemTypeAmount)
          })
        }
        let amountMaterial = ammountTemp.slice(0, 2).reduce((a, b) => a + b, 0).toFixed(2)

        if (Number(amountMaterial).toFixed(2) !== totalBeforeTax ) {
          setData({
            ...data,
            ItemList:newDataItemList,
            ItemListtemp:newDataItemList,
            TotalAmountItemType: true,
            TotalAmountBeforeTax: totalBeforeTax,
          })
        }else{
          setData({
            ...data,
            ItemList:newDataItemList,
            ItemListtemp:newDataItemList,
            TotalAmountItemType: false,
            TotalAmountBeforeTax: totalBeforeTax,
          })
        }

        // setData({
        //   ...data,
        //   ItemList:newDataItemList,
        //   ItemListtemp:newDataItemList,
        //   TotalAmountBeforeTax:totalBeforeTax
        // })
        setCurrency(unique)
      }
    }else{
      let curr =[]
      let beforeTax=[]
      let filterListPO= data.ListPO.filter((d)=>{
        return d.isCheckBox === true
      })
      if(filterListPO.length >= 1){
        filterListPO.forEach((d)=>{
          curr.push(d.currency);
          beforeTax.push(d.remainingAmountBalance)
        })
        const unique = [...new Set(curr)];
          setCurrency(unique)
          // let totalBeforeTax= beforeTax.reduce((a, b) => a + b, 0).toFixed(2)
          let amountBfrTax= Number(data.TopAmount*100/data.TopPercentage).toFixed(2)
          setData({
            ...data,
            TotalAmountBeforeTax:amountBfrTax,//totalBeforeTax,
          });
      }else{
        setCurrency(null)
      }
    }
    // eslint-disable-next-line
  }, [data.ItemList])

  return (
    <Page title="Invoice Submission">
      <Navbar />
      <div className="container-vendor">
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingDownload}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      <div className="header-vendor-invoice">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h6>Detail Invoice</h6>
            </div>
            <div>
              <span>Total Amount to be paid</span>
              {currency !== null && currency[0] === "IDR" ? <h2>{`IDR ${ConvertFormat(data.TotalAmountToBePaid)}`}</h2>:currency !== null ?
              <h2>{`${currency[0]} ${Number(data.TotalAmountToBePaid).toFixed(2)}`}</h2>:<h2>{Number(data.TotalAmountToBePaid).toFixed(2)}</h2>
              }
            </div>
          </div>
          {detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginLeft: "20px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                btn="btn-vendor-outlined"
                title="Back"
                onClick={handleBack}
              />
              {loadingSave === true ?
            
            <div className="btn-for-vendor-outlined">
              <Button
                btn="btn-download-loader"
              />
            </div>
            :
            <Button
              btn="btn-vendor-outlined"
              onClick={()=>handleSave("save")}
              title="Save"
              disabled={loadingSubmit === true?true:false}
            />
          }
             {loadingSubmit === true ? 
            <div className="btn-for-vendor-loading-submit">
            <Button
              btn="btn-download-loader"
            />
          </div>:
            <Button btn="btn-vendor-fill" title="Submit" onClick={()=>handleSave("submit")} disabled={loadingSave === true?true:false} />

        }
              
            </div>
          ) : (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginLeft: "20px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                btn="btn-vendor-outlined"
                title="Back"
                onClick={handleBack}
              />
            </div>
          )}
        </div>
        <div className="content-list-po" style={{ marginTop: "220px" }}>
          <div className="square">
            <h3>Payment Request</h3>
            <Grid container spacing={2}>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="PaymentRequestNumber">
                  Payment Request Number
                </label>
                <input
                  disabled={true}
                  id="PaymentRequestNumber"
                  name="PaymentRequestNumber"
                  type="text"
                  defaultValue={
                    detailInvoice.listPaymentRequestRes[0].paymentReqNo
                  }
                />
                <label htmlFor="PaymentType">Payment Type</label>
                <input
                  disabled={true}
                  id="PaymentType"
                  name="PaymentType"
                  type="text"
                  defaultValue={
                    detailInvoice.listPaymentRequestRes[0].paymentType
                  }
                />
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="invoiceDate">Invoice Date</label>
                <input
                  disabled={true}
                  id="invoiceDate"
                  name="invoiceDate"
                  type="text"
                  defaultValue={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? moment(today).format("DD MMM YYYY"):moment(detailInvoice.listPaymentRequestRes[0].invoiceDate).format("DD MMM YYYY")}
                />

                <label htmlFor="TaxType">Tax Type</label>
                <input
                  disabled={true}
                  id="TaxType"
                  name="TaxType"
                  type="text"
                  defaultValue={detailInvoice.listPaymentRequestRes[0].taxType}
                />
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="VendorInvoiceNumber">
                  Vendor Invoice Number
                </label>
                <input
                  disabled={true}
                  id="VendorInvoiceNumber"
                  name="VendorInvoiceNumber"
                  type="text"
                  value={detailInvoice.listPaymentRequestRes[0].vendorInvoiceNo}
                />
                <label htmlFor="companyCode">Company Code</label>
                <input
                  disabled={true}
                  id="companyCode"
                  name="companyCode"
                  type="text"
                  value={detailInvoice.listPaymentRequestRes[0].companyCode}
                />
              </Grid>
              <Grid className="detail-info" item xs={3} md={3}>
                <label htmlFor="poType">PO Type</label>
                <input
                  disabled={true}
                  id="poType"
                  name="poType"
                  type="text"
                  value={detailInvoice.listPaymentRequestRes[0].poType === 1 ? "Trade" :detailInvoice.listPaymentRequestRes[0].poType === 0 ? "Non Trade": null }
                />
                <label htmlFor="paymentTerm">Payment Term</label>
                <input
                  disabled={true}
                  id="paymentTerm"
                  name="paymentTerm"
                  type="text"
                  value={detailInvoice.listPaymentRequestRes[0].paymentTerm === "COD" ? "Cash Before Delivery" :
                  detailInvoice.listPaymentRequestRes[0].paymentTerm === "CBD" ? "Cash Before Delivery"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "CASH" ? "Payable immediately Due net"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "IN75" ? "Payment in 75 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "IN90" ? "Payment in 90 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "IN60" ? "Payment in 60 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "IN45" ? "Payment in 45 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "IN30" ? "Payment in 30 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "IN14" ? "Payment in 14 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "IN07" ? "Payment in 7 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "I365" ? "Payment in 365 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "I180" ? "Payment in 180 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "I160" ? "Payment in 160 days"
                  :detailInvoice.listPaymentRequestRes[0].paymentTerm === "I120" ? "Payment in 120 days"
                  : "---" }
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* list PO */}
        <div className="content-list-po">
          <div className="square-table">
            <h3 style={{ marginLeft: "30px" }}>List Purchase Order</h3>
            <Box
              sx={{
                height:
                  pageSizePO === 5
                    ? 400
                    : pageSizePO === 10
                    ? 700
                    : pageSizePO === 25
                    ? 1800
                    : 2000,
                width: 1,
                "& .super-app-theme--header": {
                  backgroundColor: "#001D61",
                  color: "#FFFFFF",
                  fontFamily: "Quicksand",
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
            >
              <CustomDataGrid
                columns={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? theColumns : columnPoView()}
                disableSelectionOnClick
                pageSize={pageSizePO}
                onPageSizeChange={(newPageSize) =>setPageSizePO(newPageSize)}
                rows={data.ListPO}
                rowsPerPageOptions={[5, 10]}
                selectionModel={selectionModelPO}
                isRowSelectable={
                (params) => {
                params.row.isVisible = true;
                }
                }
              />
            </Box>
          </div>
        </div>
        
          <div>
            {data.PaymentType === "Credit Payment" && data.PONo.length >= 1 && (
              <div className="content-list-po">
                <div className="square-table">
                  <div
                    style={{
                      display:"flex",
                      justifyContent: "space-between",
                      alignItems:"center"
                    }}
                  >
                    <h3 style={{ marginLeft: "30px" }}>Item List</h3>

                    <div
                     style={{
                      display:"flex",
                      marginRight:"20px"
                    }}
                    >
                      {(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4) &&
                      <>
                      <Button btn="btn-vendor-export" title="EXPORT" onClick={()=>exportToExcel()}>Export</Button>
                      <Button btn="btn-vendor-upload" title="UPLOAD" onClick={()=>setUploadItemList(true)}/>
                      </>
                      }
                    </div>
                  </div>
                  <Box
                    sx={{
                      height:
                        pageSizeItemList === 5
                        ? 400
                        : pageSizeItemList === 10
                        ? 700
                        : pageSizeItemList === 25
                        ? 1800
                        : 2000,
                      width: 1,
                      "& .super-app-theme--header": {
                        backgroundColor: "#001D61",
                        color: "#FFFFFF",
                        fontFamily: "Quicksand",
                        fontSize: "12px",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {
                      loadingItemList ? (
                        <Skeleton
                          height={
                            pageSizeItemList === 5
                            ? 400
                            : pageSizeItemList === 10
                            ? 700
                            : pageSizeItemList === 25
                            ? 1800
                            : 2000
                          }
                          variant="rectangular"
                        />
                      ):(
                        <CustomDataGrid
                          columns={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? columnItemList : columnItemListView()}
                          disableSelectionOnClick
                          pageSize={pageSizeItemList}
                          onPageSizeChange={(newPageSize) => {
                            setPageSizeItemList(newPageSize)
                          }}
                          rows={data.ItemList}
                          rowsPerPageOptions={[5, 10]}
                          selectionModel={selectionModel}
                        />
                      )
                    }
                  </Box>
                </div>
              </div>
            )}
            {/* {(detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4) && */}
            <div className="content-list-po">
              <div className="square">
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p>Subtotal Amount before Tax</p>
                  {currency !== null && currency[0] === "IDR" ? <h5>{`${currency[0]} ${ConvertFormat(data.TotalAmountBeforeTax)}`}</h5>: currency !== null ?
                  <h5>{`${currency[0]} ${Number(data.TotalAmountBeforeTax).toFixed(2)}`}</h5>
                  :
                  <h5>{Number(data.TotalAmountBeforeTax).toFixed(2)}</h5>
                  }
                </div>
              </div>
            </div>
            {/* } */}

            {/* term of payment AdvancedPayment*/}
            {data.PaymentType === "Advanced Payment" && (
              <div className="content-list-po">
                <div className="square">
                  <h3>Term of Payment</h3>
                  <Grid container spacing={2}>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopType">Type</label>
                      <select
                        disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:true}
                        className="select-create-invoice"
                        id="TopType"
                        name="TopType"
                        defaultValue={data.TopType}
                        value={data.TopType}
                        onChange={handleFormChange}
                        style={{ marginBottom: "30px" }}
                      >
                        <option disabled selected>
                          ---
                        </option>
                        <option value="DownPayment">Down Payment</option>
                        <option value="COD">COD (Cash on Delivery)</option>
                        <option value="CBD">CBD (Cash before Delivery)</option>
                      </select>
                      <label htmlFor="TopPercentage">Percentage</label>
                      <input
                        disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:true}
                        defaultValue={data.TopPercentage}
                        id="TopPercentage"
                        name="TopPercentage"
                        type="number"
                        onChange={handleFormChange}
                        value={data.TopPercentage}
                        style={{ backgroundColor: "#FFFFFF" }}
                      />
                    </Grid>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopDueDate">Due Date</label>
                      <input
                      // defaultValue={data.TopDueDate !== null? data.TopDueDate:dueDate }
                      defaultValue={data.TopDueDate}

                      disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:true}

                      value={data.TopDueDate}
                        id="TopDueDate"
                        name="TopDueDate"
                        type="date"
                        onChange={handleFormChange}
                        style={{
                          backgroundColor: "#FFFFFF",
                          marginBottom: "30px",
                        }}
                      />
                      <label htmlFor="TopAmount">Amount</label>
                        
                      <input disabled={true} Value={data.TopAmount} defaultValue={data.TopAmount}  id="TopAmount" style={{
              border:
                data.TopPercentageCondition === true ? "1px solid #CB605A" : "",
            }} name="TopAmount" type="text"  />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}

            {/* item type */}
            <div className="content-list-po">
              <div className="square-table">
                <h3 style={{ marginLeft: "30px" }}>Item Type</h3>
                <Box
                  sx={{
                    height: 400,
                    width: 1,
                    "& .super-app-theme--header": {
                      backgroundColor: "#001D61",
                      color: "#FFFFFF",
                      fontFamily: "Quicksand",
                      fontSize: "12px",
                      fontWeight: "bold",
                    },
                  }}
                >
                  {
                    loadingItemType ? (
                      <Skeleton
                        height={400}
                        variant="rectangular"
                      />
                    ):(
                      <CustomDataGrid
                        selectionModel={selectionModelItemType}
                        columns={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? columnItemType : columnItemTypeView(data,dropdownWht,dropdownWhtType,dropdownWhtSubType)}
                        disableSelectionOnClick
                        rows={dataItemType}
                        pageSize={5}
                      />
                    )
                  }
                </Box>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "30px",
                  }}
                >
                  <h2>Total Amount to be Paid</h2>
                  {currency !== null && currency[0] === "IDR" ? <h5>{`${currency[0]} ${ConvertFormat(data.TotalAmountToBePaid)}`}</h5>:currency !== null ?
                  <h5>{`${currency[0]} ${ConvertFormat(data.TotalAmountToBePaid.toFixed(2))}`}</h5>: <h5>{ConvertFormat(data.TotalAmountToBePaid.toFixed(2))}</h5>
                  }
                </div>
              </div>
            </div>

            {/* bank info */}
            <div className="content-list-po">
              <div className="square">
                <h3>Bank Account Information</h3>
                <Grid container spacing={2}>
                  <Grid className="detail-info" item xs={3} md={3}>
                    <label htmlFor="BankName">Bank</label>
                    <select
                    disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
                      className="select-create-invoice"
                      id="BankName"
                      name="BankName"
                      onChange={handleFormChange}
                      style={{ marginBottom: "30px" }}
                      value={data.BankName}
                      defaultValue={data.BankName}
                    >
                      {dropdownBank.map((status, key) => (
                    <option key={"dropdownBank-" + key} value={status.value} hidden={status.value === "" ?true:false}>
                      {status.label}
                    </option>
                  ))}
                    </select>
                    <label htmlFor="BankAccountNo">Account No</label>
                    <select
                    disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}

                      className="select-create-invoice"
                      id="BankAccountNo"
                      name="BankAccountNo"
                      onChange={handleFormChange}
                      style={{ marginBottom: "30px" }}
                      value={data.BankAccountNo}
                      defaultValue={data.BankAccountNo}
                    >
                      {dropdownBankNo.map((status, key) => (
                    <option key={"dropdownBankNo-" + key} value={status.value} hidden={status.value === "" ?true:false}>
                      {status.label}
                    </option>
                  ))}
                    </select>
                    <label htmlFor="BankAccountName">Account Name</label>
                    <input
                      disabled={true}
                      id="BankAccountName"
                      name="BankAccountName"
                      type="text"
                      defaultValue={data.BankAccountName}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            
             {/* Installment*/}
             {data.PaymentType === "Advanced Payment" && (
              <div className="content-list-po">
                <div className="square-table">
                  <h3 style={{ marginLeft: "30px" }}>Installment</h3>
                  <Grid container spacing={2}>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopType" style={{ marginLeft: "15px" }}>
                      </label>
                      <select
                        disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:true}
                      defaultValue={data.TopType}
                      value={data.TopType}
                        className="select-create-invoice"
                        id="TopType"
                        name="TopType"
                        onChange={handleFormChange}
                        style={{ marginBottom: "30px", marginLeft: "15px" }}
                        >
                          <option value="Installment">
                          Installment
                          </option>
                      </select>

                      {data.PaymentType === "Advanced Payment" ? (
                        <>
                          <label
                            htmlFor="TopInstallment"
                            style={{ marginLeft: "30px" }}
                          >
                          </label>
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              marginLeft: "30px",
                              width: "100%",
                            }}
                          >
                            <Button
                              disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}

                              btn="btn-decrease"
                              onClick={() =>
                                handleDecreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                            <input
                              disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}

                              id="TopInstallment"
                              name="TopInstallment"
                              type="number"
                              min="0"
                              step="1"
                              onPaste={preventPasteNegative}
                              onKeyPress={preventMinus}
                              onChange={handleFormChange}
                              value={data.TopInstallment}
                              style={{
                                backgroundColor: "#FFFFFF",
                                textAlign: "center",
                              }}
                            />
                            <Button
                              btn="btn-increase"
                              disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
                              onClick={() =>
                                handleIncreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                          </div>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                  {data.TopInstallment > 0 && (
                    <Box
                      sx={{
                        height: 300,
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#001D61",
                          color: "#FFFFFF",
                          fontFamily: "Quicksand",
                          fontSize: "12px",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <CustomDataGrid
                        pageSize={10}
                        columns={columnInstallment}
                        disableSelectionOnClick
                        rows={TopInstallmentDetail}
                      />
                    </Box>
                  )}
                </div>
              </div>
            )}


            {/* term off payment */}
            {data.PaymentType === "Credit Payment" && (
              <div className="content-list-po">
                <div className="square-table">
                  <h3 style={{ marginLeft: "30px" }}>Term of Payment</h3>
                  <Grid container spacing={2}>
                    <Grid className="detail-info" item xs={3} md={3}>
                      <label htmlFor="TopType" style={{ marginLeft: "30px" }}>
                        Type
                      </label>
                      <select
                        disabled={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ? false:true}

                      defaultValue={data.TopType}
                      value={data.TopType}
                        className="select-create-invoice"
                        id="TopType"
                        name="TopType"
                        onChange={handleFormChange}
                        style={{ marginBottom: "30px", marginLeft: "30px" }}
                      >
                        <option disabled selected>
                          ---
                        </option>
                        <option value="Installment">Installment</option>
                        <option value="Credit">Credit</option>
                      </select>

                      {data.PaymentType === "Credit Payment" &&
                      data.TopType === "Installment" ? (
                        <>
                          <label
                            htmlFor="TopInstallment"
                            style={{ marginLeft: "30px" }}
                          >
                            Installment
                          </label>
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              marginLeft: "30px",
                              width: "100%",
                            }}
                          >
                            <Button
                              disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}

                              btn="btn-decrease"
                              onClick={() =>
                                handleDecreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                            <input
                              disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}

                              id="TopInstallment"
                              name="TopInstallment"
                              type="number"
                              min="0"
                              step="1"
                              onPaste={preventPasteNegative}
                              onKeyPress={preventMinus}
                              onChange={handleFormChange}
                              value={data.TopInstallment}
                              style={{
                                backgroundColor: "#FFFFFF",
                                textAlign: "center",
                              }}
                            />
                            <Button
                              btn="btn-increase"
                              disabled={detailInvoice.listPaymentRequestRes[0].status === 1 ? true:false}
                              onClick={() =>
                                handleIncreaseBtn(parseInt(data.TopInstallment))
                              }
                            />
                          </div>
                        </>
                      ) : data.PaymentType === "Credit Payment" &&
                        data.TopType === "Credit" ? (
                        <>
                          <label
                            htmlFor="TopDueDate"
                            style={{ marginLeft: "30px" }}
                          >
                            Due Date
                          </label>
                          <input
                          // defaultValue={data.TopDueDate !== null? data.TopDueDate:dueDate }
                          
                            id="TopDueDate"
                            name="TopDueDate"
                            defaultValue={data.TopDueDate}
                            value={data.TopDueDate}
                            type="date"
                            onChange={handleFormChange}
                            style={{
                              backgroundColor: "#FFFFFF",
                              marginLeft: "30px",
                            }}
                            min={new Date().toISOString().split('T')[0]} // menonaktifkan tanggal sebelum hari ini
                            //disabled // menonaktifkan input tanggal
                            onKeyDown={(e) => e.preventDefault()} // Mencegah input melalui keyboard
                          />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                  {data.TopInstallment > 0 && (
                    <Box
                      sx={{
                        height: 300,
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#001D61",
                          color: "#FFFFFF",
                          fontFamily: "Quicksand",
                          fontSize: "12px",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <CustomDataGrid
                        pageSize={10}
                        columns={columnInstallment}
                        disableSelectionOnClick
                        rows={TopInstallmentDetail}
                      />
                    </Box>
                  )}
                </div>
              </div>
            )}

            {/* Supporting Document */}
            <div className="content-list-po">
              <div className="square-table">
                <h3 style={{ marginLeft: "30px" }}>Supporting Document</h3>
                <Box
                  sx={{
                    height: 900,
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#001D61",
                      color: "#FFFFFF",
                      fontFamily: "Quicksand",
                      fontSize: "12px",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <CustomDataGrid
                    pageSize={15}
                    columns={detailInvoice.listPaymentRequestRes[0].status === 0 ||detailInvoice.listPaymentRequestRes[0].status === 4 ?columnSupportDoc:columnSupportDocumentView(handleDownloadFile)}
                    disableSelectionOnClick
                    rows={attachSupportDoc}
                  />
                </Box>
              </div>
            </div>
          </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        // fullScreen={fullScreen}
        open={uploadItemList}
        onClose={()=>setUploadItemList(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Upload"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="dialog-poTrade">
            <Grid container spacing={2}>
              <Grid className="label-dialog-poTrade" item xs={4} md={4}>
                <label htmlFor="uploadFile">Upload File</label>
              </Grid>
              <Grid className="label-dialog-poTrade" item xs={8} md={8}>
                <input className="file-upload" type="file" id="uploadFile" onChange={(e)=>setFileUploadItemList(e.target.files[0])} />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setUploadItemList(false)}
            btn="btn-vendor-outlined"
            title="Cancel"
          />
          {loadingUpload === true?
        <div className="btn-for-vendor-loading-submit">
        <Button
          btn="btn-download-loader"
        />
      </div>:
            <Button onClick={handleUploadItemList} btn="btn-vendor-fill" title="Upload" />

        }
        </DialogActions>
      </Dialog>

      {/* dialog error message */}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        // fullScreen={fullScreen}
        open={errorDialog}
        onClose={()=>setErrorDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Error Detail</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="dialog-poTrade">
            <Grid item xs={12}>
              <Box
                sx={{
                  height:
                    pageErr === 5
                      ? 400
                      : pageErr === 10
                      ? 700
                      : pageErr === 25
                      ? 1800
                      : 2000,
                  width: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "#CB605A",
                    color: "#FFFFFF",
                    fontFamily: "Quicksand",
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                }}
              >
                <CustomDataGrid
                  columns={theColumnsError}
                  disableSelectionOnClick
                  pageSize={pageErr}
                  onPageSizeChange={(newPageSize) => setPageErr(newPageSize)}
                  rows={listErrorMsg}
                  rowsPerPageOptions={[5, 10]}
                />
              </Box>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setErrorDialog(false)}
            btn="btn-vendor-outlined"
            color="red"
            title="Cancel"
          />
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default DetailInvoice;
