import {
  DataGrid,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:hover": {
        color: "primary.main",
    },
    "& .MuiDataGrid-cell": {
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontWeight: 500,
    color: "#333333",
    },
    "& 	.MuiDataGrid-columnHeader":{
    backgroundColor: "#001D61",
    color: "#FFFFFF",
    fontFamily: "Quicksand",
    fontSize: "12px",
    fontWeight: "bold",
    height: "200px"
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput":{
    color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeader .MuiSvgIcon-root":{
    color: "#ffffff"
    }
}));

export default CustomDataGrid