const initialState = {
    listStatus: [],
    listSysparam:[],
    listTitle:[],
    listCurrency:[],
    listCountry:[],
    listPaymentTerms:[],
    listPaymentMethod:[],
    listBankAccount:[],
    listBank:[],
    listCompanyCode:[],
    dueDate:null,
    listPicInfo:[],
    listTaxConfig: [],
  };
  
  
  
  
  

  const listStatusReducer = (state = initialState, action) => {
    switch(action.type){
      case 'LIST_STATUS':
        return {
          ...state,
          listStatus: action.payload
        };
      case 'LIST_TITLE_DRW':
        return {
          ...state,
          listTitle: action.payload
        };
        case 'LIST_COUNTRY_DRW':
        return {
          ...state,
          listCountry: action.payload
        };
        case 'LIST_CURR_DRW':
        return {
          ...state,
          listCurrency: action.payload
        };
        case 'LIST_PAYMENT_TERMS_DRW':
        return {
          ...state,
          listPaymentTerms: action.payload
        };
        case 'LIST_PAYMENT_METHOD_DRW':
          return {
            ...state,
            listPaymentMethod: action.payload
          };
        case 'LIST_SYSPARAM':
          return {
            ...state,
            listSysparam: action.payload
          };
          case 'LIST_BANK_ACCOUNT':
          return {
            ...state,
            listBankAccount: action.payload
          };
          case 'LIST_BANK':
            return {
              ...state,
              listBank: action.payload
            };
            case 'LIST_COMPANY_CODE':
            return {
              ...state,
              listCompanyCode: action.payload
            };
            case 'LIST_PAYMENT_TERMS_DUEDATE':
            return {
              ...state,
              dueDate: action.payload
            };
            case 'LIST_PIC_INFO':
            return {
              ...state,
              listPicInfo: action.payload
            };
            
      default:
        return state;
    }
  }
  
  export default listStatusReducer;