const initialState = {
    listInfoVendor: [],
  };
  
  const infoVendorReducer = (state = initialState, action) => {
    switch(action.type){
      case 'LIST_INFO_VENDOR':
        return {
          ...state,
          listInfoVendor: action.payload
        };
      default:
        return state;
    }
  }
  
  export default infoVendorReducer;