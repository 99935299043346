// import history from "./history";
import {getItem,setItem,clearAll,removeItem} from "./Storage.js";

const axios = require("axios").default;

axios.interceptors.request.use(
    function(config){
        config.timeout = 3600000 //1 jam
        if (config.data instanceof FormData) {
            config.headers["Content-Type"] = "multipart/form-data";
          }else if(config.data instanceof URLSearchParams){
            config.headers["Content-Type"] = "application/x-www-form-urlencoded";
          }
           else {
            config.headers["Content-Type"] = "application/json";
        }
        if (getItem("access_token")) {
          config.headers.Authorization = "Bearer " + getItem("access_token");
        }

        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
  function(response){
    return response;
  },
  function(error){
    const originalRequest = error.config;
    var code = error.response ? error.response.status : 500;
    if (code === 401 && getItem("refresh_token")) {
      var data = new URLSearchParams();
      var user = JSON.parse(getItem("user_info"));

      data.append("client_id", 'ro.client');
      data.append("client_secret", process.env.REACT_APP_SECRET);
      data.append("grant_type", "refresh_token");
      data.append("refresh_token", getItem("refresh_token"));
      if (getItem("GR") === "Vendor") {
        data.append("session_token", localStorage.getItem("session_token_"));
      } else {
        data.append("session_token", localStorage.getItem("session_token__"));
      }
      data.append("username", user.email);
      console.log(data);
      return axios
        .post(process.env.REACT_APP_URL_AUTH + "connect/token", data)
        .then(res => {
          setItem("user_info", JSON.stringify(res.data));
          setItem("access_token", res.data.access_token);
          setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("session_token_", res.data.session_token);
          return axios(originalRequest);
        })
        .catch((err) => {
          console.log(err);
          removeItem("access_token")
          removeItem("refresh_token")
          // var gr = getItem("GR").toLocaleLowerCase();

          // logInfo("Redirect Login", {
          //   code: 401,
          //   type: "refresh_token",
          //   role: gr,
          //   request: originalRequest,
          // });
          setTimeout(() => {
            clearAll();
            return (window.location.href ="/auth/vdr/login");
          }, 2000);
        });
    }
    if (code === 401 && !getItem("access_token")) {
      // var gr = getItem("GR").toLocaleLowerCase();

      // logInfo("Redirect Login", {
      //   code: 401,
      //   type: "no_token",
      //   role: gr,
      //   request: originalRequest,
      // });
      setTimeout(() => {
        clearAll();
        return (window.location.href ="/auth/vdr/login");
      }, 2000);
    }
    // if (code !== 401) {
    //   if (!error.response) {
    //     var d = originalRequest.data;
    //     var pe = null;
    //     try {
    //       pe = d.get("prevent_error");
    //     } catch (error) {}
    //     alertData.setOpen(true);
    //     var e = error.toString();
    //     var messages = e.split(":")[1];
    //     if (!pe) {
    //       if (messages.indexOf("Network Error") > -1) {
    //         if (getItem("TEST_ATTEMPT") === "true") {
    //           alertData.setReconnecting(true);
    //         }
    //         alertData.setMessage(i18next.t("error.network.disconnected"));
    //       } else {
    //         alertData.setMessage(
    //           i18next.t("error.exception.title") +
    //             ", " +
    //             i18next.t("error.exception.description")
    //         );
    //       }
    //     }
    //   }
    // }


    if(error.request.responseType === 'blob'){
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          let a = reader.result;
            error.response.data = a;
            let customError = {
              code: error.response ? error.response.status : 500,
              message: error.response ? error.response.statusText : "Internal Error",
              data: error.response ? error.response.data : {}
            };

            // if (customError.code === 401) {
            //   history.replace("/error/401");
            // } else if (customError.code === 403) {
            //   history.replace("/error/403");
            // } else if (customError.code === 404) {
            //   history.replace("/error/404");
            // } else if (customError.code === 500) {
            //   history.replace("/error/500");
            // }
            
            resolve(Promise.reject(customError));
        };

        reader.onerror = () => {
            reject(error);
        };

        reader.readAsText(error.response.data);
      })
    }

    let customError = {
      code: error.response ? error.response.status : 500,
      message: error.response ? error.response.statusText : "Internal Error",
      data: error.response ? error.response.data : {}
    };

    // if (customError.code === 401) {
    //   history.replace("/error/401");
    // } else if (customError.code === 403) {
    //   history.replace("/error/403");
    // } else if (customError.code === 404) {
    //   history.replace("/error/404");
    // } else if (customError.code === 500) {
    //   history.replace("/error/500");
    // }

    return Promise.reject(customError);
  }
);

const decode = obj => {
    try {
      return new URLSearchParams(obj).toString();
    } catch (error) {
      return false;
    }
};

export async function RequestGet(uri, query, config) {
    if (query) {
      query = "?" + decode(query);
    }
    var url =
      uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
        ? uri
        : process.env.REACT_APP_URL_API + uri;
    return await axios.get(url + (query || ""), config);
}

export async function RequestPost(uri, data, config) {
  var url =
    uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
      ? uri
      : process.env.REACT_APP_URL_API + uri;
  return await axios.post(url, data, config);
}

export async function RequestPostAuth(uri, data, config) {
  var url =
    uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
      ? uri
      : process.env.REACT_APP_URL_AUTH + uri;
  return await axios.post(url, data, config);
}

export async function RequestPut(uri, data) {
  var url =
    uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
      ? uri
      : process.env.REACT_APP_URL_API + uri;
  return await axios.put(url, data);
}

export async function RequestDelete(uri){
  var url =
    uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
      ? uri
      : process.env.REACT_APP_URL_API + uri;
  return await axios.delete(url);
}