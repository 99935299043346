const initialState = {
    filesUpload: [],
  };
  
  const uploadFileReducer = (state = initialState, action) => {
    switch(action.type){
      case 'ADD_FILES_UPLOAD_PR':
        return {
          ...state,
          filesUpload: action.payload
        };
      default:
        return state;
    }
  }
  
  export default uploadFileReducer;