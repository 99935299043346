import React, { useState,useEffect } from "react";
import "./auth.css";
import { Link } from "react-router-dom";

import Button from "../../components/button/index";
import Page from "../../components/Page";
import { useDispatch,useSelector } from "react-redux";
import { createVendor } from "../../configs/redux/action/auth";
import {getTitle,getCountry,getCurrency,getPaymentMethods,getPaymentterms,getSysparam, getPIC} from '../../configs/redux/action/apiMaster'
import Swal from "sweetalert2";
// import picture

// import authLogo from "../../assets/img/logoIntaAuth.svg";
// import form from "../../assets/img/form.png";
import newAuthLogo from "../../assets/img/newLogoAuth.svg";
// import MainLogo from "../../assets/img/Main_Logo.png";
// import IconDownload from "../../assets/img/ic_upload.png";

import { Grid } from "@mui/material";

function SignupVendor() {
  // const history = useHistory();
  const dispatch = useDispatch();
  const { listTitle,listCurrency,listCountry,listPaymentTerms,listPaymentMethod,listSysparam,listPicInfo } = useSelector((state) => state.status);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState({
    titleType: "",
    name: "",
    vatRegistration: "",
    companyTaxType: "",
    city: "",
    countryCode: "",
    postalCode: "",
    street: "",
    telephone: "",
    faxNumber: "",
    emailAddress: "",
    salesPerson: "",
    salesPersonId:"",
    currency: "",
    termOfPayment: "",
    paymentMethod: "",
    status: "0",
    // bankCode: "",
    // bankAccount: "",
    // bankAccountName: "",
  });

  const [dropdownTitle,setTitle]=useState([{ value: "", label: "Choose Here" }])
  const [dropdownCompanyTax,setCompanyTax]=useState([{ value: "", label: "Choose Here" }])
  const [dropdownCountry,setCountry]=useState([{ value: "", label: "Choose Here" }])
  const [dropdownCurrency,setCurrency]=useState([{ value: "", label: "Choose Here" }])
  const [dropdownPaymentTerms,setPaymentTerms]=useState([{ value: "", label: "Choose Here" }])
  const [dropdownPaymentMethod,setPaymentMethod]=useState([{ value: "", label: "Choose Here" }])
  // const [dropdownBank,setBank]=useState([{ value: "", label: "Choose Here" }])
  
  const handleChange = (e) => {
    const dataNew = { ...data };
    dataNew[e.target.name] = e.target.value;
    setData(dataNew);
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(createVendor(data))
      .then((res) => {
        setLoading(false);
        setData({
          titleType: "",
          name: "",
          vatRegistration: "",
          companyTaxType: "",
          city: "",
          countryCode: "",
          postalCode: "",
          street: "",
          telephone: "",
          faxNumber: "",
          emailAddress: "",
          salesPerson: "",
          salesPersonId:"",
          currency: "",
          termOfPayment: "",
          paymentMethod: "",
          status: "0",
          // bankCode: "",
          // bankAccount: "",
          // bankAccountName: "",
        });
        if(res.data.message === "Failed"){
          Swal.fire("Error!", res.data.listErrorUpload[0].error_Description, "error");
        }else{
          Swal.fire("Register Success!", "Please wait while your data is being verified!", "success");
        }
        
      })
      .catch((err)=>{
        setLoading(false);
        console.log(err);
        // Swal.fire("Register Success!", "Please wait while your data is being verified!", "Error");
      });
  };

  const handleNumber = (number) => {
    setCurrentPage(number);
  };
  const handleNext = (number) => {
    number += 1;
    setCurrentPage(number);
  };
  const handleBack = (number) => {
    number -= 1;
    setCurrentPage(number);
  };
  
  useEffect(() => {
    dispatch(getSysparam("?ParamCode=COMPANY_TAX_TYPE"))
    dispatch(getPIC())
    dispatch(getPaymentterms())
    dispatch(getPaymentMethods())
    dispatch(getTitle())
    dispatch(getCountry())
    dispatch(getCurrency())// eslint-disable-next-line
  }, [])

  useEffect(() => {
    let newDataTitle = [{ label: "Choose Here", value: "" }];
    let newDataTaxType = [{ label: "Choose Here", value: "" }];
    let newDataCountry = [{ label: "Choose Here", value: "" }];
    let newDataCurrency = [{ label: "Choose Here", value: "" }];
    let newDataPaymentTerms = [{ label: "Choose Here", value: "" }];
    let newDataPaymentMethod = [{ label: "Choose Here", value: "" }];
    // let newDataBank = [{ label: "Choose Here", value: "" }];



    let titleData = listTitle
    let taxTypeData =listSysparam
    let countryData = listCountry
    let currencyData = listCurrency
    let termData = listPaymentTerms
    let payMethodData = listPaymentMethod
    // let bankData = listBank

    titleData.forEach((data, i) => {
      newDataTitle.push({
        value: data.titleType,
        label: data.titleType,
      });
    });
    taxTypeData.forEach((data, i) => {
      newDataTaxType.push({
        value: data.value,
        label: `${data.value} - ${data.description}`,
      });
    });
    countryData.forEach((data, i) => {
      newDataCountry.push({
        value: data.countryCode,
        label:`${data.countryCode} - ${data.countryName}`,
      });
    });
    currencyData.forEach((data, i) => {
      newDataCurrency.push({
        value: data.currencyCode,
        label:`${data.currencyCode} - ${data.currencyDescription}`,
      });
    });

    termData.forEach((data, i) => {
      newDataPaymentTerms.push({
        value: data.paymentTerm,
        label:`${data.paymentTerm} - ${data.explanation}`,
      });
    });

    payMethodData.forEach((data, i) => {
      newDataPaymentMethod.push({
        value: data.paymentType,
        label:data.paymentDescription,
      });
    });

    // bankData.forEach((data, i) => {
    //   newDataBank.push({
    //     value: data.bankCode,
    //     label:data.bankCode,
    //   });
    // });
    setTitle(newDataTitle);
    setCompanyTax(newDataTaxType)
    setCountry(newDataCountry);
    setCurrency(newDataCurrency)
    setPaymentTerms(newDataPaymentTerms)
    setPaymentMethod(newDataPaymentMethod)
    // setBank(newDataBank)

   // eslint-disable-next-line
  }, [listTitle,listCountry,listSysparam,listCurrency,listPaymentTerms,listPaymentMethod])
  

  

  return (
    <Page title="Register">
      <Grid container spacing={2} sx={{ height: "100vh" }}>
        <Grid item xs={7} md={7} sx={{ backgroundColor: "#FFCD08" }}>
          <div style={{ position: "relative" }}>
            <div className="bg-auth-portal"></div>
            <div className="bg-circle-top-left"></div>
            <div className="bg-circle-bottom-right"></div>
          </div>
        </Grid>
        <Grid item xs={5} md={5}>
          <div className="side-auth-register">
            {/* <Button btn="btn-back-auth" title="Back" onClick={handleBackAuth} /> */}

            <img src={newAuthLogo} width="138px" height="58px" alt="logo" />

            <div className="label-as">
              <p>As</p>
              <div className="label-as-vendor">Vendor</div>
            </div>
            <div className="tab-link" style={{ width: "100%" }}>
              <Link to="/auth/vdr/login" className="inactive">
                Login
              </Link>
              <Link to="/auth/vdr/register" className="active">
                Register
              </Link>
            </div>
            {/* <form onSubmit={formik.handleSubmit}> */}

            {/* page 1 */}
            {currentPage === 1 ? (
              <div style={{ width: "100%", paddingRight: "60px" }}>
                <h4>Vendor & Tax Info</h4>
                <label htmlFor="titleType">Title</label>
                <select
                  className="select-auth"
                  id="titleType"
                  name="titleType"
                  value={data.titleType}
                  onChange={handleChange}
                >
                  {dropdownTitle.map((status, key) => (
                    <option key={"titleType-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select>
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Company Name"
                  type="text"
                  className="input-auth"
                  value={data.name}
                  onChange={handleChange}
                />
                <label htmlFor="companyTaxType">Company Tax Type</label>
                <select
                  className="select-auth"
                  id="companyTaxType"
                  name="companyTaxType"
                  value={data.companyTaxType}
                  onChange={handleChange}
                >
                  {dropdownCompanyTax.map((status, key) => (
                    <option key={"titleType-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select>
                <label htmlFor="vatRegistration">VAT Registration No</label>
                <input
                  id="vatRegistration"
                  name="vatRegistration"
                  // placeholder="VAT Registration No"
                  type="text"
                  className="input-auth"
                  value={data.vatRegistration}
                  onChange={handleChange}
                />
                <label htmlFor="countryCode">Country</label>
                <select
                  className="select-auth"
                  id="countryCode"
                  name="countryCode"
                  value={data.countryCode}
                  onChange={handleChange}
                >
                  {dropdownCountry.map((status, key) => (
                    <option key={"titleType-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select>
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  className="input-auth"
                  value={data.city}
                  onChange={handleChange}
                />
                <label htmlFor="postalCode">Postal Code</label>
                <input
                  id="postalCode"
                  name="postalCode"
                  type="text"
                  className="input-auth"
                  value={data.postalCode}
                  onChange={handleChange}
                />
                <label htmlFor="street">Street</label>
                <textarea
                  id="street"
                  name="street"
                  type="text"
                  className="input-auth"
                  value={data.street}
                  onChange={handleChange}
                  style={{ height: "80px", paddingTop: "20px" }}
                />
              </div>
            ) : currentPage === 2 ? (
              <div style={{ width: "100%", paddingRight: "60px" }}>
                <h4>Contact Info</h4>
                <label htmlFor="telephone">Telephone</label>
                <input
                  id="telephone"
                  name="telephone"
                  type="text"
                  className="input-auth"
                  value={data.telephone}
                  onChange={handleChange}
                />
                <label htmlFor="faxNumber">Fax Number</label>
                <input
                  id="faxNumber"
                  name="faxNumber"
                  type="text"
                  className="input-auth"
                  value={data.faxNumber}
                  onChange={handleChange}
                />
                <label htmlFor="emailAddress">Email</label>
                <input
                  id="emailAddress"
                  name="emailAddress"
                  type="email"
                  className="input-auth"
                  value={data.emailAddress}
                  onChange={handleChange}
                />
                <label htmlFor="salesPerson">Sales Person</label>
                <input
                  id="salesPerson"
                  name="salesPerson"
                  type="text"
                  className="input-auth"
                  value={data.salesPerson}
                  onChange={handleChange}
                />
                <label htmlFor="salesPersonId">PIC</label>
                <select
                  className="select-auth"
                  id="salesPersonId"
                  name="salesPersonId"
                  value={data.salesPersonId}
                  onChange={handleChange}
                >
                  {listPicInfo.map((item, key) => (
                    <option key={"salesPersonId-" + key} value={item.value} hidden={item.value === "" ?true:false} >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div style={{ width: "100%", paddingRight: "60px" }}>
                <h4>Contact Info</h4>
                <label htmlFor="currency">Currency</label>
                <select
                  className="select-auth"
                  id="currency"
                  name="currency"
                  value={data.currency}
                  onChange={handleChange}
                >
                  {dropdownCurrency.map((status, key) => (
                    <option key={"titleType-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select>
                <label htmlFor="termOfPayment">Payment Term</label>
                <select
                  className="select-auth"
                  id="termOfPayment"
                  name="termOfPayment"
                  value={data.termOfPayment}
                  onChange={handleChange}
                >
                  {dropdownPaymentTerms.map((status, key) => (
                    <option key={"titleType-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select>
                <label htmlFor="paymentMethod">Payment Method</label>
                <select
                  className="select-auth"
                  id="paymentMethod"
                  name="paymentMethod"
                  value={data.paymentMethod}
                  onChange={handleChange}
                >
                  {dropdownPaymentMethod.map((status, key) => (
                    <option key={"titleType-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select>
                {/* <label htmlFor="bankAccount">Bank Account No</label>
                <input
                  id="bankAccount"
                  name="bankAccount"
                  type="text"
                  className="input-auth"
                  value={data.bankAccount}
                  onChange={handleChange}
                /> */}
                {/* <label htmlFor="bankAccountName">Bank Account Name</label>
                <input
                  id="bankAccountName"
                  name="bankAccountName"
                  type="text"
                  className="input-auth"
                  value={data.bankAccountName}
                  onChange={handleChange}
                /> */}
                {/* <label htmlFor="bankCode">Bank</label>
                <select
                  className="select-auth"
                  id="bankCode"
                  name="bankCode"
                  value={data.bankCode}
                  onChange={handleChange}
                >
                  {dropdownBank.map((status, key) => (
                    <option key={"bankCode-" + key} value={status.value} hidden={status.value === "" ?true:false} >
                      {status.label}
                    </option>
                  ))}
                </select> */}
                {/* <label className="filebutton" style={{ marginTop: "30px" }}>
                  NPWP File
                  <span>
                    <input
                      type="file"
                      id="fileNpwp"
                      name="fileNpwp"
                      onChange={handleUpload}
                    />

                    {selectedFile !== null ? (
                      <div className="file-name">{selectedFile.name}</div>
                    ) : (
                      <img src={IconDownload} alt="" />
                    )}
                  </span>
                </label> */}
              </div>
            )}
            <div className="tab-number">
              {Array(3)
                .fill()
                .map((data, index) => {
                  return (
                    <Button
                      key={index}
                      btn="btn-number"
                      title={index + 1}
                      type="button"
                      color={currentPage === index + 1 ? "yellow" : "gray"}
                      onClick={() => handleNumber(index + 1)}
                    />
                  );
                })}
            </div>
            {currentPage === 1 ? (
              <Button
                btn="btn-next"
                type="button"
                title="Next"
                onClick={() => handleNext(currentPage)}
              />
            ) : currentPage === 2 ? (
              <div style={{ display: "flex" }}>
                <Button
                  btn="btn-back"
                  title="Back"
                  type="button"
                  onClick={() => handleBack(currentPage)}
                />
                <Button
                  btn="btn-next-page"
                  title="Next"
                  type="button"
                  onClick={() => handleNext(currentPage)}
                />
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <Button
                  btn="btn-back"
                  title="Back"
                  type="button"
                  onClick={() => handleBack(currentPage)}
                />
                {loading === false ? (
                  <Button
                    btn="btn-register"
                    title="Register"
                    color="navy"
                    // type="submit"
                    onClick={handleSubmit}
                  />
                ) : (
                  <div className="btn-loading">
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="loaderDownload"></div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* </form> */}
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}

export default SignupVendor;
