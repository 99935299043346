const ConvertFormat = (angka) => {
    var	number_string = angka.toString(),
	split	= number_string.split('.'),
	sisa 	= split[0].length % 3,
	rupiah 	= split[0].substr(0, sisa),
	ribuan 	= split[0].substr(sisa).match(/\d{1,3}/gi);
            
    if (ribuan) {
        let separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }
    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    // let number_string = angka
    //     .toString()
    //     .replace(/[^,\d]/g, "")
    //     .toString(),
    //     split = number_string.split(","),
    //     sisa = split[0].length % 3,
    //     currency = split[0].substr(0, sisa),
    //     ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // if (ribuan) {
    //     let separator = sisa ? "." : "";
    //     currency += separator + ribuan.join(".");
    // }

    // currency = split[1] !== undefined ? currency + "," + split[1] : currency;
    return `${rupiah}`;
};

export default ConvertFormat;



