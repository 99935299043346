import * as React from "react";
import { useHistory,useLocation } from "react-router-dom";
import { useDispatch,useSelector} from "react-redux";

import { styled } from "@mui/material/styles";
import {
  // Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";


import AccountCircle from "@mui/icons-material/AccountCircle";
import Logo from "../assets/img/logoVendor.svg";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ChangePassword from "./changePassword";

// Component
import { clearAll } from "../configs/utilities/Storage";
// import { vendorRoute } from "../configs/router/mainRoute";
import {changeScreenIS} from "../configs/redux/action/Vendor/invoiceSubmission"
import {changeScreenPO} from "../configs/redux/action/Vendor/purchaseOrder"
// Redux



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "#FFCD08",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    background: "#FFCD08",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const vendorRoute = [
  {
      path: '/vdr/purchase-order',
      title: 'Purchase Order',
      type: 'route'
  },
  {
      path: '/vdr/invoice-submission',
      title: 'Invoice Submission',
      type: 'route'
  },
];





function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { listInfoVendor } = useSelector((state) => state.infoVendor);
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openDialog, openDialogInput] = React.useState(false);
  
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDialog = () => {
    openDialogInput(false);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenu = (thePath) => {
    history.push(thePath);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const openDialogChangePassword = ()=>{
    openDialogInput(true)
    setAnchorEl(null);
  }
  const handleEditProfile=()=>{
    if (location.pathname === "/vdr/invoice-submission") {
      dispatch(changeScreenIS("edit-profile"))
    } else {
      dispatch(changeScreenPO("edit-profile"))
    }
  }
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleEditProfile}>Profile</MenuItem>
      <MenuItem onClick={openDialogChangePassword}>Change Password</MenuItem>
      <Divider />
      <MenuItem sx={{color:"#CB605A"}} onClick={()=>{
        const clearAllCookies = new Promise ((resolve, reject) => {
          resolve(clearAll());
        });
        clearAllCookies
          .then(()=>{
            handleMenuClose();
          })
          .then(()=>{
            history.push("/auth/vdr/login");
          })
          .catch(er => {
            console.log(er);
          })
      }}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" >
        <Toolbar>
          <img src={Logo}  alt="Logo" />
          {vendorRoute.map((rute, id) =>
            rute.type === "route" ? (
              <Tooltip title={rute.title} key={"tooltip-menu-" + id}>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    handleMenu(rute.path);
                    if (rute.path === "/vdr/invoice-submission") {
                      dispatch(changeScreenIS("list-is"));
                    } else {
                      dispatch(changeScreenPO("list-po"));
                    }
                  }}
                >
                  <Box ml={3} />
                  <p className="navbar-vendor">{rute.title}</p>
                </MenuItem>
              </Tooltip>
            ) : null
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <div className="info-account-vendor">{`${listInfoVendor[0].name} (${listInfoVendor[0].vendorCode})`}</div>
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              sx={{color:"#001D61"}}
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{color:"#001D61"}}

            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
     
      {renderMobileMenu}
      {renderMenu}
      <ChangePassword open={openDialog} close={handleCloseDialog}/>
    </Box>
  );
}
export default Navbar;
