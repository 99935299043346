import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import logger from 'redux-logger'
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import store from '../redux/store'
import rootReducer from "../redux/reducer/index";

const persistConfig = {
    key: "root",
    storage,
};

let composeEnhancers = compose;

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
/* eslint-enable */
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk, logger)));
let persistor = persistStore(store);
export { persistor, store };